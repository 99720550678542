import firebase from "firebase/compat/app";
import { createDocBaseClass } from "../lib/firestore/fstore";
import { Repo } from "../lib/firestore/fstore";
import { LBillingData } from "../lib/apiDefs";

export class LBillingDoc extends createDocBaseClass<LBillingData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const LBillingRepo = new (class LBillingRepo extends Repo<LBillingDoc> {
  constructor() {
    super("l_billings", LBillingDoc);
  }
})();
