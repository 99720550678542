// icon:account-star | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";
import { createIcon } from "@chakra-ui/react";

export const ReportAccountStarIcon = createIcon({
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M15 14c-2.67 0-8 1.33-8 4v2h16v-2c0-2.67-5.33-4-8-4m0-2a4 4 0 004-4 4 4 0 00-4-4 4 4 0 00-4 4 4 4 0 004 4M5 13.28l2.45 1.49-.65-2.81L9 10.08l-2.89-.25L5 7.19 3.87 9.83 1 10.08l2.18 1.88-.68 2.81L5 13.28z"
    />
  ),
});
