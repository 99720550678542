import React, { useCallback } from "react";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { CardSection, CardSectionItem } from "../../components/layout/Sections";
import { CProfileDoc, CProfileRepo } from "../../model/CProfileDoc";
import { ProfileListRow } from "../search/ProfileListRow";
import { useAsync } from "react-use";
import { docQuery } from "../../lib/firestore/fstore";
import { Text } from "@chakra-ui/react";

export const AdminProfilesListScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminProfilesList">) => {
  const { loading, value: profiles } = useAsync(async () => {
    let profiles = await docQuery(CProfileRepo.query().where("phone", "==", route.params.phone));
    profiles.sort((a, b) => {
      let aa = 0;
      if (a.type === "app") aa -= 1;
      if (a.status === "active") aa -= 2;
      let bb = 0;
      if (b.type === "app") bb -= 1;
      if (b.status === "active") bb -= 2;
      return aa - bb;
    });
    return profiles;
  }, [route.params.phone]);

  const onSelect = useCallback((profileId: string) => {
    navigation.navigate("AdminCustomerDetails", { profileId });
  }, []);

  function renderList(profiles: CProfileDoc[] | undefined | null) {
    if (profiles && profiles.length > 0) {
      return (
        <CardSection title="Profiles" withDivider={true}>
          {profiles.map((item) => {
            return <ProfileListRow key={item.accountId} profileData={item} onPress={onSelect} />;
          })}
        </CardSection>
      );
    } else {
      return (
        <CardSection>
          <CardSectionItem>
            <Text my={1} textAlign="center" color="brand.secondaryText">
              No profiles
            </Text>
          </CardSectionItem>
        </CardSection>
      );
    }
  }

  return (
    <Screen name={`Profiles for ${route.params.phone}`} showTitle={true}>
      {!loading ? (
        <>{renderList(profiles)}</>
      ) : (
        <SectionMsg text="Loading..." type="muted" textSize="md" />
      )}
    </Screen>
  );
};
