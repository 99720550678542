import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { firebaseAuth } from "../../lib/firebase/fbenv";
import { ChangeEvent, MouseEvent, useCallback, useState } from "react";
import { Image, Input, StackDivider, Text, VStack } from "@chakra-ui/react";
import { useAuthentication } from "../../lib/auth/useAuthentication";
import { useAsyncFn } from "../../hooks/reactUse";
import { isEmbeddedWebApp } from "../../lib/auth/embeddedApp";
import { VFlex } from "../../components/layout/VFlex";
import { PasswordInput } from "../../components/primitives/PasswordInput";
import { ProviderButton } from "../../components/primitives/ProviderButton";
import { EmailIcon } from "../../components/icons/EmailIcon";
import { LogoGoogleIcon } from "../../components/icons/LogoGoogleIcon";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { UndecoratedScreen } from "../../components/layout/UndecoratedScreen";
import { MsgScreen } from "../../components/layout/MsgScreen";

export const GuardAuth = (props: { children: JSX.Element }) => {
  const authState = useAuthentication();

  if (authState.isAuthenticated) {
    return <>{props.children}</>;
  } else {
    if (isEmbeddedWebApp()) {
      // sign-in never possible in embedded web app
      return <MsgScreen msg="Not authorized." />;
    } else {
      // sign-in only possible if it's direct web app
      return <SignIn />;
    }
  }
};

const SignIn = () => {
  return (
    <UndecoratedScreen name="Sign In" alignItems={"center"}>
      <Image
        mt={10}
        mb={8}
        src={require("assets/logo/logo-with-tm.svg")}
        alt="Recirclable"
        w="100px"
        h="100px"
      />
      <VFlex width="full" maxW={400} p={4}>
        <GoogleSignIn />
        <Text m={3} mb={4} textAlign={"center"}>
          - or use -
        </Text>
        <EmailSignIn />
      </VFlex>
    </UndecoratedScreen>
  );
};

const EmailSignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState<string | null>(null);

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
    setMsg(null);
    event.preventDefault();
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
    setMsg(null);
    event.preventDefault();
  }

  function handleEmailSignIn(event: MouseEvent<HTMLElement>) {
    event.preventDefault();
    submitSignIn();
  }

  const [signInState, submitSignIn] = useAsyncFn(async () => {
    try {
      await firebaseAuth().signInWithEmailAndPassword(email, password);
      setMsg(null);
      // nothing further to do, the GuardAuth will swap to a different stack of screens
    } catch (error) {
      console.error("error trying email sign in", error);
      setMsg((error as any).message ?? "Error signing in.");
    }
  }, [email, password]);

  return (
    <VFlex width="100%">
      <VStack
        spacing={1}
        p={1}
        mb={2}
        bg="brand.section.bg"
        borderRadius="md"
        borderWidth={0}
        borderColor="gray.100"
        overflow="hidden"
        height="100%"
        divider={<StackDivider borderColor="brand.divider" />}
      >
        <Input
          id="email"
          isDisabled={signInState.loading}
          type="email"
          placeholder="Enter Email"
          autoComplete="email"
          autoCorrect="off"
          autoCapitalize="none"
          value={email}
          onChange={handleEmailChange}
        />
        <PasswordInput
          isDisabled={signInState.loading}
          placeholder="Enter Password"
          value={password}
          onChange={handlePasswordChange}
        />
      </VStack>
      <ProviderButton
        isLoading={signInState.loading}
        isDisabled={
          !email || email.length < 1 || !password || password.length < 1 || signInState.loading
        }
        label="Sign with Email"
        icon={EmailIcon}
        providerColor="#db4437"
        onClick={handleEmailSignIn}
      />
      <SectionMsg text={msg} type="error" />
    </VFlex>
  );
};

const GoogleSignIn = () => {
  const handleGoogleSignIn = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider.setCustomParameters({ prompt: "select_account" });
    firebase.auth().signInWithPopup(googleProvider);
    // nothing further to do, the GuardAuth will swap to a different stack of screens
  }, []);

  return (
    <ProviderButton
      isLoading={false}
      label="Google"
      icon={LogoGoogleIcon}
      providerColor="#ffffff"
      onClick={handleGoogleSignIn}
    />
  );
};
