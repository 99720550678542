import React, { useCallback, useMemo, useState } from "react";
import { ListItem, ListSelect } from "../../components/primitives/ListSelect";
import { Screen } from "../../components/layout/Screen";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { ProductRepo } from "../../model/ProductDoc";
import { BackAppScreenProps } from "../../backAppStack";
import { useBackRpc } from "../../lib/functions/rpc";
import { useDocQuery } from "../../lib/firestore/fstore_hooks";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const ContainerUpdateProductScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminContainerUpdateProduct">) => {
  const closeScreen = useCloseScreen();
  const [productId, setProductId] = useState<string | undefined>(route.params.productId);
  const productsState = useDocQuery(ProductRepo.query());
  const [updateState, doUpdate] = useBackRpc("containerUpdate");

  const productList = useMemo<ListItem[] | undefined>(() => {
    if (productsState.value) {
      const products = productsState.value;
      products.sort((a, b) => a.name.localeCompare(b.name));
      return products.map((p) => {
        return {
          id: p.id,
          label: p.name,
        };
      });
    }
  }, [productsState.value?.length]);

  const onSubmit = useCallback(() => {
    if (productId) {
      console.log("Update to", productId);
      doUpdate({ containerIds: [route.params.containerId], productId })
        .then((result) => {
          if (result.success) closeScreen();
        })
        .catch(() => {});
    }
  }, [productId]);

  return (
    <Screen name="Update Product" secondaryTitle={route.params.name} showTitle={true}>
      {productList && (
        <ListSelect
          placeHolder="Select Product"
          list={productList}
          selected={productId}
          onSelect={setProductId}
        />
      )}
      <SectionPrimaryButton
        isDisabled={!productId}
        // isLoading={activationState.loading || sqLocationListState.loading}
        label="Update"
        onClick={onSubmit}
      />
    </Screen>
  );
};
