import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import _omit from "lodash/omit";

/**
 * A super simple screen without header and other bells and whistles. And most
 * important outside a Navigator.
 *
 * @param props
 * @returns
 */
export const UndecoratedScreen = (props: { name: string } & FlexProps) => {
  return (
    <Flex
      position="relative"
      flexDirection="column"
      alignSelf="center"
      px={[0, 0, 4, 16]}
      w="100vw"
      h="100vh"
      bg="brand.bg"
      overflow="hidden"
      {..._omit(props, ["name"])}
    >
      {props.children}
    </Flex>
  );
};
