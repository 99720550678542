import React from "react";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { TerminalRepo } from "../../model/TerminalDoc";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import ContainerInventoryView from "../container/ContainerInventoryView";

export const AdminLocationContainersScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationContainers">) => {
  return (
    <Screen name="Containers at Location" showTitle={true}>
      <ContainerInventoryView
        locationId={route.params.locationId}
        expected={route.params.expected}
      />
    </Screen>
  );
};
