import React from "react";
import { ScreenTitle } from "../../components/layout/ScreenTitle";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import QRCode from "react-qr-code";
import { TerminalRepo } from "../../model/TerminalDoc";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import { PropertiesCard } from "../../components/properties/PropertiesCard";
import _keys from "lodash/keys";
import { CardSection, SectionBox } from "../../components/layout/Sections";
import { Text } from "@chakra-ui/react";

export const AdminTerminalDetailsScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminTerminalDetails">) => {
  const { value: terminal } = useDocGet(TerminalRepo.doc(route.params.terminalId));

  const terminalProps = {
    id: terminal?.id,
    "Terminal Id": terminal?.publicId,
    "Location Id": terminal?.locationId,
    Email: terminal?.email,
    Password: "********",
    Reader: terminal?.readerType,
    Features: terminal?.getFeatureDescription(),
  };

  return (
    <Screen name="Terminal Details">
      <ScreenTitle title={"Terminal Setup"} />
      <CardSection p={3} alignItems="center">
        {terminal && (
          <QRCode
            value={`${JSON.stringify({ email: terminal.email, password: terminal.password })}`}
          />
        )}
      </CardSection>
      <PropertiesCard title="Details" properties={terminalProps} />
      <SectionBox>
        <Text mx={6} my={4} textAlign="center">
          Please scan the QR code with the Terminal App to setup the Terminal.
        </Text>
      </SectionBox>
    </Screen>
  );
};
