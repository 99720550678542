import React, { useCallback } from "react";
import { HStack } from "@chakra-ui/react";
import { NumberTile } from "../../components/primitives/NumberTile";
import { HSpace } from "../../components/layout/HSpace";
import { useAsync } from "../../hooks/reactUse";
import InventoryGauge from "../../components/report/InventoryGauge";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import { useRoles } from "../../lib/auth/useRoles";
import { ifAdmin } from "../../components/conditional/IfAccess";
import { EntityRepo } from "../../model/Entities";
import { VersionSection } from "../../components/views/VersionSection";
import { authSignOut } from "../../lib/auth/useAuthentication";
import { ifNotEmbeddedApp } from "../../lib/auth/embeddedApp";
import { useDocGet, useDocQuery } from "../../lib/firestore/fstore_hooks";
import { SectionBox } from "../../components/layout/Sections";
import { createAdminBackendClient } from "../../lib/trpc/adminBackend";
import AveragesReportView from "../../components/report/AveragesReportView";
import ActivityReportContainer from "../../components/report/ActivityReportContainer";
import { isProduction } from "../../util/config";
import { SectionForwardButton } from "../../components/layout/SectionForwardButton";

export const AdminHomeScreen = ({ navigation, route }: BackAppScreenProps<"AdminHome">) => {
  const { eroot } = useRoles();

  const entityState = useDocGet(EntityRepo.doc(eroot));
  const dashboardTitle = entityState.loading
    ? "..."
    : entityState.value
    ? `${entityState.value.name} Dashboard`
    : "Dashboard";

  const reportEntityState = useAsync(async () => {
    const client = createAdminBackendClient();
    return client.reportEntity.mutate({ entityId: eroot });
  }, []);

  const inventoryState = useDocQuery(VInventoryLevelRepo.queryEntity(eroot));
  const sums = (inventoryState.value ?? [])
    .filter((level) => !isProduction() || level.test !== true)
    .reduce(
      (acc, level) => ({
        subscriptionSum: acc.subscriptionSum + level.subscriptionSum,
        outstandingSum: acc.outstandingSum + level.outstandingSum,
        inventorySum: acc.inventorySum + level.deliverSum + level.countSum + level.itemSum,
      }),
      {
        subscriptionSum: 0,
        outstandingSum: 0,
        inventorySum: 0,
      }
    );

  const handleListLocations = useCallback(() => {
    navigation.push("AdminLocationList");
  }, []);

  const handleCustomerSearch = useCallback(() => {
    navigation.push("AdminCustomerSearch");
  }, []);

  const handleAdminScan = useCallback(() => {
    navigation.push("AdminScan");
  }, []);

  const handleBulkAssign = useCallback(() => {
    navigation.push("AdminContainerBulkAsssign");
  }, []);

  const handleAdminCreateCustomer = useCallback(() => {
    navigation.push("AdminCustomerCreate");
  }, []);

  const handleTransactionList = useCallback(() => {
    navigation.push("AdminRecentTransactionList");
  }, []);

  const handleSignOut = useCallback(() => {
    authSignOut();
  }, []);

  return (
    <Screen
      name={dashboardTitle}
      showTitle={true}
      titleMenu={ifAdmin(
        {
          "Scan QR Code": handleAdminScan,
          "Bulk Setup Containers": handleBulkAssign,
          "Create Customer": handleAdminCreateCustomer,
          ...ifNotEmbeddedApp({ "Sign Out": handleSignOut }),
        },
        ifNotEmbeddedApp({ "Sign Out": handleSignOut })
      )}
    >
      <HSpace w={3} />
      <SectionBox>
        <HStack gap={2}>
          {ifAdmin(
            <NumberTile
              value={reportEntityState.value?.customersTotal ?? 0}
              secondaryValue={
                reportEntityState.value?.customersRows !== reportEntityState.value?.customersTotal
                  ? ` of ${reportEntityState.value?.customersRows ?? 0}`
                  : undefined
              }
              label="Customers"
              onDetails={handleCustomerSearch}
              isLoading={reportEntityState.loading}
            />
          )}
          <NumberTile
            value={reportEntityState.value?.locationsTotal ?? 0}
            secondaryValue={
              reportEntityState.value?.locationsRows !== reportEntityState.value?.locationsTotal
                ? ` of ${reportEntityState.value?.locationsRows ?? 0}`
                : undefined
            }
            label="Locations"
            onDetails={handleListLocations}
            isLoading={reportEntityState.loading}
          />
        </HStack>
      </SectionBox>
      <InventoryGauge
        isLoading={inventoryState.loading}
        subscription={sums.subscriptionSum}
        borrowed={-sums.outstandingSum}
        inventory={sums.inventorySum}
        difference={0}
      />

      <AveragesReportView
        isLoading={reportEntityState.loading}
        avgBorrowCnt={reportEntityState.value?.avgBorrowCnt ?? 0}
        avgBorrowDays={reportEntityState.value?.avgBorrowDays ?? 0}
      />
      <ActivityReportContainer entityId={eroot} inclIncomplete={true} />
      <SectionForwardButton
        label="Recent Transactions"
        onPress={handleTransactionList}
        fontSize="sm"
      />
      <VersionSection />
    </Screen>
  );
};
