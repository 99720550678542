import React from "react";
import { CardSection } from "../layout/Sections";
import { Select } from "@chakra-ui/react";

export interface ListItem {
  id: string;
  label: string;
}

export const ListSelect: React.FunctionComponent<{
  placeHolder: string;
  list: ListItem[];
  selected?: string | null;
  onSelect: (item: any) => void;
}> = (props) => {
  return (
    <CardSection>
      {props.list && (
        <Select
          borderRadius={10}
          borderWidth={0}
          value={props.selected ?? ""}
          fontSize="md"
          minWidth="200"
          aria-label={props.placeHolder}
          placeholder={props.placeHolder}
          onChange={(e) => props.onSelect(e.target.value)}
        >
          {props.list.map((item) => {
            return <option key={item.id} label={item.label} value={item.id} />;
          })}
        </Select>
      )}
    </CardSection>
  );
};
