// icon:credit-card-check-outline | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";
import { createIcon } from "@chakra-ui/react";

export const InventoryDiffPayIcon = createIcon({
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M13 19c0-.34.04-.67.09-1H3v-6h16v1c.7 0 1.37.13 2 .35V6c0-1.11-.89-2-2-2H3c-1.11 0-2 .89-2 2v12a2 2 0 002 2h10.09c-.05-.33-.09-.66-.09-1M3 6h16v2H3V6m14.75 16L15 19l1.16-1.16 1.59 1.59 3.59-3.59 1.16 1.41L17.75 22"
    />
  ),
});
