import React from "react";
import { authSignOut } from "../../lib/auth/useAuthentication";
import { sentry } from "../../lib/sentry/sentry";
import { ifProduction } from "../../util/config";
import { ONE_SEC } from "../../util/constants";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";

interface ErrorBoundaryProps {
  children?: JSX.Element | JSX.Element[] | string | any;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: any;
  showError?: boolean;
  errorAt?: number;
  lastErrorAt?: number;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    sentry().captureException(error);
    console.error("ERROR BOUNDARY:", error);
    return { hasError: true, error, errorAt: Date.now() };
  }

  async handleRetry() {
    // repeated errors --> do a deeper reset of the app
    const repeatMs = ifProduction(20 * ONE_SEC, 3 * ONE_SEC);
    if (
      this.state.errorAt &&
      this.state.lastErrorAt &&
      this.state.errorAt < this.state.lastErrorAt + repeatMs
    ) {
      authSignOut();
    }

    // update the timestamps
    this.setState((s) => ({ lastErrorAt: s.errorAt, showError: false }));

    // reload the web app
    window.location.reload();

    // in case restart not possible, just remove the error
    this.setState({ hasError: false });
  }

  // rendering uses intentionally pure React Native
  render() {
    if (this.state.hasError) {
      return (
        <Flex flexDirection="column" bg="#f0f4f0" paddingTop="30px" w="100vw" h="100vh">
          <Flex
            flex={1}
            flexDirection="column"
            alignItems="center"
            alignContent="center"
            padding={8}
            width="full"
          >
            <Image
              aria-label="Error Illustration"
              src={require("assets/images/error.png")}
              objectFit="contain"
              width={180}
              height={180}
              onClick={() => this.setState((s) => ({ ...s, showError: !s.showError }))}
            />
            <Text fontSize="md" fontWeight={600} color="#010A5A" margin={4} textAlign="center">
              Something went wrong. Are you connected to a network? Please try again.
            </Text>
            <Button onClick={() => this.handleRetry()} colorScheme="teal">
              Retry
            </Button>
          </Flex>
          {this.state.showError ? (
            <Box
              flex={1}
              flexDirection="column"
              width="full"
              borderColor="#a0a0a0"
              borderWidth={1}
              backgroundColor={"white"}
            >
              {"stack" in this.state.error
                ? String(this.state.error.stack)
                    .split("\n")
                    .map((line) => {
                      return (
                        <Text fontSize="xs" fontWeight={400} color="#010A5A" textAlign="center">
                          {line}
                        </Text>
                      );
                    })
                : null}
            </Box>
          ) : null}
        </Flex>
      );
    } else {
      return this.props.children;
    }
  }
}
