import firebase from "firebase/compat/app";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";
import { ONE_MIN } from "../util/constants";
import { isProduction } from "../util/config";
import { ContainerData } from "../lib/apiDefs";

export class ContainerDoc extends createDocBaseClass<ContainerData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }

  isLocked() {
    return !!this.returnLockUntil && this.returnLockUntil.getTime() > Date.now();
  }

  recentlyDelivered(locationId: string) {
    const DELIVER_DELTA_TIME = isProduction() ? 15 * ONE_MIN : ONE_MIN;
    return (
      this.event === "deliver" &&
      this.locationId === locationId &&
      this.locationAt &&
      this.locationAt.getTime() > Date.now() - DELIVER_DELTA_TIME
    );
  }
}

export const ContainerRepo = new (class ContainerRepo extends Repo<ContainerDoc> {
  constructor() {
    super("containers", ContainerDoc);
  }
})();
