import React from "react";
import { ScreenTitle } from "../../components/layout/ScreenTitle";
import InventoryLevelGauge from "../../components/report/InventoryLevelGauge";
import { LocationInfoCard } from "../../components/views/LocationInfoCard";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import { IfAdmin, ifAdmin } from "../../components/conditional/IfAccess";
import ContainerInventoryView from "../container/ContainerInventoryView";
import { safe } from "../../util/safe";
import { useDocGet, useDocWatch } from "../../lib/firestore/fstore_hooks";
import { PartnerRepo } from "../../model/PartnerDoc";
import { LocationRepo } from "../../model/LocationDoc";
import useAsync from "react-use/lib/useAsync";
import { createAdminBackendClient } from "../../lib/trpc/adminBackend";
import AveragesReportView from "../../components/report/AveragesReportView";
import ActivityReportContainer from "../../components/report/ActivityReportContainer";
import { SectionForwardButton } from "../../components/layout/SectionForwardButton";

export const AdminLocationScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationOverview">) => {
  const { value: partner } = useDocGet(PartnerRepo.doc(route.params.partnerId));
  const { value: location } = useDocGet(LocationRepo.doc(route.params.locationId));

  const reportEntityState = useAsync(async () => {
    const client = createAdminBackendClient();
    return client.reportEntity.mutate({ entityId: route.params.locationId });
  }, [route.params.locationId]);

  const inventoryState = useDocWatch(VInventoryLevelRepo.doc(route.params.locationId));

  function handleTransactionList() {
    if (location) {
      navigation.push("AdminLocationTransactionList", {
        partnerId: route.params.partnerId,
        locationId: route.params.locationId,
        locationName: location?.name,
      });
    }
  }

  function handleLocationDetails() {
    if (location) {
      navigation.push("AdminLocationDetails", {
        locationId: route.params.locationId,
      });
    }
  }

  function handleTerminalList() {
    if (location) {
      navigation.push("AdminTerminalList", {
        locationId: route.params.locationId,
      });
    }
  }

  function handleQRCodeList() {
    if (location) {
      navigation.push("AdminPointList", {
        partnerId: route.params.partnerId,
        locationId: route.params.locationId,
        locationName: location?.name,
      });
    }
  }

  function handleInventoryDetails() {
    navigation.push("AdminLocationInventoryDetails", {
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
    });
  }

  function handleContainerDetails() {
    navigation.push("AdminLocationContainers", {
      locationId: route.params.locationId,
      expected: inventoryState
        ? safe.neg(inventoryState.doc?.outstandingSum) +
          safe.add(
            inventoryState.doc?.deliverSum,
            inventoryState.doc?.itemSum,
            inventoryState.doc?.countSum
          )
        : 0,
    });
  }

  function handleChangeSubscription() {
    navigation.push("AdminLocationChangeSubscription", {
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
    });
  }

  function handleDeliverRemove() {
    navigation.push("AdminLocationDeliverRemove", {
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
    });
  }

  function handleDeliverScan() {
    navigation.push("AdminLocationDeliverScan", {
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
    });
  }

  function handleRemoveScan() {
    navigation.push("AdminLocationRemoveScan", {
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
    });
  }

  function handleInventoryCount() {
    navigation.push("AdminLocationInventoryCount", {
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
    });
  }

  function handleInventoryCountScan() {
    navigation.push("AdminLocationInventoryCountScan", {
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
    });
  }

  function handleAdjustDifference() {
    navigation.push("AdminLocationAdjustDifference", {
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
    });
  }

  function renderBody() {
    if (partner && location) {
      const kv: Record<string, string | number | boolean> = {};
      kv["Partner Id"] = partner.id.substring(0, 8) + " ...";
      kv["Partner Name"] = partner.name;
      kv["Location Id"] = location.id.substring(0, 8) + " ...";
      kv["Location Name"] = location.name;
      kv["POS Linked"] = "" + (partner.pos ?? false);

      return (
        <>
          <ScreenTitle
            title={location.name}
            secondaryTitle={"Overview"}
            menu={ifAdmin({
              "Deliver & Remove": handleDeliverRemove,
              "Deliver /w Scan": handleDeliverScan,
              "Remove /w Scan": handleRemoveScan,
              "Inventory Count": handleInventoryCount,
              "Inventory Count /w Scan": handleInventoryCountScan,
              "Clear Difference": handleAdjustDifference,
              "Change Subscription": handleChangeSubscription,
            })}
          />
          <InventoryLevelGauge isLoading={inventoryState.loading} data={inventoryState.doc} />
          <SectionForwardButton
            label="Inventory details"
            onPress={handleInventoryDetails}
            fontSize="sm"
          />
          <SectionForwardButton
            label="Container details"
            onPress={handleContainerDetails}
            fontSize="sm"
          />

          <AveragesReportView
            isLoading={reportEntityState.loading}
            avgBorrowCnt={reportEntityState.value?.avgBorrowCnt ?? 0}
            avgBorrowDays={reportEntityState.value?.avgBorrowDays ?? 0}
          />
          <ActivityReportContainer
            entityId={route.params.locationId}
            inclIncomplete={!!partner.pos || !!location.settings?.pos || !!location.onlineOrder}
          />

          <SectionForwardButton
            label="Recent Transaction"
            onPress={handleTransactionList}
            fontSize="sm"
          />

          <LocationInfoCard
            partnerId={route.params.partnerId}
            locationId={route.params.locationId}
          />
          <SectionForwardButton label="QR Codes" onPress={handleQRCodeList} fontSize="sm" />

          <IfAdmin>
            <SectionForwardButton label="Terminals" onPress={handleTerminalList} fontSize="sm" />
            <SectionForwardButton
              label="Billing & Other Details"
              onPress={handleLocationDetails}
              fontSize="sm"
            />
          </IfAdmin>
        </>
      );
    } else {
      return null;
    }
  }

  return <Screen name="Location Overview">{renderBody()}</Screen>;
};
