import React, { useCallback } from "react";
import { useAsync } from "../../hooks/reactUse";
import { Screen } from "../../components/layout/Screen";
import { LocationInfoCard } from "../../components/views/LocationInfoCard";
import { BackAppScreenProps } from "../../backAppStack";
import { CTransactionItemRepo } from "../../model/CTransactionItemDoc";
import { CTransactionDetailBlock } from "./CTransactionDetailBlock";
import { ifAdmin, ifCondition } from "../../components/conditional/IfAccess";
import { useRoles } from "../../lib/auth/useRoles";
import { docGet } from "../../lib/firestore/fstore";
import { AccountInfoCard } from "../account/AccountInfoCard";
import { ProfileInfoCard } from "../customer/ProfileInfoCard";
import { CTransactionItemTypes } from "../../lib/apiDefs";
import { ScreenTitle } from "../../components/layout/ScreenTitle";

const ctransactionNames: Record<CTransactionItemTypes, string> = {
  borrow: "Borrow",
  return: "Return",
  expire: "Container Expired",
  void: "Void Purchase",
};

export const CTransactionDetailScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminCTransactionDetails">) => {
  const { isAdmin } = useRoles();

  const handleProfileDetail = useCallback((profileId?: string | null) => {
    if (profileId) navigation.push("AdminCustomerDetails", { profileId });
  }, []);

  const handleAccountDetail = useCallback((accountId: string) => {
    navigation.push("AdminAccountDetails", { accountId });
  }, []);

  const handleRefund = useCallback((transactionId: string) => {
    navigation.navigate("AdminRefund", { transactionId });
  }, []);

  const transactionState = useAsync(async () =>
    docGet(CTransactionItemRepo.doc(route.params.transactionId))
  );

  const handleBorrowTransactionDetails = useCallback(() => {
    if (transactionState.value?.type === "expire") {
      const borrowId = transactionState.value.relations?.borrowIds?.[0];
      if (borrowId) {
        navigation.push("AdminCTransactionDetails", { transactionId: borrowId! });
      }
    }
  }, [transactionState.value]);

  return (
    <Screen name="Transaction Detail">
      {transactionState.value ? (
        <>
          <ScreenTitle
            title={ctransactionNames[transactionState.value.type] ?? transactionState.value.type}
            secondaryTitle={transactionState.value.locationName}
            menu={ifCondition(
              transactionState.value.type === "expire",
              ifAdmin({
                "Refund & Return": () => handleRefund(transactionState.value!.id),
                "View Borrow Transaction": handleBorrowTransactionDetails,
              })
            )}
          />
          {!!transactionState.value?.accountId && (
            <>
              <AccountInfoCard
                accountId={transactionState.value!.accountId!}
                onPress={
                  isAdmin
                    ? () => handleAccountDetail(transactionState.value!.accountId!)
                    : undefined
                }
              />
              <ProfileInfoCard
                profileId={transactionState.value.profileId}
                accountId={transactionState.value!.accountId!}
                onPress={isAdmin ? handleProfileDetail : undefined}
              />
            </>
          )}
          {transactionState.value.locationId ? (
            <LocationInfoCard
              partnerId={transactionState.value.partnerId}
              locationId={transactionState.value.locationId}
            />
          ) : null}
          <CTransactionDetailBlock transaction={transactionState.value} />
        </>
      ) : null}
    </Screen>
  );
};
