import React, { useCallback, useState } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import { Screen } from "../../components/layout/Screen";
import { PropertiesCard } from "../../components/properties/PropertiesCard";
import AccountCTransactionList from "../ctransactions/AccountCTransactionList";
import { useAsyncFn } from "../../hooks/reactUse";
import { useFocusEffect } from "@react-navigation/native";
import { StatsRow } from "../../components/composite/StatsRow";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { BackAppScreenProps } from "../../backAppStack";
import { ifAdmin } from "../../components/conditional/IfAccess";
import { VBalanceDoc, VBalanceRepo } from "../../model/VBalanceDoc";
import { useBackRpc } from "../../lib/functions/rpc";
import { CProfileDoc, CProfileRepo } from "../../model/CProfileDoc";
import { docGet } from "../../lib/firestore/fstore";
import { CardSection } from "../../components/layout/Sections";
import { PropertiesRecord } from "../../components/properties/types";
import { safe } from "../../util/safe";
import { CAccountDoc, CAccountRepo } from "../../model/CAccountDoc";
import { formatDateTime } from "../../util/format";
import { SectionForwardButton } from "../../components/layout/SectionForwardButton";
import { ProfileInfoCard } from "../customer/ProfileInfoCard";

export const AdminAccountDetailsScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminAccountDetails">) => {
  const toast = useToast();
  const [adminExtendState, doAdminExtend] = useBackRpc("adminExtendBorrow");
  const [msg, setMsg] = useState<string>();

  const [dataState, doLoadData] = useAsyncFn(async () => {
    const [account, balance] = await Promise.all([
      CAccountRepo.mustFindById(route.params.accountId),
      VBalanceRepo.mustFindById(route.params.accountId),
    ]);
    const profile = account?.ownerId ? await docGet(CProfileRepo.doc(account.ownerId)) : null;
    return { profile, account, balance };
  });

  const loadData = useCallback(() => {
    doLoadData();
  }, []);

  // regularly reload the screen, to see fresh data
  useFocusEffect(loadData);

  function renderDetails(
    profile: CProfileDoc | null | undefined,
    account: CAccountDoc,
    balance: VBalanceDoc
  ) {
    const kv: PropertiesRecord = {};
    kv["Account Id"] = { value: account.id, feature: ["clipboard"] };
    kv["Current Balance"] = balance?.outstandingSum ?? "0";
    kv["Next Expires"] = balance?.getNextExpiresAt()?.toLocaleDateString();
    if (profile) {
      if (profile.authenticated) {
        kv["Sign Up Date"] = profile.signUpAt ? formatDateTime(profile.signUpAt) : "-";
      }
    }

    const borrowed = balance?.total.borrowed.toFixed() ?? "0";
    const returned = safe.add(balance?.total.returned, balance?.total.credits).toFixed();
    const purchased = balance?.total.purchased.toFixed() ?? "0";

    return (
      <Box w="full">
        {!!msg && <SectionMsg text={msg} type="error" />}
        {!!profile && (
          <ProfileInfoCard
            title="Account owner"
            profileId={profile.id}
            onPress={handleProfileDetails}
          />
        )}
        {!profile && (
          <CardSection
            title="Account owner"
            json={{
              name: "Account",
              data: { account, balance },
            }}
          >
            <Text px={3} py={2} fontSize="sm">
              No app sign-up
            </Text>
          </CardSection>
        )}
        {dataState.value?.account.phone && (
          <SectionForwardButton
            label="Associated profiles"
            onPress={handleProfilesList}
            fontSize="sm"
          />
        )}
        <StatsRow title="Lifetime" borrowed={borrowed} returned={returned} purchased={purchased} />
        <PropertiesCard title="Details" properties={kv} />
        {account && <AccountCTransactionList accountId={account.id} />}
      </Box>
    );
  }

  const handleProfileDetails = useCallback((profileId: string | null | undefined) => {
    if (profileId) navigation.navigate("AdminCustomerDetails", { profileId });
  }, []);

  const handleProfilesList = useCallback(() => {
    if (dataState.value?.account.phone) {
      navigation.navigate("AdminProfilesList", { phone: dataState.value?.account.phone });
    }
  }, [dataState.value?.account.phone]);

  const handleExtend = useCallback(async () => {
    const result = await doAdminExtend({ accountId: route.params.accountId });
    if (result.success) {
      setMsg("");
      toast({
        position: "top",
        title: "Extend Confirmed",
        description: `The open borrow(s) were extended by 7 days.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setMsg(result.msg);
    }
  }, []);

  const handleBorrowOnBehalf = useCallback(() => {
    if (dataState.value?.profile) {
      navigation.navigate("AdminBorrow", { profileId: dataState.value.profile.id });
    }
  }, [dataState.value?.profile]);

  const handleReturnOnBehalf = useCallback(() => {
    navigation.navigate("AdminReturn", { accountId: route.params.accountId });
  }, []);

  const handleSendText = useCallback(() => {
    navigation.navigate("AdminCustomerSendText", {
      accountId: route.params.accountId,
    });
  }, []);

  const titleDetail =
    dataState.value?.account.type === "phone"
      ? "Account " + dataState.value?.account.phone
      : "Account";
  return (
    <Screen
      name={titleDetail}
      showTitle={true}
      titleMenu={ifAdmin(
        {
          "Extend borrow(s) by 7 days": !!dataState.value?.balance?.outstandingSum && handleExtend,
          "Borrow for Customer": dataState.value?.profile?.canBorrow() && handleBorrowOnBehalf,
          "Return for Customer": !!dataState.value?.balance?.outstandingSum && handleReturnOnBehalf,
          "Send Text Message": !!dataState.value?.account?.phone && handleSendText,
        },
        {}
      )}
    >
      {dataState.value
        ? renderDetails(
            dataState.value?.profile!,
            dataState.value?.account,
            dataState.value?.balance
          )
        : null}
    </Screen>
  );
};
