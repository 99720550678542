import React from "react";
import { Flex, FlexProps, SpaceProps } from "@chakra-ui/react";

export const VFlex = React.forwardRef<unknown, FlexProps>((props, ref) => {
  return (
    <Flex
      ref={ref}
      flexDirection="column"
      gridRowGap={0}
      gridColumnGap={0}
      gridGap={0}
      gap={0}
      columnGap={0}
      rowGap={0}
      {...props}
      sx={{ gap: 0 }}
    >
      {props.children}
    </Flex>
  );
});
