import React from "react";
import _isEmpty from "lodash/isEmpty";
import { Box, Text } from "@chakra-ui/react";
import { ActionMenu, MenuDef } from "../composite/ActionMenu";
import { HFlex } from "./HFlex";
import { VFlex } from "./VFlex";
import { SectionTextIndent } from "./SectionTextIndent";
import { Grow } from "./Grow";

export const ScreenTitle = (props: {
  title: string;
  secondaryTitle?: string;
  titleButton?: JSX.Element | null;
  menu?: MenuDef;
}) => {
  return (
    <SectionTextIndent mt={5} mb={2}>
      <HFlex>
        <VFlex justifyContent="center">
          {!!props.secondaryTitle && (
            <Text mb={-1} fontSize="xs" color="muted.400" fontWeight="400">
              {props.secondaryTitle}
            </Text>
          )}
          <Text fontSize="lg" fontWeight="700">
            {props.title}
          </Text>
        </VFlex>
        <VFlex justifyContent="center">{props.titleButton ? props.titleButton : null}</VFlex>
        <Grow />
        {props.menu && !_isEmpty(props.menu) ? <ActionMenu menu={props.menu} /> : null}
      </HFlex>
    </SectionTextIndent>
  );
};
