import React, { useCallback } from "react";
import { Screen } from "../../components/layout/Screen";
import { useRoles } from "../../lib/auth/useRoles";
import { CTransactionItemRepo } from "../../model/CTransactionItemDoc";
import { BackAppScreenProps } from "../../backAppStack";
import { CTransactionListCard } from "./CTransactionListCard";
import { useDocQuery } from "../../lib/firestore/fstore_hooks";

const TRANSACTION_LIMIT = 200;

export const AdminCTransactionsScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminRecentTransactionList">) => {
  const { eroot } = useRoles();

  const result = useDocQuery(
    CTransactionItemRepo.queryEntity(eroot)
      .orderBy("transactionAt", "desc")
      .limit(TRANSACTION_LIMIT)
  );

  const handleTransactionDetails = useCallback((transactionId: string) => {
    navigation.push("AdminCTransactionDetails", { transactionId });
  }, []);

  const msg = result.loading
    ? "Loading ..."
    : !result.value?.length
    ? `No transactions.`
    : result.value.length >= TRANSACTION_LIMIT
    ? `Only the most recent ${TRANSACTION_LIMIT} transactions are shown.`
    : "";

  return (
    <Screen name="Recent Transactions" showTitle={true}>
      <CTransactionListCard
        isLoading={result.loading}
        transactions={result.value}
        limit={TRANSACTION_LIMIT}
        showLocation={true}
        onPress={handleTransactionDetails}
      />
    </Screen>
  );
};
