// triggers the camera permission check, which then opens a permission dialog in
// the browser.
export const requestCameraPermission = async () => {
  await navigator.mediaDevices.getUserMedia({ video: true });
};

/* this code is taken from the expo camer library. it's the code to select a specific camera, either the front or
   back camera. The code is a bit changed, for example it defaults to the first camera if no specific camera could
   be selected. */
export async function findVideoInputDevice(labels: string[], type: string) {
  if (!navigator.mediaDevices.enumerateDevices) {
    return null;
  }

  // get all devices
  const devices = await navigator.mediaDevices.enumerateDevices();

  // filter for cameras
  const cameras = devices.filter((t) => t.kind === "videoinput");
  if (cameras.length === 0) {
    return null;
  }

  // search for camera with the correct label
  const [hasCamera] = cameras.filter((camera) =>
    labels.some((label) => camera.label.toLowerCase().includes(label))
  );

  // search for camera with the facing mode
  const [isCapable] = cameras.filter((camera) => {
    if (!("getCapabilities" in camera)) return null;

    const capabilities = (camera as InputDeviceInfo).getCapabilities();
    if (!capabilities.facingMode) return null;

    return capabilities.facingMode.find((mode: string) => mode === type);
  });

  // return the best matching device id
  return isCapable?.deviceId || hasCamera?.deviceId || cameras[0].deviceId;
}
