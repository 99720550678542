import React, { useCallback, useEffect, useState } from "react";
import { useBackRpc } from "../../lib/functions/rpc";
import { ListSelect } from "../../components/primitives/ListSelect";
import { VSpace } from "../../components/layout/VSpace";
import { Screen } from "../../components/layout/Screen";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { appUniversalDomain } from "../../util/config";
import { PropertiesCard } from "../../components/properties/PropertiesCard";
import { PointDoc, PointRepo } from "../../model/PointDoc";
import { BackAppScreenProps } from "../../backAppStack";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";
import { SectionSecondaryButton } from "../../components/layout/SectionSecondaryButton";

export const AdminPointDetailsScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminPoint">) => {
  const closeScreen = useCloseScreen();
  const { value: point } = useDocGet(PointRepo.doc(route.params.pointId));
  const [returnType, setReturnType] = useState<"self_return" | "self_return_w_scan" | undefined>();
  const [hasReturnUpdate, setHasReturnUpdate] = useState(false);
  const [pointUpdateState, doPointUpdate] = useBackRpc("pointUpdate");

  useEffect(() => {
    // is a return point?
    if (point?.type === "self_return" || point?.type === "self_return_w_scan") {
      setReturnType(point.type);

      // is there only one return point at that location?
      PointRepo.pointsAtSameLocation(point).then((points) => {
        const hasReturn = !!points.find((p) => p.type === "self_return");
        const hasReturnScan = !!points.find((p) => p.type === "self_return_w_scan");
        setHasReturnUpdate((hasReturn && !hasReturnScan) || (!hasReturn && hasReturnScan));
      });
    }
  }, [point?.id, setHasReturnUpdate]);

  function renderDetails(point: PointDoc) {
    const kv: Record<string, string | number | boolean> = {};
    kv["Id"] = point.id;
    kv["Type"] = point.type;
    kv["Partner"] = point.partnerName;
    kv["Location"] = point.locationName;

    return <PropertiesCard properties={kv} />;
  }

  const onCopy = useCallback(() => {
    // NOTE: this only works on web, but our BackApp is web based
    navigator.clipboard.writeText(`https://${appUniversalDomain()}/p/${route.params.pointId}`);
  }, []);

  const onUpdate = useCallback(async () => {
    if (point && returnType) {
      await doPointUpdate({ pointId: point?.id, type: returnType });
      closeScreen();
    }
  }, [point?.id, returnType]);

  return (
    <Screen name="Point Details" showTitle={true}>
      {point ? renderDetails(point) : null}
      <SectionPrimaryButton label="Copy to Clipboard" onClick={onCopy} />
      {hasReturnUpdate && (
        <>
          <VSpace h={4} />
          <ListSelect
            placeHolder="Select Product"
            list={[
              { id: "self_return", label: "Return" },
              { id: "self_return_w_scan", label: "Return with scan" },
            ]}
            selected={returnType}
            onSelect={setReturnType}
          />
          <SectionSecondaryButton
            isDisabled={pointUpdateState.loading}
            label="Update"
            onClick={onUpdate}
          />
        </>
      )}
    </Screen>
  );
};
