import { Screen } from "../../components/layout/Screen";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { CompactCounterInput } from "../../components/composite/CompactCounterInput";
import { ForwardButtonItem } from "../../components/primitives/ForwardButtonItem";
import { useEffect, useState } from "react";
import { useAsyncFn } from "../../hooks/reactUse";
import { BackAppScreenProps } from "../../backAppStack";
import { useBackRpc } from "../../lib/functions/rpc";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import { LocationRepo } from "../../model/LocationDoc";
import { CardSection } from "../../components/layout/Sections";
import { useToast } from "@chakra-ui/react";
import { VFlex } from "../../components/layout/VFlex";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminBorrowScreen = ({ navigation, route }: BackAppScreenProps<"AdminBorrow">) => {
  const closeScreen = useCloseScreen();
  const toast = useToast();
  const [adminBorrowState, doAdminBorrow] = useBackRpc("adminBorrow");
  const [borrowQty, setBorrowQty] = useState<number>(0);
  const locationState = useDocGet(LocationRepo.doc(route.params.locationId));

  const [borrowState, doBorrow] = useAsyncFn(
    async (borrowQty: number, partnerId: string, locationId: string) => {
      const result = await doAdminBorrow({
        profileId: route.params.profileId,
        partnerId,
        locationId,
        borrowQty,
      });
      return result;
    }
  );

  useEffect(() => {
    if (!borrowState.loading && !!borrowState.value?.success) {
      toast({
        position: "top",
        title: "Borrowed Confirmed",
        description: `You borrowed ${borrowQty} container(s) for the customer from ${locationState.value?.name}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      closeScreen();
    }
  }, [borrowQty, locationState.value?.name, borrowState.loading, borrowState.value?.success]);

  return (
    <Screen name="Borrow for Customer" showTitle={true}>
      <CardSection withDivider={true}>
        <CompactCounterInput
          preText="Borrow"
          postText="container(s)"
          maxValue={20}
          minValue={0}
          onNumChange={setBorrowQty}
        />
        <ForwardButtonItem
          label="Location"
          value={locationState.value ? locationState.value.name : "Select location"}
          onPress={() => navigation.navigate("SelectLocation")}
        />
      </CardSection>
      <SectionPrimaryButton
        label="Confirm"
        onClick={() => doBorrow(borrowQty, route.params.partnerId!, route.params.locationId!)}
        isDisabled={borrowQty === 0 || !locationState.value || borrowState.loading}
        isLoading={borrowState.loading}
      />
    </Screen>
  );
};
