import { BoxProps, ButtonProps } from "@chakra-ui/react";
import { CardSection, SectionBox } from "./Sections";
import { ForwardButtonItem } from "../primitives/ForwardButtonItem";
import _omit from "lodash/omit";
import _pick from "lodash/pick";

export const SectionForwardButton = (
  props: {
    label: string;
    value?: string;
    fontSize?: string;
    icon?: JSX.Element;
    onPress?: () => void;
  } & ButtonProps
) => {
  const spacingProps = [
    "m",
    "mt",
    "mb",
    "ml",
    "mr",
    "mx",
    "my",
    "p",
    "pt",
    "pb",
    "pl",
    "pr",
    "px",
    "py",
  ];
  const boxProps: any = _pick(props, spacingProps);
  const buttonProps: any = _omit(props, spacingProps);

  return (
    <CardSection {...boxProps}>
      <ForwardButtonItem {...buttonProps} />
    </CardSection>
  );
};
