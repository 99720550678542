import { useState } from "react";
import { Radio, RadioGroup, VStack } from "@chakra-ui/react";
import { AdjustReturnReasonCode } from "../../model/CTransactionItemDoc";

export const RefundReasonCodeSelector: React.FC<{
  setReasonCode: (code: AdjustReturnReasonCode) => void;
}> = (props) => {
  const [value, setValue] = useState<string>("");

  return (
    <RadioGroup
      name="Reason Code"
      colorScheme="teal"
      value={value}
      onChange={(nextValue) => {
        props.setReasonCode(nextValue as AdjustReturnReasonCode);
        setValue(nextValue);
      }}
    >
      <VStack gap={1} alignItems="start">
        <Radio value="did_not_scan">Customer did not scan</Radio>
        <Radio value="mispack">Restaurant mispack</Radio>
        <Radio value="duplicate_borrow">Duplicate borrow</Radio>
        <Radio value="returned_by_friend">Returned by Friend</Radio>
        <Radio value="late_return">Late Return</Radio>
        <Radio value="other">Other</Radio>
      </VStack>
    </RadioGroup>
  );
};
