import React, { useCallback, useState } from "react";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { QRCodeScanner } from "../../components/camera/QRCodeScanner";
import { FullScreen } from "../../components/layout/FullScreen";
import { CodeResult } from "../../components/camera/code";
import { BackAppScreenProps } from "../../backAppStack";
import { PointRepo } from "../../model/PointDoc";
import { useFocusEffect } from "@react-navigation/native";
import { docGet } from "../../lib/firestore/fstore";
import { SectionBox } from "../../components/layout/Sections";
import { Text } from "@chakra-ui/react";

export const AdminScanScreen = ({ navigation, route }: BackAppScreenProps<"AdminScan">) => {
  const closeScreen = useCloseScreen();

  const handleScan = useCallback((result: CodeResult) => {
    switch (result.type) {
      case "point":
        docGet(PointRepo.doc(result.code!)).then((point) => {
          if (point) {
            navigation.navigate("AdminPoint", { pointId: result.code! });
          }
        });
        break;

      case "item":
        navigation.navigate("AdminContainer", { containerId: result.code! });
        break;
    }
  }, []);

  return (
    <FullScreen name="Admin Scan">
      <QRCodeScanner
        expectedTypes={["item", "point"]}
        onScan={handleScan}
        onNoCamera={closeScreen}
      />
      <SectionBox mt={8}>
        <Text textAlign="center">
          Please scan any Recirclable QR code. Details for point and containers will be shown.
        </Text>
      </SectionBox>
    </FullScreen>
  );
};
