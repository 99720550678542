import React, { useEffect, useState } from "react";
import { SectionMsg, SectionMsgType } from "../../components/layout/SectionMsg";
import { PartnerDoc } from "../../model/PartnerDoc";
import { useBackRpc } from "../../lib/functions/rpc";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const SquareConnectButton = (props: { partner?: PartnerDoc | null }) => {
  const [msg, setMsg] = useState<string | null>(null);
  const [msgType, setMsgType] = useState<SectionMsgType>("muted");
  const [connected, setConnected] = useState<boolean>(false);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [prepareLinkState, submitPrepareLink] = useBackRpc("squarePrepareLink");

  useEffect(() => {
    console.log("SQ RETURN", window.location.href, window.location.search);
    if (new URLSearchParams(window.location.search).has("failure")) {
      setMsg(
        `Failure connecting with Square [${new URLSearchParams(window.location.search).get(
          "failure"
        )}]`
      );
      setMsgType("error");
      setConnecting(false);

      // TODO: in the embedded app, the callback from square opens a new web page. we now basically
      // have the page twice on the browser stack. this should be fixed. manipulating the history from
      // here isn't possible, because of browser secutiry.
      //
      // solutions:
      // - further research about window.history
      // - WebContainer recognizes duplicate url and handles it
      // - send message to WebContainer with cmd
      // - use a separe expo-webbrowser to show url, maybe even use a special auth session
    } else if (new URLSearchParams(window.location.search).has("success")) {
      setMsg("Successfully connected with Square");
      setMsgType("muted");
      setConnected(true);
      setConnecting(false);

      // TODO: see comment above
    }
  }, []);

  function handleConnect() {
    // with origin + pathname we remove all parameters, e.g. __token=
    let returnUri = window.location.origin + window.location.pathname;
    console.log("SQUARE RETURN URI", returnUri);

    // prepare the connection request in the database and then redirect to Square
    submitPrepareLink({ returnURL: `${returnUri}` }).then((result) => {
      if (result) {
        window.location.href = result.redirect;
      }
    });

    setConnecting(true);
  }

  // calculate button label
  let connectLabel = "...";
  if (props.partner) {
    connectLabel =
      props.partner.posLinked || connected ? "Connected with Square" : "Connect with Square";
  }
  const disabled = !props.partner || !props.partner.pos || props.partner.posLinked || connected;

  return (
    <>
      <SectionPrimaryButton
        mt={8}
        isDisabled={disabled}
        isLoading={connecting}
        onClick={handleConnect}
        label={connectLabel}
      />
      <SectionMsg text={msg} type={msgType} />
    </>
  );
};
