import _keys from "lodash/keys";
import { Box, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from "@chakra-ui/react";
import { ActionMenuIcon } from "../icons/ActionMenuIcon";

export type MenuDef = Record<string, false | undefined | null | (() => void)>;

export type ActionMenuProps = {
  menu: MenuDef;
};

export const ActionMenu = (props: ActionMenuProps) => {
  return (
    <Menu placement="bottom-end" gutter={3}>
      <MenuButton>
        <ActionMenuIcon color="muted" boxSize={6} />
      </MenuButton>
      <MenuList py={1}>
        {_keys(props.menu).map((key, index) => {
          return (
            <Box key={key}>
              {index > 0 && <MenuDivider my={1} />}
              <MenuItem
                px={3}
                py={1}
                _focus={{ bg: "white" }}
                isDisabled={!props.menu![key]}
                onClick={props.menu![key] ? (props.menu[key] as any) : undefined}
              >
                {key}
              </MenuItem>
            </Box>
          );
        })}
      </MenuList>
    </Menu>
  );
};
