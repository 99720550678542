import { useNavigation } from "@react-navigation/native";
import { useCallback } from "react";

export function useCloseScreen() {
  const navigation = useNavigation();

  return useCallback((backScreen?: string) => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      /* in case the app is called with deep link, we explicity go back to the first route, which is the home screen */
      navigation.navigate(navigation.getState().routeNames[0]);
    }
  }, []);
}
