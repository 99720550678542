import firebase from "firebase/compat/app";
import { ToastTxnData } from "../lib/apiDefs";
import { SubRepo, createDocBaseClass } from "../lib/firestore/fstore";

export class ToastTxnDataDoc extends createDocBaseClass<ToastTxnData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const ToastTxnDataRepo = new (class ToastTxnDataRepo extends SubRepo<ToastTxnDataDoc> {
  constructor() {
    super("c_transaction_items", "toast_data", ToastTxnDataDoc);
  }
})();
