// icon:gauge-empty | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";
import { createIcon } from "@chakra-ui/react";

export const InventoryLossIcon = createIcon({
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M12 2a10 10 0 0110 10 10 10 0 01-10 10A10 10 0 012 12 10 10 0 0112 2m0 2a8 8 0 00-8 8c0 2.4 1 4.5 2.7 6 1.4-1.3 3.3-2 5.3-2s3.8.7 5.3 2c1.7-1.5 2.7-3.6 2.7-6a8 8 0 00-8-8m2 2a1 1 0 011 1 1 1 0 01-1 1 1 1 0 01-1-1 1 1 0 011-1m-4 0a1 1 0 011 1 1 1 0 01-1 1 1 1 0 01-1-1 1 1 0 011-1M6.91 8.94c.13 0 .25.03.39.06l3.2 1.32.27.11c.56-.43 1.32-.55 1.98-.28 1.02.41 1.52 1.58 1.1 2.6a1.992 1.992 0 01-2.6 1.1c-.66-.26-1.13-.85-1.25-1.57l-.23-.1-3.22-1.3-.02-.01c-.53-.21-.76-.79-.56-1.31a1 1 0 01.94-.62M17 9a1 1 0 011 1 1 1 0 01-1 1 1 1 0 01-1-1 1 1 0 011-1z"
    />
  ),
});
