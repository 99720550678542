import React from "react";
import { Checkbox, Text } from "@chakra-ui/react";
import { JsonButton } from "../composite/JsonButton";
import { SectionTextIndent } from "./SectionTextIndent";
import { HFlex } from "./HFlex";

export const SectionTitle = (props: {
  title: string;
  filterLabel?: string;
  onFilterChange?: (value: boolean) => void;
  json?: {
    name?: string;
    data: any;
  };
}) => {
  const [checked, setChecked] = React.useState(false);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    setChecked(event.target.checked);
    props.onFilterChange?.(event.target.checked);
  }

  return (
    <SectionTextIndent my={0} mt={3} py={0}>
      <HFlex justifyContent="space-between" alignItems="flex-end">
        <Text color="gray.500" fontSize="sm" fontWeight="400">
          {props.title}
        </Text>
        <HFlex alignItems="center" justifyContent={"flex-end"}>
          {!!props.onFilterChange && (
            <>
              <Text color="gray.500" fontSize="md" fontWeight="400">
                {props.filterLabel ?? "This Profile"}
              </Text>
              <Checkbox
                mx={1}
                size="sm"
                colorScheme="gray"
                checked={checked}
                onChange={handleChange}
              />
            </>
          )}
          {props.json ? <JsonButton name={props.json.name} data={props.json.data} /> : null}
        </HFlex>
      </HFlex>
    </SectionTextIndent>
  );
};
