import firebase from "firebase/compat/app";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";
import { CActivityData } from "../lib/apiDefs";

export class CActivityDoc extends createDocBaseClass<CActivityData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const CActivityRepo = new (class CActivityRepo extends Repo<CActivityDoc> {
  constructor() {
    super("c_activities", CActivityDoc);
  }
})();
