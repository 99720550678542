import React from "react";
import { Text } from "@chakra-ui/react";
import { HFlex } from "../layout/HFlex";
import { VFlex } from "../layout/VFlex";
import { VSpace } from "../layout/VSpace";
import { ChevronForwardIcon } from "../icons/ChevronForwardIcon";
import { TextWithLoading } from "./TextWithLoading";

export const NumberTile = (props: {
  value: number;
  secondaryValue?: string;
  label: string;
  onDetails?: () => void;
  isLoading?: boolean;
}) => {
  return (
    <VFlex
      px={3}
      pt={2}
      pb={1}
      alignItems="flex-start"
      borderRadius={5}
      bgColor="white"
      w="full"
      onClick={props.onDetails}
    >
      <HFlex mb={2} alignItems="center" w="100%" justifyContent={"flex-start"}>
        <Text color="brand.text" fontSize="sm" fontWeight="400">
          {props.label}
        </Text>
        {props.onDetails ? <ChevronForwardIcon boxSize={4} /> : <VSpace h="24px" />}
      </HFlex>
      {!props.isLoading ? (
        <HFlex alignItems="baseline">
          <Text color="brand.text" fontSize="2xl" fontWeight="700">
            {props.value}
          </Text>
          &nbsp;
          {props.secondaryValue ?? (
            <Text ml={2} color="mute.400" fontSize="mg" fontWeight="400">
              {props.secondaryValue}
            </Text>
          )}
        </HFlex>
      ) : (
        <TextWithLoading textAlign="right" fontSize="2xl" isLoading={true} />
      )}
    </VFlex>
  );
};
