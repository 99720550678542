import React, { useCallback } from "react";
import { Box, IconButton, Text } from "@chakra-ui/react";
import { HFlex } from "../layout/HFlex";
import { PropertyValue, PropertyValueExtended, defaultValue } from "./types";
import { ModalInput } from "./ModalInput";
import { safe } from "../../util/safe";
import { CardSection } from "../layout/Sections";
import { PencilIcon } from "../icons/PencilIcon";
import { ClipboardIcon } from "../icons/ClipboardIcon";

export const PropertyRow = (props: {
  label: string;
  value: PropertyValue | PropertyValueExtended;
  onChange?: (key: string, value: PropertyValue) => void;
}) => {
  const [showInputModal, setShowInputModal] = React.useState(false);

  const property =
    props.value && typeof props.value === "object" ? props.value : { value: props.value };
  const displayValue = String(property?.value ?? "");
  const isEditable = !!property?.type && !!property?.key && property?.feature?.includes("edit");
  const isClipboard = !!displayValue && property?.feature?.includes("clipboard");

  const handleClipboard = useCallback((value: any) => {
    navigator.clipboard.writeText(String(value));
  }, []);

  const handleEdit = useCallback((label: PropertyKey, propExtended: PropertyValueExtended) => {
    setShowInputModal(true);
  }, []);

  const handleSubmitEdit = useCallback(
    (success: boolean, value?: string | number | boolean) => {
      setShowInputModal(false);
      if (success && value !== undefined && property.key) {
        let newValue: any = undefined;
        switch (property.type) {
          case "string":
            newValue = String(value);
            break;

          case "number":
            if (typeof value === "number") {
              newValue = value;
            } else if (typeof value === "string") {
              newValue = safe.parseInt(value);
            } else {
              return;
            }
            break;

          case "boolean":
            if (typeof value === "boolean") {
              newValue = value;
            } else if (typeof value === "string") {
              newValue = Boolean(value);
            } else {
              return;
            }
            break;

          default:
            return;
        }
        props.onChange?.(property.key, newValue);
      }
    },
    [property]
  );

  return (
    <>
      <HFlex key={props.label} w="full">
        <Box flex={3} flexWrap="wrap" bgColor="gray.50" px={3} py={1}>
          <Text
            textAlign="start"
            overflowWrap="break-word"
            wordBreak="break-all"
            sx={{ textWrap: "wrap" }}
          >
            {props.label}
          </Text>
        </Box>
        <HFlex flex={4} px={3} py={1} justifyContent="space-between" alignItems="center">
          <Box flex={1} flexWrap="wrap">
            <Text
              textAlign="start"
              overflowWrap="break-word"
              wordBreak="break-all"
              sx={{ textWrap: "wrap" }}
            >
              {displayValue ?? ""}
            </Text>
          </Box>
          {isEditable ? (
            <IconButton
              aria-label="Edit"
              variant="unstyled"
              size="xs"
              p={0}
              m={0}
              icon={<PencilIcon color="brand.text" boxSize={5} />}
              onClick={() => handleEdit(props.label, property)}
            />
          ) : null}
          {isClipboard ? (
            <IconButton
              aria-label="Copy"
              variant="unstyled"
              size="xs"
              p={0}
              m={0}
              icon={<ClipboardIcon color="brand.text" boxSize={5} />}
              onClick={() => handleClipboard(displayValue)}
            />
          ) : null}
        </HFlex>
      </HFlex>
      {showInputModal ? (
        <ModalInput
          isOpen={showInputModal}
          onClose={handleSubmitEdit}
          label={props.label}
          description={property.description}
          value={property.value ?? defaultValue[property.type ?? "string"]}
        />
      ) : null}
    </>
  );
};

export const PropertiesCard = (props: {
  properties: Record<string, PropertyValue | PropertyValueExtended>;
  title?: string;
  json?: {
    name?: string;
    data: any;
  };
  onChange?: (key: PropertyKey, value: PropertyValue) => void;
}) => {
  return (
    <CardSection title={props.title} json={props.json} withDivider={true}>
      {Object.getOwnPropertyNames(props.properties).map((label) => {
        return (
          <PropertyRow
            key={label}
            label={label}
            value={props.properties[label]}
            onChange={props.onChange}
          />
        );
      })}
    </CardSection>
  );
};
