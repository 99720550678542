import React, { useCallback } from "react";
import { ScreenTitle } from "../../components/layout/ScreenTitle";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { LocationRepo } from "../../model/LocationDoc";
import { useDocWatch, useDocQueryWatch } from "../../lib/firestore/fstore_hooks";
import { TerminalDoc, TerminalRepo } from "../../model/TerminalDoc";
import { SimpleList } from "../../components/primitives/SimpleList";
import { useBackRpc } from "../../lib/functions/rpc";
import _keys from "lodash/keys";
import _toPairs from "lodash/toPairs";
import { CardSection, CardSectionContent, CardSectionItem } from "../../components/layout/Sections";
import { Text } from "@chakra-ui/react";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminTerminalListScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminTerminalList">) => {
  const { doc: location } = useDocWatch(LocationRepo.doc(route.params.locationId));
  const { docs: terminals } = useDocQueryWatch(
    TerminalRepo.query().where("locationId", "==", route.params.locationId)
  );
  const [terminalCreateState, submitTerminalCreate] = useBackRpc("terminalCreate");

  const handleOnSelect = useCallback((terminal: TerminalDoc) => {
    navigation.navigate("AdminTerminalDetails", { terminalId: terminal.id });
  }, []);

  const handleCreate = useCallback(() => {
    submitTerminalCreate({
      locationId: route.params.locationId,
    });
  }, []);

  function renderList(terminals: TerminalDoc[] | undefined | null) {
    if (terminals && terminals.length > 0) {
      return (
        <SimpleList
          items={terminals}
          getItemLabel={(terminal) => {
            return {
              title: terminal.publicId,
              below: `${terminal.readerType} | ${terminal.getFeatureDescription()}`,
            };
          }}
          onSelect={handleOnSelect}
        />
      );
    } else {
      return (
        <CardSection>
          <CardSectionItem>
            <Text textAlign="center" color="brand.secondaryText">
              No terminals setup.
            </Text>
          </CardSectionItem>
        </CardSection>
      );
    }
  }

  return (
    <Screen name="Location Details">
      <ScreenTitle title={location?.name ?? "..."} secondaryTitle={"Terminals"} />
      {renderList(terminals)}
      <SectionPrimaryButton
        disabled={terminalCreateState.loading}
        label="Create Terminal"
        onClick={handleCreate}
      />
    </Screen>
  );
};
