import React from "react";
import { HFlex } from "./HFlex";
import { Box, IconButton, Image, Text } from "@chakra-ui/react";
import { BackIcon } from "../icons/BackIcon";
import { CloseIcon } from "../icons/CloseIcon";

export const HEADER_HEIGHT = 46;
export const HEADER_HEIGHT_PX = `${HEADER_HEIGHT}px`;
const HEADER_ICON_WIDTH = 46;
const HEADER_ICON_WIDTH_PX = `${HEADER_ICON_WIDTH}px`;

export const Header = (props: {
  title?: string;
  label?: string;
  backMode?: "none" | "close" | "back" | "both";
  onBack?: () => void;
  onLongBack?: () => void;
  onClose?: () => void;
  right?: React.ReactNode;
}) => {
  let IconLeft: React.ElementType | null = null;
  let IconRight: React.ElementType | null = null;
  let rightComponent = props.right;
  switch (props.backMode) {
    case "back":
      IconLeft = BackIcon;
      break;

    case "close":
      IconRight = CloseIcon;
      break;

    case "both":
      IconLeft = BackIcon;
      IconRight = CloseIcon;
      break;
  }

  return (
    <>
      <HFlex
        position="absolute"
        top={0}
        left={0}
        w="100%"
        h={`${HEADER_HEIGHT}px`}
        zIndex={10}
        justifyContent="center"
        alignItems="center"
        bg="white"
        borderColor="coolGray.200"
        borderBottomWidth={1}
        borderLeftWidth={1}
        borderRightWidth={1}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          justifyContent="center"
          alignItems="center"
          minW={HEADER_ICON_WIDTH_PX}
          w={HEADER_ICON_WIDTH_PX}
          h={HEADER_HEIGHT_PX}
          zIndex={11}
        >
          {IconLeft && (
            <IconButton
              aria-label="Back"
              onClick={() => props.onBack?.()}
              // onLongPress={props.onLongBack}
              variant="unstyled"
              mt="4px"
              ml="2px"
              icon={<IconLeft boxSize={`${HEADER_HEIGHT - 18}px`} color="brand.text" />}
            />
          )}
        </Box>
        <Box w="140px" h="100%" position="relative">
          <Image
            src={require("assets/logo/wordmark-darkblue.svg")}
            w="140px"
            h="100%"
            alt="Recirclable"
          />
          {props.label ? (
            <Box position="absolute" top={0} left="160px">
              <Box my={2} borderRadius={4} bgColor="lime.400">
                <Text mx={2} my={1}>
                  {props.label}
                </Text>
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box position="absolute" top={0} right={0} minW={HEADER_ICON_WIDTH_PX}>
          {rightComponent}
          {IconRight && (
            <IconButton
              aria-label="Close"
              onClick={() => (props.onClose ?? props.onBack)?.()}
              variant="unstyled"
              mt="4px"
              mr="2px"
              icon={<IconRight boxSize={`${HEADER_HEIGHT - 18}px`} color="brand.text" />}
            />
          )}
        </Box>
      </HFlex>
      <Box w="full" minH={HEADER_HEIGHT_PX} h={HEADER_HEIGHT_PX} bg="transparent" />
    </>
  );
};
