import { useState } from "react";
import { useRoles } from "../../lib/auth/useRoles";
import { useAsync } from "../../hooks/reactUse";
import {
  Divider,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { JsonIcon } from "../icons/JsonIcon";
import { JSONTree } from "react-json-tree";

const theme = {
  scheme: "google",
  author: "seth wright (http://sethawright.com)",
  base00: "#1d1f21",
  base01: "#282a2e",
  base02: "#373b41",
  base03: "#969896",
  base04: "#b4b7b4",
  base05: "#c5c8c6",
  base06: "#e0e0e0",
  base07: "#ffffff",
  base08: "#CC342B",
  base09: "#F96A38",
  base0A: "#FBA922",
  base0B: "#198844",
  base0C: "#3971ED",
  base0D: "#3971ED",
  base0E: "#A36AC7",
  base0F: "#3971ED",
};

export type JsonButtonProps = {
  name?: string;
  data: object | (() => object | Promise<object>);
};

export const JsonButton = (props: JsonButtonProps) => {
  const { isSuperAdmin } = useRoles();
  const [isOpen, setOpen] = useState(false);

  // don't show anything, if not a recirclable person
  if (!isSuperAdmin) return null;

  // lazy load the data
  const { value: data } = useAsync(async () => {
    if (isOpen) {
      if (typeof props.data === "function") {
        return props.data();
      } else {
        return props.data;
      }
    } else {
      return {};
    }
  }, [isOpen, props.data]);

  // when admin, then render JSON tree
  return (
    <>
      <IconButton
        variant="unstyled"
        m={0}
        p={0}
        aria-label="Json View"
        size="xs"
        icon={<JsonIcon color="muted" boxSize={4} />}
        onClick={() => setOpen(true)}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        size="xl"
        scrollBehavior="inside"
        closeOnOverlayClick={true}
      >
        <ModalOverlay />
        <JsonModalContent name={props.name} data={data} />
      </Modal>
    </>
  );
};

export const JsonModalContent = (props: { name?: string; data: any }) => {
  return (
    <ModalContent marginBottom="auto" marginTop="auto">
      <ModalCloseButton />
      <ModalHeader px={4} py={2}>
        {props.name ?? "JSON"}
      </ModalHeader>
      <ModalBody px={3} borderTopWidth={1} borderColor={"gray.300"}>
        <JSONTree
          theme={theme}
          invertTheme={true}
          data={props.data ?? {}}
          sortObjectKeys={(a, b) => a!.toString().localeCompare(b!.toString())}
          hideRoot={true}
          // shouldExpandNode={() => true}
        />
        {/* <ReactJson src={props.data ?? {}} /> */}
      </ModalBody>
    </ModalContent>
  );
};
