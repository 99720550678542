import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import once from "lodash/once";
import { appConfigExtra, appConfig, isEmulator } from "../../util/config";

export const emulatorPorts = {
  functions: 5001,
  hosting: 5000,
  auth: 9099,
  firestore: 8888,
};

export function getEmulatorHostName() {
  return "localhost";
}

export function webGoogleClientId(): string | undefined {
  // NOTE: the web client id is also necessary on native when running in Expo Go
  return appConfig()?.web?.config?.firebase?.clientId;
}

export const firebaseApp = once(() => {
  const fbConfig = appConfigExtra().firebase;
  return firebase.initializeApp(fbConfig!);
});

export const firebaseFirestore = once(() => {
  let fs = firebaseApp().firestore();
  if (isEmulator()) {
    fs.useEmulator(getEmulatorHostName(), emulatorPorts.firestore);
  }

  // this fixes an issues, when on some wifi connections the websocket for onSnapshot() fails.
  // the failure happens most likey because of some proxy configuration of the internet provider.
  // strangely enough this issue only happens on Safari and not in Chrome. a helpful source was:
  // https://github.com/firebase/firebase-js-sdk/issues/5977
  fs.settings({
    experimentalForceLongPolling: true,
    experimentalAutoDetectLongPolling: false,
    merge: true,
  });
  // note: "experimentalAutoDetectLongPolling: true" is not sufficient to solve issues

  /* FIXME: turn persistence off for now. it might cause further issue in the web app. the Firebase
     documentation is also not super enthusiastic about it in the web. for example it's not
     turned on by default, while on web it's turned on as default.
     
  // NOTE: synchronizeTabs=true causes problems in the WKWebView. unclear why. also not essential.
  fs.enablePersistence({ synchronizeTabs: false }).then(() => {
    console.log("🍏 FS PERSISTENCE ENABLED");
  }).catch(error => {
    console.log("🍎 FS PERSISTENCE FAILED", error);
  })
  */

  // turn on firestore debugging
  // firebase.firestore.setLogLevel('debug');

  return fs;
});

export const firebaseAuth = once(() => {
  var fa = firebaseApp().auth();
  if (isEmulator()) {
    fa!.useEmulator(`http://${getEmulatorHostName()}:${emulatorPorts.auth}`);
  }
  return fa!;
});

export const firebaseFunctions = once(() => {
  let fn = firebaseApp().functions("us-east4");
  if (isEmulator()) {
    fn.useEmulator(getEmulatorHostName(), emulatorPorts.functions);
  }
  return fn;
});
