import { Screen } from "../../components/layout/Screen";
import { useEffect, useState } from "react";
import { ForwardButtonItem } from "../../components/primitives/ForwardButtonItem";
import { useAsync, useAsyncFn } from "../../hooks/reactUse";
import { RefundReasonCodeSelector } from "./RefundReasonCodeSelector";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { BackAppScreenProps } from "../../backAppStack";
import { AdjustReturnReasonCode, CTransactionItemRepo } from "../../model/CTransactionItemDoc";
import { useBackRpc } from "../../lib/functions/rpc";
import { LocationRepo } from "../../model/LocationDoc";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import { docGet } from "../../lib/firestore/fstore";
import {
  CardSection,
  SectionBox,
  CardSectionContent,
  CardSectionItem,
} from "../../components/layout/Sections";
import { PrimaryButton } from "../../components/primitives/PrimaryButton";
import { Checkbox, Text, useToast } from "@chakra-ui/react";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminRefundScreen = ({ navigation, route }: BackAppScreenProps<"AdminRefund">) => {
  const closeScreen = useCloseScreen();
  const toast = useToast();
  const [adminVoidState, doAdminVoid] = useBackRpc("adminVoid");
  const [returnBowl, setReturnBowl] = useState<boolean>(false);
  const [reasonCode, setReasonCode] = useState<AdjustReturnReasonCode>();

  const borrowTransactionState = useAsync(async () => {
    const expireTransaction = await docGet(CTransactionItemRepo.doc(route.params.transactionId));
    if (expireTransaction && expireTransaction.relations?.borrowIds?.[0]) {
      const borrowTransaction = await docGet(
        CTransactionItemRepo.doc(expireTransaction.relations.borrowIds[0])
      );
      return borrowTransaction;
    }
  });

  useEffect(() => {
    if (!returnBowl) {
      navigation.setParams({
        partnerId: undefined,
        locationId: undefined,
      });
    } else {
      navigation.setParams({
        partnerId: borrowTransactionState.value?.partnerId,
        locationId: borrowTransactionState.value?.locationId,
      });
    }
  }, [returnBowl]);

  const locationState = useDocGet(LocationRepo.doc(route.params.locationId));

  const [refundState, doRefund] = useAsyncFn(async () => {
    const result = await doAdminVoid({
      expireTransactionId: route.params.transactionId,
      reasonCode: reasonCode ?? "other",
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
    });
    return result;
  }, [reasonCode, route.params.partnerId, route.params.locationId]);

  useEffect(() => {
    if (!refundState.loading && !!refundState.value?.success) {
      toast({
        position: "top",
        title: "Refund Confirmed",
        description: `You have successfully refunded the customer's fee(s).`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      closeScreen();
    }
  }, [!refundState.loading, !!refundState.value?.success]);

  return (
    <Screen name="Refund & Return" showTitle={true}>
      <CardSection withDivider={true}>
        <CardSectionItem>
          <Checkbox
            aria-label="Return container(s)"
            value={"return"}
            colorScheme="teal"
            borderColor="brand.text"
            onChange={(e) => setReturnBowl(e.target.checked)}
          >
            <Text>Return container(s)</Text>
          </Checkbox>
        </CardSectionItem>
        <ForwardButtonItem
          label="Location"
          value={returnBowl ? locationState.value?.name ?? "Select location" : "No Return"}
          onPress={() => navigation.navigate("SelectLocation")}
          isDisabled={!returnBowl}
        />
      </CardSection>
      <CardSection title="Reason Code">
        <CardSectionContent>
          <RefundReasonCodeSelector setReasonCode={setReasonCode} />
        </CardSectionContent>
      </CardSection>
      <SectionPrimaryButton
        mt={3}
        label="Submit"
        onClick={doRefund}
        isDisabled={!reasonCode || refundState.loading}
        isLoading={refundState.loading}
      />
    </Screen>
  );
};
