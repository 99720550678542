import React, { useCallback, useState } from "react";
import { useBackRpc } from "../../lib/functions/rpc";
import { HFlex } from "../../components/layout/HFlex";
import { VFlex } from "../../components/layout/VFlex";
import { useAsync } from "../../hooks/reactUse";
import { Screen } from "../../components/layout/Screen";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { CompactCounterInput } from "../../components/composite/CompactCounterInput";
import { LocationInfoCard } from "../../components/views/LocationInfoCard";
import { VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import { BackAppScreenProps } from "../../backAppStack";
import { docGet } from "../../lib/firestore/fstore";
import { CardSection } from "../../components/layout/Sections";
import { Box, Divider, Text } from "@chakra-ui/react";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminLocationInventoryCount = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationInventoryCount">) => {
  const closeScreen = useCloseScreen();
  const [actual, setActual] = useState<number>(0);
  const [invCountState, submitInvCount] = useBackRpc("locationInventoryCount");

  const { loading, value: ilevel } = useAsync(async () => {
    const ilevel = await docGet(
      VInventoryLevelRepo.doc(VInventoryLevelRepo.doc(route.params.locationId))
    );
    setActual(ilevel?.currentQuantity ?? 0);
    return ilevel;
  }, [route.params.locationId]);

  const handleSubmit = useCallback(async () => {
    await submitInvCount({
      locationId: route.params.locationId,
      qty: actual,
    });
    closeScreen();
  }, [actual, submitInvCount]);

  return (
    <Screen name="Inventory Count" showTitle={true}>
      <LocationInfoCard partnerId={route.params.partnerId} locationId={route.params.locationId} />
      <CardSection title="Inventory Count" p={2}>
        <VFlex alignItems="center" w="full">
          <Text textAlign="center" fontSize="sm">
            Expected Inventory
          </Text>
          <HFlex alignItems="center">
            <Text textAlign="center" fontSize="xl" fontWeight={700}>
              {loading ? "..." : ilevel?.currentQuantity ?? 0}
            </Text>
          </HFlex>
        </VFlex>
        <Divider my={2} orientation="horizontal" borderColor="brand.text" />
        <Box w="full">
          <CompactCounterInput
            minValue={0}
            value={actual}
            maxValue={1000}
            onNumChange={setActual}
            preText="Counting"
            postText="containers"
          />
        </Box>
      </CardSection>
      <SectionPrimaryButton
        label="Submit"
        onClick={handleSubmit}
        isLoading={invCountState.loading}
      />
    </Screen>
  );
};
