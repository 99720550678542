export type PropertyValue = string | number | boolean | null | undefined;

export type PropertyValueExtended = {
  key?: string;
  value: PropertyValue;
  type?: "string" | "number" | "boolean";
  feature?: ("edit" | "clipboard")[];
  description?: string;
};

export type PropertiesRecord = Record<string, PropertyValue | PropertyValueExtended>;

export const defaultValue = {
  string: "",
  number: 0,
  boolean: false,
};
