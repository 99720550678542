import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";

export const HFlex = React.forwardRef<unknown, FlexProps>((props, ref) => {
  return (
    <Flex ref={ref} flexDirection="row" {...props}>
      {props.children}
    </Flex>
  );
});
