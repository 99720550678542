import React, { useCallback, useMemo, useState } from "react";
import { BackAppScreenProps } from "../../backAppStack";
import { useDocQuery } from "../../lib/firestore/fstore_hooks";
import { ListItem, ListSelect } from "../../components/primitives/ListSelect";
import { ProductRepo } from "../../model/ProductDoc";
import { CardSection } from "../../components/layout/Sections";
import { FullScreen } from "../../components/layout/FullScreen";
import { ContainerScanner } from "../../components/containers/ContainerScanner";
import { ContainerDoc } from "../../model/ContainerDoc";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { VSpace } from "../../components/layout/VSpace";
import { ContainersView } from "../../components/containers/ContainersView";
import { useBackRpc } from "../../lib/functions/rpc";
import { Text } from "@chakra-ui/react";
import { Scrollable } from "../../components/primitives/Scrollable";
import { QR_SCANNER_HEIGHT } from "../../components/camera/QRCodeScanner";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const ContainerBulkAssignScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminContainer">) => {
  const closeScreen = useCloseScreen();
  const [productId, setProductId] = useState<string | null>();
  const productsState = useDocQuery(ProductRepo.query());
  const [containers, setContainers] = useState<ContainerDoc[]>([]);
  const [updateState, callUpdate] = useBackRpc("containerUpdate");

  const productList = useMemo<ListItem[] | undefined>(() => {
    if (productsState.value) {
      const products = productsState.value;
      products.sort((a, b) => a.name.localeCompare(b.name));
      return products.map((p) => {
        return {
          id: p.id,
          label: p.name,
        };
      });
    }
  }, [productsState.value?.length]);

  const handleValidateContainer = useCallback((container: ContainerDoc) => {
    if (container.productId) {
      return "Container already has a product id assigned.";
    }
    return null;
  }, []);

  const handleAddContainer = useCallback((container: ContainerDoc) => {
    setContainers((state) => {
      // NOTE: double scans can easily happen, because the camera is constantly reading QR codes. we
      //       filter duplicates codes earlier, but due to async actions it can still happen that
      //       handleAddContainer() is called twice with the same code. handle that situation here.
      const alreadyScanned = state.find((scanned) => scanned.id === container.id);
      if (alreadyScanned) return state;

      const newContainers = state.concat([container]); // <- concat() to get a new array
      newContainers.sort((a, b) => a.name?.localeCompare(b.name));
      return newContainers;
    });
  }, []);

  const handleAssign = useCallback(async () => {
    if (productId && containers.length) {
      await callUpdate({ productId, containerIds: containers.map((c) => c.id) });
      closeScreen();
    }
  }, [productId, containers]);

  const buttonLabel = productId
    ? containers.length
      ? `Assign ${containers.length} containers`
      : "Please scan a container code"
    : "Please select a container type";

  return (
    <FullScreen name="Select Container Type">
      <ContainerScanner
        alreadyScanned={containers}
        validate={handleValidateContainer}
        onAddContainer={handleAddContainer}
        onNoCamera={closeScreen}
      />
      <Scrollable maxH={`calc(100vh - ${QR_SCANNER_HEIGHT}px)`}>
        <ContainersView
          containers={containers}
          emptyMsg="Please scan the containers."
          continueMsg="Please continue scanning container(s)"
        />
        {productList && (
          <ListSelect
            placeHolder="Select Product"
            list={productList}
            selected={productId}
            onSelect={setProductId}
          />
        )}
        {!productList && (
          <CardSection>
            <Text mx={3} my={2}>
              Loading ...
            </Text>
          </CardSection>
        )}
        <SectionPrimaryButton
          mt={3}
          isDisabled={!productId || !containers.length || updateState.loading}
          isLoading={updateState.loading}
          label={buttonLabel}
          onClick={handleAssign}
        />
        <VSpace minH="60px" />
      </Scrollable>
    </FullScreen>
  );
};
