import { firebaseAuth } from "../firebase/fbenv";
import { sentry } from "../sentry/sentry";

//
// -----  auth  -----
//

// checks, whether a token was passed in and authenticates the user
//
// NOTE: we pass the token from the host app into the webview as global variable and not as
// URL parameter. the URL parameter might prevent the browser from caching the app, because the
// URL changes all the time. that way also the URL is never seen by intermediate infrastructure
// like proxies or caches.

export async function handleEmbeddedAuth() {
  try {
    // old way
    const params = new URLSearchParams(window.location.search);
    let token = params.get("__token");
    // new way
    if (!token) token = (window as any)?.Recirclable?.token;

    if (token) {
      hostAppLog(`AUTH WITH TOKEN ${token}`);

      // authentication the user
      const result = await firebaseAuth().signInWithCustomToken(token);
      hostAppLog(`AUTH TOKEN USER ${result.user?.uid} ${result.user?.displayName}`);
    }
  } catch (error) {
    sentry().captureException(error);
    hostAppError(`AUTH TOKEN ERROR ${String(error)}`);
  }
}

//
// -----  logging to the host app  -----
//

export type HostLogLevel = "log" | "error";

export type LogCmd = {
  cmd: "log";
  params: {
    level?: HostLogLevel;
    message?: string;
  };
};

function _hostAppLog(level: HostLogLevel, message: any) {
  console.log("LOG TO HOST", message);
  if (isEmbeddedWebApp()) {
    const msg = JSON.stringify({ cmd: "log", params: { level, message: String(message) } });
    // @ts-ignore
    window.ReactNativeWebView.postMessage(msg);
  }
}

export function hostAppLog(message: any) {
  _hostAppLog("log", message);
}

export function hostAppError(message: any) {
  _hostAppLog("error", message);
}

//
// -----  helper functions  -----
//

export function isEmbeddedWebApp() {
  return window.navigator.userAgent.includes("Recirclable");
}

export function ifEmbeddedApp<T, F>(trueValue: T, falseValue?: F): T | F | undefined {
  return isEmbeddedWebApp() ? trueValue : falseValue;
}

export function ifNotEmbeddedApp<T, F>(trueValue: T, falseValue?: F): T | F | undefined {
  return !isEmbeddedWebApp() ? trueValue : falseValue;
}
