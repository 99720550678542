import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AlertOk } from "./AlertOk";
import { FUNC_NOOP } from "../../util/constants";
import { AlertYesNo } from "./AlertYesNo";

type AlertOkOptions = {
  title: string;
  text: string | (() => JSX.Element);
  ok?: string;
};

type AlertYesNoOptions = {
  title: string;
  text: string | (() => JSX.Element);
  yes?: string;
  no?: string;
};

type YesNoCallbackType = (yesno: boolean | null | undefined) => void;

type AlertContextType = {
  openAlertOk: (opts: AlertOkOptions, callback: YesNoCallbackType) => void;
  openAlertYesNo: (opts: AlertYesNoOptions, callback: YesNoCallbackType) => void;
  closeAlert: YesNoCallbackType;
  dismissAlert: () => void;
};

const AlertContext = createContext<AlertContextType | null>(null);

export const AlertProvider = (props: { children?: JSX.Element | JSX.Element[] }) => {
  const [alertOkOptions, setAlertOkOptions] = useState<AlertOkOptions | null>(null);
  const [alertYesNoOptions, setAlertYesNoOptions] = useState<AlertYesNoOptions | null>(null);
  const callbackRef = useRef<YesNoCallbackType>(FUNC_NOOP);

  const dismissAlert = useCallback((yesno?: boolean | null) => {
    setAlertOkOptions(null);
    setAlertYesNoOptions(null);
    callbackRef.current = FUNC_NOOP;
  }, []);

  const closeAlert = useCallback((yesno?: boolean | null) => {
    callbackRef.current?.(yesno);
    setAlertOkOptions(null);
    setAlertYesNoOptions(null);
    callbackRef.current = FUNC_NOOP;
  }, []);

  const openAlertOk = useCallback((opts: AlertOkOptions, callback: YesNoCallbackType): void => {
    dismissAlert(); // dismiss any open alert
    setAlertOkOptions(opts);
    callbackRef.current = callback;
  }, []);

  const openAlertYesNo = useCallback(
    (opts: AlertYesNoOptions, callback: YesNoCallbackType): void => {
      dismissAlert(); // dismiss any open alert
      setAlertYesNoOptions(opts);
      callbackRef.current = callback;
    },
    []
  );

  const contextValue = useMemo(
    () => ({ openAlertOk, openAlertYesNo, closeAlert, dismissAlert }),
    []
  );

  useEffect(() => {
    return () => {
      // when the provider is unmounted also dismiss all alerts
      dismissAlert();
    };
  }, []);

  return (
    <AlertContext.Provider value={contextValue}>
      {props.children}
      {!!alertOkOptions && (
        <AlertOk
          isOpen={!!alertOkOptions}
          title={alertOkOptions.title}
          msg={alertOkOptions.text}
          primary={alertOkOptions.ok}
          onClose={closeAlert}
        />
      )}
      {!!alertYesNoOptions && (
        <AlertYesNo
          isOpen={!!alertYesNoOptions}
          title={alertYesNoOptions.title}
          msg={alertYesNoOptions.text}
          primary={alertYesNoOptions.yes}
          secondary={alertYesNoOptions.no}
          onClose={closeAlert}
        />
      )}
    </AlertContext.Provider>
  );
};

export const useAlerts = () => {
  const alerts = useContext(AlertContext);

  useEffect(() => {
    return () => {
      // if the containing view is unmounted, then make sure also all alerts are closed
      alerts?.dismissAlert();
    };
  }, []);

  return alerts!;
};
