import React from "react";
import { CardSection, CardSectionItem } from "../../components/layout/Sections";
import { HFlex } from "../../components/layout/HFlex";
import { useAsync } from "../../hooks/reactUse";
import { HSpace } from "../../components/layout/HSpace";
import { VProfileRepo } from "../../model/VProfileDoc";
import { VBalanceRepo } from "../../model/VBalanceDoc";
import { CProfileDoc, CProfileRepo } from "../../model/CProfileDoc";
import { CPaymentRepo } from "../../model/CPaymentDoc";
import { CActivityRepo } from "../../model/CActivityDoc";
import { docGet } from "../../lib/firestore/fstore";
import { CAccountRepo } from "../../model/CAccountDoc";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import { UPrivilegesRepo } from "../../model/UPrivilegesDoc";
import { Box, Text } from "@chakra-ui/react";
import { PhoneIcon } from "../../components/icons/PhoneIcon";
import { EmailIcon } from "../../components/icons/EmailIcon";
import { ChevronForwardIcon } from "../../components/icons/ChevronForwardIcon";
import { AccountIcon } from "../../components/icons/AccountIcon";
import { AccountOnlineIcon } from "../../components/icons/AccountIconOnline";
import { AccountTerminalIcon } from "../../components/icons/AccountTerminalIcon";
import { AccountAnonymousIcon } from "../../components/icons/AccountAnonymousIcon";
import { RolePartnerIcon } from "../../components/icons/RolePartnerIcon";
import { RoleAdminIcon } from "../../components/icons/RoleAdminIcon";
import { RoleProgramIcon } from "../../components/icons/RoleProgramIcon";
import { RoleTerritoryIcon } from "../../components/icons/RoleTerritoryIcon";
import { AccountVerfiedIcon } from "../../components/icons/AccountVerifiedIcon";
import { NotificationIcon } from "../../components/icons/NotificationIcon";
import { VFlex } from "../../components/layout/VFlex";

const typeIcon = {
  app: <AccountIcon boxSize={5} color="brand.text" />,
  online: <AccountOnlineIcon boxSize={5} color="brand.text" />,
  terminal: <AccountTerminalIcon boxSize={5} color="brand.text" />,
  anonymous: <AccountAnonymousIcon boxSize={5} color="brand.text" />,
} as const;

const PrimaryProfileBlock = (props: { profile: CProfileDoc; showChevron: boolean }) => {
  const { value: privileges } = useDocGet(UPrivilegesRepo.doc(props.profile.id));

  return (
    <CardSectionItem>
      <VFlex w="full">
        {/* name */}
        <HFlex justifyContent="space-between" alignItems="center" pb={1}>
          <HFlex alignItems="center">
            {props.profile.type && typeIcon[props.profile.type ?? "app"]}
            {!props.profile.type && <Box w={8} />}
            <Text
              pl={3}
              mr={2}
              fontWeight="700"
              textDecoration={props.profile.status === "archived" ? "line-through" : "none"}
            >
              {props.profile.name ?? ""}
            </Text>
            {privileges?.isPartner() && <RolePartnerIcon mr={1} boxSize={5} color="brand.text" />}
            {privileges?.isAdmin() && <RoleAdminIcon mr={1} boxSize={5} color="brand.text" />}
            {privileges?.isProgram() && <RoleProgramIcon mr={1} boxSize={5} color="brand.text" />}
            {privileges?.isTerritory() && (
              <RoleTerritoryIcon mr={1} boxSize={5} color="brand.text" />
            )}
          </HFlex>
          <HFlex alignItems="center">
            <Text
              color="brand.secondaryText"
              textDecoration={props.profile.status === "archived" ? "line-through" : "none"}
            >
              {`${props.profile.id.slice(0, 10)}...`}
            </Text>
            <HSpace w={1} />
            {props.showChevron && <ChevronForwardIcon boxSize={4} color="brand.text" />}
          </HFlex>
        </HFlex>

        {/* email */}
        <HFlex justifyContent="space-between" alignItems="center">
          <HFlex alignItems="center">
            <EmailIcon boxSize={5} color="brand.text" />
            <Text
              pl={3}
              textDecoration={props.profile.status === "archived" ? "line-through" : "none"}
            >
              {props.profile.email ?? ""}
            </Text>
          </HFlex>
          <HFlex>
            {props.profile.identityMethods().includes("email") && (
              <AccountVerfiedIcon boxSize={5} color="brand.text" />
            )}
            {props.profile.notificationMethods().includes("email") ? (
              <NotificationIcon boxSize={5} color="brand.text" />
            ) : (
              <Box w="20px" />
            )}
          </HFlex>
        </HFlex>

        {/* phone */}
        <HFlex justifyContent="space-between" alignItems="center">
          <HFlex alignItems="center">
            <PhoneIcon boxSize={5} color="brand.text" />
            <Text
              pl={3}
              textDecoration={props.profile.status === "archived" ? "line-through" : "none"}
            >
              {props.profile.phone ?? ""}
            </Text>
          </HFlex>
          <HFlex>
            {props.profile.identityMethods().includes("phone") && (
              <AccountVerfiedIcon boxSize={5} color="brand.text" />
            )}
            {props.profile.notificationMethods().includes("phone") ? (
              <NotificationIcon boxSize={5} color="brand.text" />
            ) : (
              <Box w="20px" />
            )}
          </HFlex>
        </HFlex>
      </VFlex>
    </CardSectionItem>
  );
};

const profileTypeName = {
  app: "app (web or native)",
  online: "online order",
  terminal: "card + phone",
  anonymous: "phone only",
};

const SecondaryProfileBlock = (props: { profile: CProfileDoc }) => {
  return (
    <CardSectionItem>
      <HFlex justifyContent="space-between" alignItems="center" w="full">
        <HFlex alignItems="center">
          {props.profile.type && typeIcon[props.profile.type ?? "app"]}
          {!props.profile.type && <Box w={8} />}
          <Text
            pl={3}
            mr={2}
            fontWeight="700"
            textDecoration={props.profile.status === "archived" ? "line-through" : "none"}
          >
            {`using ${profileTypeName[props.profile.type ?? "app"]}`}
          </Text>
        </HFlex>
        <HFlex alignItems="center">
          <Text
            color="brand.secondaryText"
            textDecoration={props.profile.status === "archived" ? "line-through" : "none"}
          >
            {`${props.profile.id.slice(0, 10)}...`}
          </Text>
        </HFlex>
      </HFlex>
    </CardSectionItem>
  );
};

export const ProfileInfoCard = (props: {
  profileId?: string | null;
  accountId?: string;
  title?: string;
  onPress?: (profileId?: string | null) => void;
}) => {
  const dataState = useAsync(async () => {
    const [profile, account] = await Promise.all([
      docGet(CProfileRepo.doc(props.profileId)),
      docGet(CAccountRepo.doc(props.accountId)),
    ]);

    let primaryProfile: CProfileDoc | null = profile;
    let secondaryProfile: CProfileDoc | null = null;
    if (account?.ownerId) {
      primaryProfile = await docGet(CProfileRepo.doc(account.ownerId));
      if (primaryProfile?.id !== profile?.id) secondaryProfile = profile;
    }

    return { primaryProfile, secondaryProfile, profile, account };
  });

  return (
    <CardSection
      title={props.title ?? "Profile"}
      json={{
        name: props.title ?? "Customer",
        data: async () => {
          const payment =
            dataState.value?.profile?.paymentId &&
            (await docGet(CPaymentRepo.doc(dataState.value?.profile?.paymentId)));
          const activity = await docGet(CActivityRepo.doc(props.profileId));
          const vprofile = await docGet(VProfileRepo.doc(props.profileId));
          const vbalance = await docGet(VBalanceRepo.doc(dataState.value?.account?.id));
          // FUTURE: also load the personal balance
          return {
            profile: dataState.value?.profile,
            account: dataState.value?.account,
            payment,
            activity,
            v_profile: vprofile,
            v_balance: vbalance,
          };
        },
      }}
      onClick={() => props.onPress?.(dataState.value?.primaryProfile?.id)}
      withDivider={true}
    >
      {dataState.value?.primaryProfile && (
        <PrimaryProfileBlock
          profile={dataState.value?.primaryProfile}
          showChevron={!!props.onPress}
        />
      )}
      {dataState.value?.secondaryProfile && (
        <SecondaryProfileBlock profile={dataState.value?.secondaryProfile} />
      )}
    </CardSection>
  );
};
