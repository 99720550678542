import firebase from "firebase/compat/app";
import {
  createDocBaseClass,
  docGet,
  docQuery,
  docQueryFirst,
  mustDocGet,
} from "../lib/firestore/fstore";
import { Repo } from "../lib/firestore/fstore";
import { CAccountData, PhoneNumber } from "../lib/apiDefs";

export class CAccountDoc extends createDocBaseClass<CAccountData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const CAccountRepo = new (class CAccountRepo extends Repo<CAccountDoc> {
  constructor() {
    super("c_accounts", CAccountDoc);
  }

  private phoneKey(phone: string) {
    return `phone:${phone}`;
  }

  async findById(id: string) {
    return docGet(this.doc(id));
  }

  async mustFindById(id: string) {
    return mustDocGet(this.doc(id));
  }

  async findByPhone(phone: string) {
    return docQueryFirst(
      this.query().where("status", "==", "active").where("key", "==", this.phoneKey(phone))
    );
  }

  async findAllByPhone(phone: PhoneNumber) {
    return docQuery(this.query().where("phone", "in", [phone, `*${phone}`]));
  }
})();
