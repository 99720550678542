import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { HFlex } from "../../components/layout/HFlex";
import { Screen } from "../../components/layout/Screen";
import { VInventoryLevelDoc, VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import { LTransactionList } from "../ltransactions/LTransactionList";
import InventoryLevelGauge from "../../components/report/InventoryLevelGauge";
import { BackAppScreenProps } from "../../backAppStack";
import _fill from "lodash/fill";
import { useDocWatch } from "../../lib/firestore/fstore_hooks";
import { CardSection } from "../../components/layout/Sections";
import { InventoryIcon } from "../../components/icons/InventoryIcon";
import { InventoryPlusIcon } from "../../components/icons/InventoryPlusIcon";
import { InventoryMinusIcon } from "../../components/icons/InventoryMinusIcon";
import { InventoryDiscrepancyIcon } from "../../components/icons/InventoryDiscrepancyIcon";
import { MultipleAccountsIcon } from "../../components/icons/MultipleAccountsIcon";
import { InventoryOutstandingIcon } from "../../components/icons/InventoryOutstandingIcon";
import { InventoryExpireIcon } from "../../components/icons/InventoryExpiredIcon";
import { InventoryToOtherIcon } from "../../components/icons/InventoryToOtherIcon";
import { InventoryFromOtherIcon } from "../../components/icons/InventoryFromOtherIcon";
import { ChevronRightIcon } from "../../components/icons/ChevronRightIcon";
import { ChevronDownIcon } from "../../components/icons/ChevronDownIcon";

type RowData = {
  show?: boolean;
  indent: number;
  label: string;
  value?: number;
  icon: JSX.Element;
};

// {props.indent ? 8 : 2}

const TotalRow = (props: {
  open: boolean | null; // true - open, false - close, null - no child
  label: string;
  value?: number;
  icon: JSX.Element;
  indent: number;
  onPress?: () => void;
}) => {
  return (
    <HFlex
      pl={`${8 + 16 * props.indent}px`}
      pr={4}
      py={1}
      my={1}
      alignItems="center"
      w="full"
      onClick={props.onPress}
    >
      {props.open === true && <ChevronDownIcon boxSize={6} color="brand.text" />}
      {props.open === false && <ChevronRightIcon boxSize={6} color="brand.text" />}
      {props.open === null && <Box w="24px" />}
      {props.icon}
      <Text pl={3} color="brand.text" fontSize="md" fontWeight="700">
        {props.label}
      </Text>
      <Box flex={2} />
      <Text minW="50px" color="brand.text" fontSize="md" fontWeight="700" textAlign="right">
        {props.value !== undefined ? props.value : "-"}
      </Text>
    </HFlex>
  );
};

const InventoryTotalTree = (props: { data: RowData[] }) => {
  const [openRows, setOpenRows] = useState<(boolean | null)[]>(
    props.data.map((row, index) => {
      if (index < props.data.length - 1 && row.indent < props.data[index + 1].indent) {
        return false;
      }
      return null;
    })
  );
  const [showRows, setShowRows] = useState<boolean[]>(_fill(Array(props.data.length), false));

  return (
    <CardSection title="Total" withDivider={true}>
      {props.data.map((row, index) => {
        if (row.indent === 0 || showRows[index]) {
          return (
            <TotalRow
              key={row.label}
              open={openRows[index]}
              label={row.label}
              value={row.value}
              icon={row.icon}
              indent={row.indent}
              onPress={() => {
                let childIndex = index + 1;
                if (childIndex < props.data.length && openRows[index] !== null) {
                  const newOpen = [...openRows];
                  const newShow = [...showRows];
                  if (openRows[index] === true) {
                    // currently expaned -> hide all children
                    newOpen[index] = false;
                    while (props.data[childIndex] && props.data[childIndex].indent !== row.indent) {
                      newShow[childIndex] = false;
                      childIndex++;
                    }
                  } else if (openRows[index] === false) {
                    // currently collapsed -> show immediate children
                    newOpen[index] = true;
                    while (props.data[childIndex] && props.data[childIndex].indent !== row.indent) {
                      if (props.data[childIndex].indent === row.indent + 1) {
                        newShow[childIndex] = true;
                        if (openRows[childIndex] !== null) newOpen[childIndex] = false;
                      }
                      childIndex++;
                    }
                  }
                  setOpenRows(newOpen);
                  setShowRows(newShow);
                }
              }}
            />
          );
        }
      })}
    </CardSection>
  );
};

const InventoryTotals = (props: { levels?: VInventoryLevelDoc | null }) => {
  const data: RowData[] = [];

  data.push({
    indent: 0,
    label: "Net received",
    value: props.levels?.deliverSum,
    icon: <InventoryIcon boxSize={8} color="brand.text" />,
  });
  data.push({
    indent: 1,
    label: "Delivered",
    value: props.levels?.deliverInSum,
    icon: <InventoryPlusIcon boxSize={8} color="brand.text" />,
  });
  data.push({
    indent: 1,
    label: "Removed",
    value: props.levels?.deliverOutSum,
    icon: <InventoryMinusIcon boxSize={8} color="brand.text" />,
  });

  data.push({
    indent: 0,
    label: "Net Inventory discrepancy",
    value: props.levels?.countSum,
    icon: <InventoryDiscrepancyIcon boxSize={8} color="brand.text" />,
  });

  data.push({
    indent: 0,
    label: "Net customer activity",
    value: (props.levels?.itemSum ?? 0) - (props.levels?.outstandingSum ?? 0),
    icon: <MultipleAccountsIcon boxSize={8} color="brand.text" />,
  });
  data.push({
    indent: 1,
    label: "Returned to other location",
    value: props.levels?.xreturnToOtherSum,
    icon: <InventoryToOtherIcon boxSize={8} color="brand.text" />,
  });
  data.push({
    indent: 1,
    label: "Returned from other location",
    value: props.levels?.xreturnFromOtherSum,
    icon: <InventoryFromOtherIcon boxSize={8} color="brand.text" />,
  });
  data.push({
    indent: 1,
    label: "Expired",
    value: props.levels?.expireSum,
    icon: <InventoryExpireIcon boxSize={8} color="brand.text" />,
  });

  data.push({
    indent: 0,
    label: "Currently borrowed",
    value: props.levels ? props.levels.outstandingSum : undefined,
    icon: <InventoryOutstandingIcon boxSize={8} color="brand.text" />,
  });

  return <InventoryTotalTree data={data} />;
};

export const AdminLocationInventoryDetailsScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationInventoryDetails">) => {
  const inventoryState = useDocWatch(VInventoryLevelRepo.doc(route.params.locationId));

  function handleTransactionDetails(transactionId: string) {
    navigation.push("AdminLTransactionDetails", { transactionId });
  }

  return (
    <Screen
      name={inventoryState.doc ? inventoryState.doc.locationName : ""}
      secondaryTitle={"Inventory Details"}
      showTitle={true}
    >
      <InventoryLevelGauge isLoading={inventoryState.loading} data={inventoryState.doc} />
      <InventoryTotals levels={inventoryState.doc} />
      <LTransactionList
        partnerId={route.params.partnerId}
        locationId={route.params.locationId}
        onPress={handleTransactionDetails}
      />
    </Screen>
  );
};
