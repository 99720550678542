import React, { useEffect, useState } from "react";
import { BackAppScreenProps } from "../../backAppStack";
import { LocationMetricList } from "../../components/views/LocationMetricList";
import { VInventoryLevelDoc, VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import { useRoles } from "../../lib/auth/useRoles";
import { useDocQuery } from "../../lib/firestore/fstore_hooks";
import { SectionBox } from "../../components/layout/Sections";
import { HFlex } from "../../components/layout/HFlex";
import { Box, Text } from "@chakra-ui/react";
import TabControl from "../../components/primitives/TabControl";
import { Screen } from "../../components/layout/Screen";

export const AdminLocationListScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationList">) => {
  const { eroot } = useRoles();

  // all production locations
  let ilevelListState = useDocQuery(
    VInventoryLevelRepo.queryEntity(eroot).where("test", "!=", true)
  );

  // all test locations
  let testILevelListState = useDocQuery(
    VInventoryLevelRepo.queryEntity(eroot).where("test", "==", true)
  );

  const [locations, setLocations] = useState<VInventoryLevelDoc[]>();
  const [testLocations, setTestLocations] = useState<VInventoryLevelDoc[]>();

  const sortLabels: string[] = ["A - Z", "Low", "High"];
  const [sortIndex, setSortIndex] = useState<number>(0);

  const sortByName = (array: VInventoryLevelDoc[] | undefined) => {
    if (array) {
      return [...array].sort((a, b) => a.locationName.localeCompare(b.locationName));
    }
  };

  const sortByInventory = (array: VInventoryLevelDoc[] | undefined, asc: boolean) => {
    if (array) {
      if (asc) {
        return [...array].sort((a, b) => a.currentQuantity - b.currentQuantity);
      } else {
        return [...array].sort((a, b) => b.currentQuantity - a.currentQuantity);
      }
    }
  };

  useEffect(() => {
    if (sortIndex === 1) {
      setLocations(sortByInventory(ilevelListState.value, true));
      setTestLocations(sortByInventory(testILevelListState.value, true));
    } else if (sortIndex === 2) {
      setLocations(sortByInventory(ilevelListState.value, false));
      setTestLocations(sortByInventory(testILevelListState.value, false));
    } else {
      setLocations(sortByName(ilevelListState.value));
      setTestLocations(sortByName(testILevelListState.value));
    }
  }, [sortIndex, ilevelListState.value, testILevelListState.value]);

  return (
    <Screen name="Locations" showTitle={true}>
      <Box px={4}>
        <TabControl values={sortLabels} selectedIndex={sortIndex} onTabPress={setSortIndex} />
      </Box>
      <SectionBox my={0}>
        <HFlex px={3} pt={2} alignItems="center" w="100%" justifyContent="space-between">
          <Text flex={2} color="brand.text" fontSize="md">
            Location
          </Text>
          <HFlex flex={1} justifyContent="space-around">
            <Text flex={1} textAlign="end" color="brand.text" fontSize="md">
              B
            </Text>
            <Text flex={1} textAlign="end" color="brand.text" fontSize="md">
              I
            </Text>
            <Text flex={1} textAlign="end" color="brand.text" fontSize="md">
              S/E
            </Text>
          </HFlex>
        </HFlex>
      </SectionBox>
      {locations && locations.length > 0 && (
        <LocationMetricList
          items={locations}
          onSelect={async (ilevel: VInventoryLevelDoc) => {
            navigation.push("AdminLocationOverview", {
              partnerId: ilevel.partnerId,
              locationId: ilevel.id,
            });
          }}
        />
      )}
      {testLocations && testLocations.length > 0 && (
        <LocationMetricList
          items={testLocations}
          onSelect={async (ilevel: VInventoryLevelDoc) => {
            navigation.push("AdminLocationOverview", {
              partnerId: ilevel.partnerId,
              locationId: ilevel.id,
            });
          }}
        />
      )}
    </Screen>
  );
};
