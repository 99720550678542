import React from "react";
import { HFlex } from "../layout/HFlex";
import { Box, ButtonProps, Text } from "@chakra-ui/react";
import { ChevronForwardIcon } from "../icons/ChevronForwardIcon";

export const ForwardButtonItem = (
  props: {
    label: string;
    value?: string;
    fontSize?: string;
    icon?: JSX.Element;
    onPress?: () => void;
  } & ButtonProps
) => {
  return (
    <HFlex
      flexDirection="row"
      p={2}
      px={3}
      justifyContent="space-between"
      alignItems="center"
      w="full"
      onClick={props.isDisabled ? undefined : props.onPress}
    >
      {props.icon ? (
        <>
          {props.icon}
          <Box w="14px" />
        </>
      ) : null}
      <Text
        fontSize={props.fontSize ? props.fontSize : "md"}
        color={props.isDisabled ? "muted" : "brand.text"}
      >
        {props.label}
      </Text>
      <Box flexGrow={1} />
      <Text
        mx={1}
        fontSize={props.fontSize ? props.fontSize : "md"}
        color={props.isDisabled ? "muted" : "brand.text"}
      >
        {props.value}
      </Text>
      <ChevronForwardIcon boxSize={4} color={props.isDisabled ? "muted" : "brand.text"} />
    </HFlex>
  );
};
