import { Button, ButtonProps } from "@chakra-ui/react";
import _omit from "lodash/omit";
import React from "react";

export const SecondaryButton = (props: { label: string } & ButtonProps) => {
  const extraProps = _omit(props, ["label", "colorScheme", "variant"]);

  return (
    <Button
      variant="unstyled"
      display={"flex"}
      flexDirection={"row"}
      alignContent={"center"}
      alignItems={"center"}
      p={1}
      bg="white"
      borderWidth={0}
      borderColor="teal.500"
      borderRadius="xl"
      fontWeight={600}
      color="teal.700"
      _hover={{ bg: "white" }}
      _active={{ bg: "teal.700", color: "white" }}
      isDisabled={props.isDisabled || props.isLoading}
      {...extraProps}
    >
      {props.label}
    </Button>
  );
};
