import React from "react";
import { LocationCTransactionList } from "./LocationCTransactionList";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";

export const LocationTransactionListScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationTransactionList">) => {
  const handleDetail = (transactionId: string) => {
    navigation.push("AdminCTransactionDetails", { transactionId });
  };

  return (
    <Screen
      name={route.params.locationName ?? "Location"}
      secondaryTitle="Customer transactions"
      showTitle={true}
    >
      <LocationCTransactionList
        partnerId={route.params.partnerId}
        locationId={route.params.locationId}
        onPress={handleDetail}
      />
    </Screen>
  );
};
