import { ONE_DAY } from "./constants";

// Date(1970, 0, 1) sets time portion in local time. this is intentionally, so
// reference point is in the local timezone.
const REF_TIME = new Date(1970, 0, 1).getTime();

// days since the reference time
function days(date: Date): number {
  return Math.trunc((date.getTime() - REF_TIME) / ONE_DAY);
}

// days until the provided future date is reached
export function daysUntil(date: Date, now: Date = new Date()): number {
  return days(date) - days(now);
}

// days since provided date, which is in the past
export function daysSince(date: Date, now: Date = new Date()): number {
  return days(now) - days(date);
}
