import firebase from "firebase/compat/app";
import { CTransactionItemData } from "../lib/apiDefs";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";
export type { AdjustReturnReasonCode } from "../lib/apiDefs";

export class CTransactionItemDoc extends createDocBaseClass<CTransactionItemData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const CTransactionItemRepo =
  new (class CTransactionItemRepo extends Repo<CTransactionItemDoc> {
    constructor() {
      super("c_transaction_items", CTransactionItemDoc);
    }
  })();
