import React, { useState } from "react";
import { HFlex } from "../layout/HFlex";
import { Box, IconButton, Text } from "@chakra-ui/react";
import { CounterMinusIcon } from "../icons/CounterMinusIcon";
import { CounterPlusIcon } from "../icons/CounterPlusIcon";

export const CompactCounterInput = (props: {
  preText: string;
  postText: string;
  maxValue: number;
  minValue: number;
  value?: number;
  onNumChange: (value: number) => void;
}) => {
  const [internalValue, setInternalValue] = useState<number>(props.value ?? props.minValue);

  const value = props.value ?? internalValue;

  return (
    <HFlex alignItems="center" justifyContent="space-between" flexGrow={1} my={1} w="full">
      <Text flex={3} textAlign="right">
        {props.preText}
      </Text>
      <IconButton
        variant="unstyled"
        flex={1}
        aria-label="Decrement"
        icon={<CounterMinusIcon color="teal.800" boxSize={9} />}
        onClick={() => {
          props.onNumChange(value - 1);
          setInternalValue(value - 1);
        }}
        isDisabled={value === props.minValue}
        disabled={value === props.minValue}
      />
      <Box flex={2} bg="green.200" borderRadius={4}>
        <Text my={1} textAlign="center" fontSize="xl" fontWeight="bold">
          {value}
        </Text>
      </Box>
      <IconButton
        variant="unstyled"
        flex={1}
        aria-label="Increment"
        icon={<CounterPlusIcon color="teal.800" boxSize={9} />}
        onClick={() => {
          props.onNumChange(value + 1);
          setInternalValue(value + 1);
        }}
        isDisabled={value === props.maxValue}
        disabled={value === props.maxValue}
      />
      <Text flex={3} textAlign="left">
        {props.postText}
      </Text>
    </HFlex>
  );
};
