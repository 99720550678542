import React from "react";
import { PropertiesCard } from "../../components/properties/PropertiesCard";
import { LTransactionItemDoc } from "../../model/LTransactionItemDoc";
import { formatDateTime } from "../../util/format";

export const LTransactionDetailBlock: React.FC<{
  transaction: LTransactionItemDoc;
  showInternalValues: boolean;
}> = (props) => {
  const kv: Record<string, string | number | boolean> = {};
  if (props.showInternalValues) {
    kv["Transaction Id"] = props.transaction.id;
  }
  kv["Transaction At"] = formatDateTime(props.transaction.transactionAt);
  kv["Container count"] = props.transaction.qty;

  return (
    <PropertiesCard
      title="Details"
      json={{
        name: "Transaction",
        data: async () => {
          return {
            l_transaction_items: props.transaction,
          };
        },
      }}
      properties={kv}
    />
  );
};
