import React, { useEffect, useState } from "react";
import { useAsync } from "../../hooks/reactUse";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { ListItem, ListSelect } from "../../components/primitives/ListSelect";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { PointType, PointRepo } from "../../model/PointDoc";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { useBackRpc } from "../../lib/functions/rpc";
import { useDocQuery } from "../../lib/firestore/fstore_hooks";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

function generatePointId() {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let pointId = "";
  for (let i = 0; i < 20; i++) {
    pointId += chars[Math.floor(Math.random() * chars.length)];
  }
  return pointId;
}

const pointTypes: ListItem[] = [
  {
    id: "self_borrow_w_confirm",
    label: "Borrow",
  },
  {
    id: "self_return",
    label: "Return",
  },
  {
    id: "self_return_w_scan",
    label: "Return with scan",
  },
  {
    id: "pos",
    label: "POS Checkout",
  },
];

interface Selection {
  type: PointType | null;
  sqLocationId: string | null;
}

export const AdminCreateQRCodeScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminCreateQRCode">) => {
  const closeScreen = useCloseScreen();
  const { value: pointDocs } = useDocQuery(
    PointRepo.query().where("locationId", "==", route.params.locationId)
  );
  const [pointId] = useState<string>(generatePointId());
  const [msg, setMsg] = useState<string | null>(null);
  const [selection, setSelection] = useState<Selection>({
    type: null,
    sqLocationId: null,
  });
  const [activationState, submitActivation] = useBackRpc("pointActivate");
  const [sqLocationsState, submitSqLocations] = useBackRpc("squareLocations");

  // read Square location list
  const sqLocationListState = useAsync(async () => {
    if (selection.type === "pos") {
      const result = await submitSqLocations({
        partnerId: route.params.partnerId,
      });
      return result.locations;
    }
    return null;
  }, [selection.type]);

  function onTypeSelect(type: PointType) {
    setSelection((current) => ({ ...current, type, sqLocationId: null }));
  }

  function onSqLocationSelect(sqLocationId: string) {
    setSelection((current) => ({ ...current, sqLocationId }));
  }

  function handleActivate() {
    const hasSameType = pointDocs?.find((p) => p.type === selection.type);
    if (hasSameType) {
      const yesno = window.confirm(
        `There is already a [${selection.type}] QR code for this location. Really create another one?`
      );
      if (!yesno) {
        closeScreen();
        return;
      }
    }
    submitActivation({
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
      sqLocationId: selection.sqLocationId,
      pointId,
      type: selection.type!,
    });
  }

  // when the activation is done, either show message or navigate to the home page
  useEffect(() => {
    if (!activationState.loading && activationState.value) {
      if (activationState.value?.msg) {
        setMsg(activationState.value?.msg);
      } else {
        closeScreen();
      }
    }
  }, [activationState]);

  const isReady = selection.type && (selection.type != "pos" || selection.sqLocationId);

  return (
    <Screen name="Create QR Code" showTitle={true}>
      <ListSelect
        placeHolder="Select Point Type"
        list={pointTypes}
        selected={selection.type}
        onSelect={onTypeSelect}
      />
      {selection.type === "pos" && sqLocationListState.value ? (
        <ListSelect
          placeHolder="Select POS Location"
          list={sqLocationListState.value}
          selected={selection.sqLocationId}
          onSelect={onSqLocationSelect}
        />
      ) : null}
      <SectionPrimaryButton
        isDisabled={!isReady || activationState.loading}
        isLoading={activationState.loading || sqLocationListState.loading}
        label="Create"
        onClick={handleActivate}
      />
      <SectionMsg text={msg} type="error" />
    </Screen>
  );
};
