import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { PrimaryButton } from "../primitives/PrimaryButton";

export const AlertOk: React.FunctionComponent<{
  title: string;
  msg: string | (() => JSX.Element);
  primary?: string;
  isOpen: boolean;
  onClose?: (yes?: boolean) => void;
}> = (props) => {
  const leastDestructiveRef = React.useRef(null);
  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose?.(true);
      }}
      size="xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx={4} bg="brand.bg">
          <AlertDialogCloseButton />
          <AlertDialogHeader textAlign="center" fontWeight="700" fontSize="md">
            {props.title}
          </AlertDialogHeader>
          <AlertDialogBody
            px={6}
            py={4}
            borderTopWidth={1}
            borderBottomWidth={1}
            borderColor={"gray.300"}
          >
            {typeof props.msg === "function" ? props.msg() : props.msg}
          </AlertDialogBody>
          <AlertDialogFooter justifyContent="center">
            <PrimaryButton
              onClick={() => props.onClose?.(true)}
              label={props.primary ?? "Ok"}
              minW="30%"
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
