import { useEffect } from "react";
import { useError, useTimeoutFn } from "./reactUse";
import { WAIT_SCREEN_TIMEOUT } from "../components/layout/WaitScreen";

export function usePendingWatchDog(pending: boolean, msg: string = "") {
  const dispatchError = useError();

  const [_isReady, cancelTimeout, _resetTimeout] = useTimeoutFn(() => {
    console.log("🍎 WatchDog timeout", msg);
    dispatchError(new Error("Waiting too long. " + msg));
  }, WAIT_SCREEN_TIMEOUT);

  useEffect(() => {
    if (!pending) cancelTimeout();
  }, [pending]);
}
