import React from "react";
import { safe } from "../../util/safe";
import InventoryGauge from "./InventoryGauge";
import { VInventoryLevelDoc } from "../../model/VInventoryLevelDoc";

export const InventoryLevelGauge = (props: {
  isLoading: boolean;
  data: VInventoryLevelDoc | null | undefined;
}) => {
  const subscription = safe.get(props.data?.subscriptionSum);
  const borrowed = safe.neg(props.data?.outstandingSum);
  const inventory = safe.add(props.data?.deliverSum, props.data?.itemSum, props.data?.countSum);
  const difference = safe.get(props.data?.differenceSum);

  return (
    <InventoryGauge
      isLoading={props.isLoading}
      subscription={subscription}
      borrowed={borrowed}
      inventory={inventory}
      difference={difference}
      lastCountAt={props.data?.lastCountAt}
    />
  );
};

export default InventoryLevelGauge;
