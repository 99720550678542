import { useRoles } from "../../lib/auth/useRoles";
import { If } from "./If";

//
// ----- conditional components  -----
//

export const IfAdmin = (props: { children: JSX.Element | JSX.Element[] }) => {
  const { isAdmin } = useRoles();
  return <If condition={isAdmin}>{props.children}</If>;
};

export const IfSuperAdmin = (props: { children: JSX.Element | JSX.Element[] }) => {
  const { isSuperAdmin } = useRoles();
  return <If condition={isSuperAdmin}>{props.children}</If>;
};

export const IfManager = (props: { children: JSX.Element | JSX.Element[] }) => {
  const { isManager } = useRoles();
  return <If condition={isManager}>{props.children}</If>;
};

export const IfProgram = (props: { children: JSX.Element | JSX.Element[] }) => {
  const { isProgram } = useRoles();
  return <If condition={isProgram}>{props.children}</If>;
};

export const IfTerritory = (props: { children: JSX.Element | JSX.Element[] }) => {
  const { isTerritory } = useRoles();
  return <If condition={isTerritory}>{props.children}</If>;
};

export const IfPartner = (props: { children: JSX.Element | JSX.Element[] }) => {
  const { isPartner } = useRoles();
  return <If condition={isPartner}>{props.children}</If>;
};

//
// ----- conditional functions  -----
//

export function ifCondition<T, F>(
  condition: boolean,
  trueValue: T,
  falseValue?: F
): T | F | undefined {
  return condition ? trueValue : falseValue;
}

export function ifAdmin<T, F>(trueValue: T, falseValue?: F): T | F | undefined {
  return useRoles.getState().isAdmin ? trueValue : falseValue;
}
