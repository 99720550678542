import React from "react";
import { AppNavigation } from "./components/navigation/Navigation";
import { useRoles } from "./lib/auth/useRoles";
import { adminNavDef, partnerNavDef } from "./backAppStack";

export function BackAppNavigation() {
  const roles = useRoles();
  const navDef = roles.isManager ? adminNavDef : partnerNavDef;

  return <AppNavigation navDef={navDef} />;
}
