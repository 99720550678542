import React, { useCallback } from "react";
import { PropertiesCard } from "../../components/properties/PropertiesCard";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { ContainerDoc, ContainerRepo } from "../../model/ContainerDoc";
import { ProductRepo } from "../../model/ProductDoc";
import { ContainerMovementDoc, ContainerMovementRepo } from "../../model/ContainerMovementDoc";
import { SimpleList } from "../../components/primitives/SimpleList";
import { formatDateTime } from "../../util/format";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { useDocGet, useDocQuery } from "../../lib/firestore/fstore_hooks";
import { Box } from "@chakra-ui/react";
import { InventoryIcon } from "../../components/icons/InventoryIcon";
import { InventoryPlusIcon } from "../../components/icons/InventoryPlusIcon";
import { InventoryMinusIcon } from "../../components/icons/InventoryMinusIcon";
import { BowlEmptyIcon } from "../../components/icons/BowlEmptyIcon";
import { SectionSecondaryButton } from "../../components/layout/SectionSecondaryButton";

const eventLabels: Record<string, string> = {
  deliver: "Deliver",
  remove: "Remove",
  return: "Return",
  count: "Inventory Count",
};

const eventIcons: Record<string, JSX.Element> = {
  deliver: <InventoryPlusIcon boxSize={8} color="brand.text" />,
  remove: <InventoryMinusIcon boxSize={8} color="brand.text" />,
  return: <BowlEmptyIcon boxSize={8} color="brand.text" />,
  count: <InventoryIcon boxSize={8} color="brand.text" />,
};

export const ContainerDetailsScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminContainer">) => {
  const { value: container } = useDocGet(ContainerRepo.doc(route.params.containerId));
  const { value: product } = useDocGet(ProductRepo.doc(container?.productId));
  const { loading: movementsLoading, value: movements } = useDocQuery(
    ContainerMovementRepo.query()
      .where("containerId", "==", route.params.containerId)
      .orderBy("locationAt", "desc")
  );

  function renderDetails(container: ContainerDoc) {
    const kv: Record<string, string | number | boolean> = {};
    kv["Id"] = container.id;
    kv["Product Id"] = container.productId ?? "<null>";
    kv["Product Name"] = product?.name ?? "";
    kv["Name"] = container.name;
    kv["Location"] = container.locationName ?? "-";
    kv["Location At"] = container.locationAt ? formatDateTime(container.locationAt) : "-";
    kv["Return Lock Until"] = container.returnLockUntil
      ? formatDateTime(container.returnLockUntil)
      : "";

    return <PropertiesCard title="Details" properties={kv} />;
  }

  function renderMovements(movements: ContainerMovementDoc[]) {
    if (!movementsLoading) {
      if (movements.length) {
        return (
          <SimpleList
            title="Movements"
            items={movements}
            getItemLabel={(item) => ({
              icon: eventIcons[item.event ?? "other"] ?? <Box w="32px" />,
              above: eventLabels[item.event ?? "other"] ?? "Other",
              title: item.locationName ?? "-",
              right: item.locationAt ? formatDateTime(item.locationAt) : "",
            })}
          />
        );
      } else {
        return <SectionMsg text="No movements recorded." type="muted" />;
      }
    } else {
      return null;
    }
  }

  const onUpdateProduct = useCallback(() => {
    if (container) {
      navigation.navigate("AdminContainerUpdateProduct", {
        containerId: container?.id,
        productId: container?.productId,
        name: container?.name,
      });
    }
  }, [container?.id]);

  return (
    <Screen name="Container Details" showTitle={true}>
      {container ? renderDetails(container) : null}
      <SectionSecondaryButton
        isDisabled={!container}
        label="Update Product"
        onClick={onUpdateProduct}
      />
      {movements ? renderMovements(movements) : null}
    </Screen>
  );
};
