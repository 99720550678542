import React from "react";
import { Text } from "@chakra-ui/react";
import { HFlex } from "../layout/HFlex";
import { ContainerDoc } from "../../model/ContainerDoc";
import { ContainerList } from "./ContainerList";
import { CardSection } from "../layout/Sections";

export const ContainersView: React.FunctionComponent<{
  containers: ContainerDoc[];
  emptyMsg?: string;
  continueMsg?: string;
  showProductInfo?: boolean;
}> = (props) => {
  const items = props.containers;
  return (
    <CardSection title="Scanned Container(s)">
      <HFlex my={2} justifyContent="space-between" w="full">
        <ContainerList
          containers={items}
          showProductInfo={props.showProductInfo}
          emptyMsg={props.emptyMsg}
          continueMsg={props.continueMsg}
        />
        <HFlex
          ml={1}
          borderLeftColor={"gray.300"}
          borderLeftWidth={1}
          justifyContent="center"
          alignItems="center"
          alignSelf="stretch"
          w="25%"
        >
          <Text fontSize="5xl" fontWeight="bold">
            {items.length}
          </Text>
        </HFlex>
      </HFlex>
    </CardSection>
  );
};
