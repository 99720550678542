import { ScreenDefinitions, createNavDef } from "./components/navigation/NavigationDefinitions";
import { AdminCustomerSearchScreen } from "./features/search/AdminCustomerSearchScreen";
import { AdminHomeScreen } from "./features/home/AdminHomeScreen";
import { AdminLocationListScreen } from "./features/location/AdminLocationListScreen";
import { AdminLocationScreen } from "./features/location/AdminLocationScreen";
import { LocationTransactionListScreen } from "./features/ctransactions/LocationCTransactionListScreen";
import { AdminPointDetailsScreen } from "./features/point/AdminPointDetailsScreen";
import { AdminScanScreen } from "./features/home/AdminScanScreen";
import { CTransactionDetailScreen } from "./features/ctransactions/CTransactionDetailScreen";
import { AdminProfileDetailsScreen } from "./features/customer/AdminProfileDetailsScreen";
import { AdminReturnScreen } from "./features/ctransactions/AdminReturnScreen";
import { SelectLocationScreen } from "./features/location/SelectLocationScreen";
import { AdminRefundScreen } from "./features/ctransactions/AdminRefundScreen";
import { AdminLocationChangeSubscription } from "./features/ltransactions/AdminLocationChangeSubscription";
import { AdminLocationInventoryCount } from "./features/ltransactions/AdminLocationInventoryCount";
import { AdminLocationAdjustDifference } from "./features/ltransactions/AdminLocationAdjustDifference";
import { AdminLocationDeliverRemove } from "./features/ltransactions/AdminLocationDeliverRemove";
import { AdminLocationInventoryDetailsScreen } from "./features/location/AdminLocationInventoryDetailsScreen";
import { AdminPointsListScreen } from "./features/point/AdminPointsListScreen";
import { AdminCreateQRCodeScreen } from "./features/point/AdminCreateQRCodeScreen";
import { AdminCustomerCreateScreen } from "./features/customer/AdminCustomerCreateScreen";
import { AdminChangeRole } from "./features/customer/AdminChangeRole";
import { AdminCTransactionsScreen } from "./features/ctransactions/AdminCTransactionsScreen";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { AdminBorrowScreen } from "./features/ctransactions/AdminBorrowScreen";
import { LTransactionDetailScreen } from "./features/ltransactions/LTransactionDetailScreen";
import { AdminLocationDeliverScanScreen } from "./features/ltransactions/AdminLocationDeliverScan";
import { ContainerDetailsScreen } from "./features/container/ContainerDetailsScreen";
import { AdminLocationInventoryCountScan } from "./features/ltransactions/AdminLocationInventoryCountScan";
import { AdminCustomerSendTextScreen } from "./features/customer/AdminCustomerSendTextScreen";
import { ContainerUpdateProductScreen } from "./features/container/ContainerUpdateProductScreen";
import { PartnerHomeScreen } from "./features/partner/PartnerHomeScreen";
import { AdminLocationRemoveScanScreen } from "./features/ltransactions/AdminLocationRemoveScan";
import { AdminLocationDetailsScreen } from "./features/location/AdminLocationDetailScreen";
import { AdminTerminalListScreen } from "./features/location/AdminTerminalListScreen";
import { AdminTerminalDetailsScreen } from "./features/location/AdminTerminalDetailsScreen";
import { ContainerBulkAssignScreen } from "./features/container/ContainerBulkAssignScreen";
import { AdminAccountDetailsScreen } from "./features/account/AdminAccountDetailsScreen";
import { AdminProfilesListScreen } from "./features/account/AdminProfilesListScreen";
import { AdminLocationContainersScreen } from "./features/location/AdminLocationContainerScreen";

export type BackAppScreenParamList = {
  AdminHome: undefined;
  AdminScan: undefined;
  AdminPoint: { pointId: string; returnUpdate?: boolean };
  AdminContainer: { containerId: string };
  AdminContainerUpdateProduct: { containerId: string; productId?: string; name?: string };
  AdminContainerBulkAsssign: undefined;
  AdminLocationList: undefined;
  AdminLocationOverview: { partnerId: string; locationId: string };
  AdminLocationDetails: { locationId: string };
  AdminLocationChangeSubscription: { partnerId: string; locationId: string };
  AdminLocationInventoryCount: { partnerId: string; locationId: string };
  AdminLocationInventoryCountScan: { partnerId: string; locationId: string };
  AdminLocationAdjustDifference: { partnerId: string; locationId: string };
  AdminLocationDeliverRemove: { partnerId: string; locationId: string };
  AdminLocationDeliverScan: { partnerId: string; locationId: string };
  AdminLocationRemoveScan: { partnerId: string; locationId: string };
  AdminLocationInventoryDetails: {
    partnerId: string;
    locationId: string;
  };
  AdminLocationContainers: {
    locationId: string;
    expected: number;
  };
  AdminLocationTransactionList: {
    partnerId: string;
    locationId: string;
    locationName?: string;
  };
  AdminPointList: {
    partnerId: string;
    locationId: string;
    locationName?: string;
  };
  AdminCustomerCreate: undefined;
  AdminChangeRole: {
    profileId: string;
    email: string;
    partnerId?: string;
    locationId?: string;
  };
  AdminCreateQRCode: {
    partnerId: string;
    locationId: string;
  };
  AdminCTransactionDetails: {
    transactionId: string;
  };
  AdminLTransactionDetails: {
    transactionId: string;
  };
  AdminCustomerSearch: undefined;
  AdminProfilesList: {
    phone: string;
  };
  AdminAccountDetails: {
    accountId: string;
  };
  AdminCustomerDetails: {
    profileId: string;
  };
  AdminCustomerSendText: {
    profileId?: string;
    accountId?: string;
  };
  AdminReturn: {
    profileId?: string;
    accountId?: string;
    partnerId?: string;
    locationId?: string;
  };
  AdminBorrow: {
    profileId: string;
    partnerId?: string;
    locationId?: string;
  };
  SelectLocation: undefined;
  AdminRefund: {
    transactionId: string;
    partnerId?: string;
    locationId?: string;
  };
  AdminRecentTransactionList: undefined;
  AdminTerminalList: {
    locationId: string;
  };
  AdminTerminalDetails: {
    terminalId: string;
  };
};

export type BackAppScreenProps<Screen extends keyof BackAppScreenParamList> =
  NativeStackScreenProps<BackAppScreenParamList, Screen>;

export const backAppScreenDefs: ScreenDefinitions<keyof Omit<BackAppScreenParamList, "AdminHome">> =
  {
    AdminScan: {
      component: AdminScanScreen,
      title: "Scan QR Code",
    },
    AdminPoint: {
      component: AdminPointDetailsScreen,
      title: "Point Info",
    },
    AdminContainer: {
      component: ContainerDetailsScreen,
      title: "Container Info",
    },
    AdminContainerUpdateProduct: {
      component: ContainerUpdateProductScreen,
      title: "Update Product",
    },
    AdminContainerBulkAsssign: {
      component: ContainerBulkAssignScreen,
      title: "Assign Container Type",
    },
    AdminLocationList: {
      component: AdminLocationListScreen,
      title: "Partner List",
    },
    AdminLocationOverview: {
      component: AdminLocationScreen,
      title: "Location Overview",
    },
    AdminLocationDetails: {
      component: AdminLocationDetailsScreen,
      title: "Location Details",
    },
    AdminLocationChangeSubscription: {
      component: AdminLocationChangeSubscription,
      title: "Change Subscription",
    },
    AdminLocationInventoryCount: {
      component: AdminLocationInventoryCount,
      title: "Inventory Count",
    },
    AdminLocationInventoryCountScan: {
      component: AdminLocationInventoryCountScan,
      title: "Inventory Count /w Scan",
    },
    AdminLocationAdjustDifference: {
      component: AdminLocationAdjustDifference,
      title: "Adjust Difference",
    },
    AdminLocationDeliverRemove: {
      component: AdminLocationDeliverRemove,
      title: "Deliver/Remove",
    },
    AdminLocationDeliverScan: {
      component: AdminLocationDeliverScanScreen,
      title: "Deliver /w Scan",
    },
    AdminLocationRemoveScan: {
      component: AdminLocationRemoveScanScreen,
      title: "Remove /w Scan",
    },
    AdminLocationInventoryDetails: {
      component: AdminLocationInventoryDetailsScreen,
      title: "Location Inventory Details",
    },
    AdminLocationContainers: {
      component: AdminLocationContainersScreen,
      title: "Location Container Details",
    },
    AdminLocationTransactionList: {
      component: LocationTransactionListScreen,
      title: "Location Transactions",
    },
    AdminPointList: {
      component: AdminPointsListScreen,
      title: "Location Points",
    },
    AdminCustomerCreate: {
      component: AdminCustomerCreateScreen,
      title: "Create Customer",
    },
    AdminChangeRole: {
      component: AdminChangeRole,
      title: "Change Role",
    },
    AdminCreateQRCode: {
      component: AdminCreateQRCodeScreen,
      title: "Create QR Code",
    },
    AdminCTransactionDetails: {
      component: CTransactionDetailScreen,
      title: "Transactions Details",
    },
    AdminLTransactionDetails: {
      component: LTransactionDetailScreen,
      title: "Transactions Details",
    },
    AdminCustomerSearch: {
      component: AdminCustomerSearchScreen,
      title: "Customer Search",
    },
    AdminProfilesList: {
      component: AdminProfilesListScreen,
      title: "Profiles List",
    },
    AdminAccountDetails: {
      component: AdminAccountDetailsScreen,
      title: "Account Details",
    },

    AdminCustomerDetails: {
      component: AdminProfileDetailsScreen,
      title: "Customer Details",
    },
    AdminCustomerSendText: {
      component: AdminCustomerSendTextScreen,
      title: "Send Text Message",
    },
    AdminReturn: {
      component: AdminReturnScreen,
      title: "Return On Behalf",
    },
    AdminBorrow: {
      component: AdminBorrowScreen,
      title: "Borrow On Behalf",
    },
    SelectLocation: {
      component: SelectLocationScreen,
      title: "Select Location",
    },
    AdminRefund: {
      component: AdminRefundScreen,
      title: "Refund & Return",
    },
    AdminRecentTransactionList: {
      component: AdminCTransactionsScreen,
      title: "Recent Transactions",
    },
    AdminTerminalList: {
      component: AdminTerminalListScreen,
      title: "Terminal List",
    },
    AdminTerminalDetails: {
      component: AdminTerminalDetailsScreen,
      title: "Terminal Details",
    },
  };

export const adminNavDef = createNavDef({
  screens: {
    AdminHome: {
      component: AdminHomeScreen,
      path: "a/admin",
    },
    ...backAppScreenDefs,
  },
});

export const partnerNavDef = createNavDef({
  screens: {
    AdminHome: {
      component: PartnerHomeScreen,
      path: "a/partner",
    },
    ...backAppScreenDefs,
  },
});
