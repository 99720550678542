import React, { useState } from "react";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { LocationInfoCard } from "../../components/views/LocationInfoCard";
import { CompactCounterInput } from "../../components/composite/CompactCounterInput";
import { VFlex } from "../../components/layout/VFlex";
import { useAsync } from "../../hooks/reactUse";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import { useBackRpc } from "../../lib/functions/rpc";
import { docGet } from "../../lib/firestore/fstore";
import { CardSection } from "../../components/layout/Sections";
import { Box, Divider, Text } from "@chakra-ui/react";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminLocationChangeSubscription = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationChangeSubscription">) => {
  const closeScreen = useCloseScreen();
  const [newSubscription, setNewSubscription] = useState<number>(0);
  const [subscriptionState, submitSubscription] = useBackRpc("locationChangeSubscription");

  const { loading, value: currentSubscription } = useAsync(async () => {
    const ilevel = await docGet(
      VInventoryLevelRepo.doc(VInventoryLevelRepo.doc(route.params.locationId))
    );
    setNewSubscription(ilevel?.subscriptionSum ?? 0);
    return ilevel?.subscriptionSum ?? 0;
  }, [route.params.locationId]);

  async function handleSubmit() {
    await submitSubscription({
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
      deltaQty: newSubscription - (currentSubscription ?? 0),
    });
    closeScreen();
  }

  return (
    <Screen name="Change Subscription" showTitle={true}>
      <LocationInfoCard partnerId={route.params.partnerId} locationId={route.params.locationId} />
      <CardSection title="Subscription" p={2}>
        <VFlex w="full">
          <Text textAlign="center" fontSize="sm">
            Current Subscription
          </Text>
          <Text textAlign="center" fontSize="xl" fontWeight={700}>
            {loading ? "..." : currentSubscription ?? 0}
          </Text>
        </VFlex>
        <Divider my={2} orientation="horizontal" borderColor="brand.text" />
        <Box w="full">
          <CompactCounterInput
            minValue={0}
            value={newSubscription}
            maxValue={1000}
            onNumChange={setNewSubscription}
            preText="Change to"
            postText="containers"
          />
        </Box>
      </CardSection>
      <SectionPrimaryButton
        label="Submit"
        isDisabled={newSubscription === currentSubscription}
        onClick={handleSubmit}
      />
    </Screen>
  );
};
