import React from "react";
import { TwoButtonGroup } from "../primitives/TwoButtonGroup";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react";

export const AlertYesNo: React.FunctionComponent<{
  title: string;
  msg: string | (() => JSX.Element);
  primary?: string;
  secondary?: string;
  isOpen: boolean;
  onClose?: (yesno?: boolean) => void;
}> = (props) => {
  const yesLabel = props.primary ?? "Yes";
  const noLabel = props.secondary ?? "No";

  const leastDestructiveRef = React.useRef(null);
  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={props.isOpen}
      onClose={() => props.onClose?.()}
      size="xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx={4} bg="brand.bg">
          <AlertDialogCloseButton />
          <AlertDialogHeader textAlign="center" fontWeight="700" fontSize="md">
            {props.title}
          </AlertDialogHeader>
          <AlertDialogBody
            px={6}
            py={4}
            borderTopWidth={1}
            borderBottomWidth={1}
            borderColor={"gray.300"}
          >
            {typeof props.msg === "function" ? props.msg() : props.msg}
          </AlertDialogBody>
          <AlertDialogFooter justifyContent="center">
            <TwoButtonGroup
              _left={{
                label: noLabel,
                onClick: () => props.onClose?.(false),
              }}
              _right={{
                label: yesLabel,
                onClick: () => props.onClose?.(true),
              }}
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
