import React from "react";
import _omit from "lodash/omit";
import { BoxProps, Image, StackDivider, VStack } from "@chakra-ui/react";
import { VFlex } from "./VFlex";
import { SectionTitle } from "./SectionTitle";
import { HFlex } from "./HFlex";

export const SectionBox = (
  props: {
    title?: string;
    filterLabel?: string;
    onFilterChange?: (value: boolean) => void;
    json?: {
      name?: string;
      data: any;
    };
  } & BoxProps
) => {
  const extraVProps = _omit(props, ["title", "json", "filterLabel", "onFilterChange"]);
  return (
    <>
      {props.title ? <SectionTitle title={props.title} json={props.json} /> : null}
      <VFlex position="relative" px={4} mt={props.title ? 0 : 0.5} mb={1} w="full" {...extraVProps}>
        {props.children}
      </VFlex>
    </>
  );
};

export const CardSectionContent = (props: BoxProps) => {
  return (
    <VFlex position="relative" px={3} my={1} w="full" {...props}>
      {props.children}
    </VFlex>
  );
};

export const CardSectionItem = (props: BoxProps) => {
  return (
    <HFlex position="relative" px={3} py={2} w="full" {...props}>
      {props.children}
    </HFlex>
  );
};

export const CardSection = (
  props: {
    title?: string;
    filterLabel?: string;
    onFilterChange?: (value: boolean) => void;
    json?: {
      name?: string;
      data: any;
    };
    bgImageSource?: string;
    withDivider?: boolean;
  } & BoxProps
) => {
  const extraVProps = _omit(props, [
    "title",
    "json",
    "filterLabel",
    "onFilterChange",
    "bgImageSource",
    "withDivider",
  ]);
  return (
    <SectionBox title={props.title} json={props.json}>
      {/* don't replace VStack with VFlex. there is an optional divider */}
      <VStack
        position="relative"
        bg="white"
        borderRadius="md"
        borderWidth={0}
        borderColor="gray.100"
        w="full"
        overflow="hidden"
        spacing={0}
        alignItems="start"
        divider={
          props.withDivider ? <StackDivider borderColor="brand.divider" w="full" /> : undefined
        }
        {...extraVProps}
      >
        {props.bgImageSource ? (
          <Image
            position="absolute"
            top={0}
            left={0}
            src={props.bgImageSource}
            alt="Background"
            w={"100%"}
            h={"100%"}
            zIndex={-1}
          />
        ) : null}
        {props.children}
      </VStack>
    </SectionBox>
  );
};
