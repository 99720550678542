import invariant from "invariant";
import { Screen } from "../../components/layout/Screen";
import { SimpleList } from "../../components/primitives/SimpleList";
import { useNavigationState } from "@react-navigation/core";
import { BackAppScreenProps } from "../../backAppStack";
import { LocationDoc, LocationRepo } from "../../model/LocationDoc";
import { useDocQuery } from "../../lib/firestore/fstore_hooks";

export const SelectLocationScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"SelectLocation">) => {
  const previousRouteName = useNavigationState((state) => state.routes[state.index - 1].name);
  let locationListState = useDocQuery(LocationRepo.query().orderBy("name", "asc"));

  return (
    <Screen name="Select Location" showTitle={true}>
      <SimpleList
        items={locationListState.value}
        getItemLabel={(location: LocationDoc) => {
          return `${location.name}${location.test ? " [Test]" : ""}`;
        }}
        onSelect={(location) => {
          invariant(location.partnerId, "Subcollection location should have a parent id");

          //@ts-ignore
          navigation.navigate({
            name: previousRouteName,
            params: {
              partnerId: location.partnerId,
              locationId: location.id,
            },
            merge: true,
          });
        }}
      />
    </Screen>
  );
};
