import _omit from "lodash/omit";
import React from "react";
import tinycolor from "tinycolor2";
import { Button, ButtonProps, Flex, Icon, Text } from "@chakra-ui/react";

export const ProviderButton = (
  props: {
    providerColor: string;
    label: string;
    isLoading: boolean;
    icon: React.ElementType;
  } & ButtonProps
) => {
  const extraProps = _omit(props, ["colorScheme", "variant", "providerColor", "label", "icon"]);
  const tcolor = tinycolor(props.providerColor);
  const bright = tcolor.getBrightness() > 180;
  const textColor = bright ? "black" : "white";
  const pressedColor = (bright ? tcolor.darken(15) : tcolor.brighten(10)).toHexString();
  const mutedColor = tcolor.desaturate(30).toHexString();

  return (
    <Button
      variant="unstyled"
      display={"flex"}
      flexDirection={"row"}
      alignContent={"center"}
      alignItems={"center"}
      p={2}
      bg={props.providerColor}
      color={textColor}
      fontWeight={600}
      borderColor={pressedColor}
      borderWidth={bright ? 1 : 0}
      leftIcon={<props.icon boxSize={6} />}
      _hover={{ bg: props.providerColor }}
      _active={{ bg: pressedColor }}
      _disabled={{ bg: mutedColor }}
      {...extraProps}
    >
      {"Sign In with " + props.label}
    </Button>
  );
};
