import "firebase/compat/auth";
import { create } from "zustand";
import { useAuthentication } from "./useAuthentication";
import { UPrivilegesRepo, UPrivilegesDoc } from "../../model/UPrivilegesDoc";
import { docGet } from "../firestore/fstore";

export type RoleState =
  | {
      isPending: true;
      isPrivileged: false;
      isManager: false;
      isAdmin: false;
      isSuperAdmin: false;
      isPartner: false;
      isProgram: false;
      isTerritory: false;
      isBeta: false;
      partnerId: undefined;
      eroot: "";
    }
  | {
      isPending: false;
      isPrivileged: boolean; // <-- admin, program, territory, partner
      isManager: boolean; // <-- admin, program, territory
      isAdmin: boolean;
      isSuperAdmin: boolean; // <-- @recirclable.com
      isPartner: boolean;
      isProgram: boolean;
      isTerritory: boolean;
      isBeta: boolean;
      partnerId?: string;
      eroot: string;
    };

const ROLES_PENDING: RoleState = {
  isPending: true,
  isPrivileged: false,
  isManager: false,
  isAdmin: false,
  isSuperAdmin: false,
  isProgram: false,
  isTerritory: false,
  isPartner: false,
  isBeta: false,
  partnerId: undefined,
  eroot: "",
};

const ROLES_NO_PRIVILEGES: RoleState = {
  isPending: false,
  isPrivileged: false,
  isManager: false,
  isAdmin: false,
  isSuperAdmin: false,
  isPartner: false,
  isProgram: false,
  isTerritory: false,
  isBeta: false,
  partnerId: undefined,
  eroot: "",
};

function rolesFromPrivileges(
  privileges: UPrivilegesDoc | null | undefined,
  email: string | null | undefined
): RoleState {
  if (privileges) {
    return {
      isPending: false,
      isPrivileged: !!privileges?.role?.length,
      isManager: ["admin", "program", "territory"].includes(privileges?.role ?? ""),
      isAdmin: privileges?.role === "admin",
      isSuperAdmin: privileges?.role === "admin" && !!email?.includes("@recirclable.com"),
      isPartner: privileges?.role === "partner",
      isProgram: privileges?.role === "program",
      isTerritory: privileges?.role === "territory",
      isBeta: !!privileges?.beta,
      partnerId: privileges?.role === "partner" ? privileges.eroot : undefined,
      eroot: privileges?.eroot ?? "",
    };
  } else {
    return { ...ROLES_NO_PRIVILEGES };
  }
}

export const useRoles = create<RoleState>((set, get) => {
  const store: RoleState = { ...ROLES_PENDING };
  let userId: string | null | undefined = null;

  function loadPrivileges() {
    const currentAuthState = useAuthentication.getState();
    if (!currentAuthState.isPending) {
      if (currentAuthState.isAuthenticated) {
        // NOTE: having the "privileged" flag as a custom claims avoids reading from the database
        //       for every customer.
        if (currentAuthState.claims?.privileged) {
          userId = currentAuthState.user.uid;
          docGet(UPrivilegesRepo.doc(userId)).then((privileges) => {
            set(rolesFromPrivileges(privileges, currentAuthState.user.email));
          });
        } else {
          set({ ...ROLES_NO_PRIVILEGES });
        }
      } else {
        set({ ...ROLES_NO_PRIVILEGES });
      }
    }
  }

  // immediately load the current privileges
  loadPrivileges();

  // re-load roles whenever the auth state changes
  useAuthentication.subscribe((authState) => {
    set({ ...ROLES_PENDING });
    loadPrivileges();
  });

  return store;
});
