// icon:chevron-down-circle-outline | Ionicons https://ionicons.com/ | Ionic Framework
import * as React from "react";
import { createIcon } from "@chakra-ui/react";

export const ChevronDownCircleIcon = createIcon({
  viewBox: "0 0 512 512",
  path: [
    <path
      fill="none"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={32}
      d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z"
    />,
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M352 216l-96 96-96-96"
    />,
  ],
});
