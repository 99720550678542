import { useCallback, useRef, useState } from "react";
import { ONE_SEC } from "../../util/constants";
import { useTimeoutFn } from "../../hooks/reactUse";

export function useAutoClearRef<T>(
  initial: T,
  timeout: number = 3 * ONE_SEC
): [React.MutableRefObject<T | null>, (value: T) => void] {
  const ref = useRef<T | null>(initial);

  const [_, __, resetTimer] = useTimeoutFn(() => {
    ref.current = null;
  }, timeout);

  const set = useCallback(
    (value: T) => {
      ref.current = value;
      resetTimer();
    },
    [ref, resetTimer]
  );

  return [ref, set];
}
