import firebase from "firebase/compat/app";
import { createDocBaseClass } from "../lib/firestore/fstore";
import { Repo } from "../lib/firestore/fstore";
import { LInvoiceData } from "../lib/apiDefs";

export class LInvoiceDoc extends createDocBaseClass<LInvoiceData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const LInvoiceRepo = new (class LInvoiceRepo extends Repo<LInvoiceDoc> {
  constructor() {
    super("l_invoices", LInvoiceDoc);
  }
})();
