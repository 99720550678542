import React, { useState } from "react";
import { Box, Checkbox, Divider, Text } from "@chakra-ui/react";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { LocationInfoCard } from "../../components/views/LocationInfoCard";
import { CompactCounterInput } from "../../components/composite/CompactCounterInput";
import { HFlex } from "../../components/layout/HFlex";
import { VFlex } from "../../components/layout/VFlex";
import { useAsync } from "../../hooks/reactUse";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import { useBackRpc } from "../../lib/functions/rpc";
import { docGet } from "../../lib/firestore/fstore";
import { CardSection } from "../../components/layout/Sections";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminLocationAdjustDifference = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationAdjustDifference">) => {
  const closeScreen = useCloseScreen();
  const [newDifference, setNewDifference] = useState<number>(0);
  const [payment, setPayment] = useState(false);
  const [adjustDiffState, submitAdjustDiff] = useBackRpc("locationAdjustDiff");

  const { loading, value: currentDifference } = useAsync(async () => {
    const ilevel = await docGet(
      VInventoryLevelRepo.doc(VInventoryLevelRepo.doc(route.params.locationId))
    );
    setNewDifference(ilevel?.differenceSum ?? 0);
    return ilevel?.differenceSum ?? 0;
  }, [route.params.locationId]);

  async function handleCountChange(value: number) {
    setNewDifference(value);
    if (value <= 0) setPayment(false);
  }

  async function handleSubmit() {
    await submitAdjustDiff({
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
      payment,
      qty: newDifference - (currentDifference ?? 0),
    });
    closeScreen();
  }

  const safeCurrentDifference = currentDifference ?? 0;

  return (
    <Screen name="Clear Difference" showTitle={true}>
      <LocationInfoCard partnerId={route.params.partnerId} locationId={route.params.locationId} />
      <CardSection title="Difference" p={2}>
        <VFlex w="full">
          <Text textAlign="center" fontSize="sm">
            Current Difference
          </Text>
          <Text textAlign="center" fontSize="xl" fontWeight={700}>
            {loading ? "..." : currentDifference ?? 0}
          </Text>
        </VFlex>
        <Divider my={2} orientation="horizontal" borderColor="brand.text" />
        <Box w="full">
          <CompactCounterInput
            minValue={safeCurrentDifference < 0 ? safeCurrentDifference : 0}
            value={newDifference}
            maxValue={safeCurrentDifference > 0 ? safeCurrentDifference : 0}
            onNumChange={handleCountChange}
            preText="Set to"
            postText="difference"
          />
        </Box>
        <HFlex w="100%" justifyContent="center">
          <Checkbox
            value="payment"
            isChecked={payment}
            isDisabled={newDifference <= (currentDifference ?? 0)}
            colorScheme="teal"
            borderColor="brand.text"
            onChange={(e) => setPayment(e.target.checked)}
            _checked={{ bg: "teal.800" }}
          >
            Difference was paid
          </Checkbox>
        </HFlex>
      </CardSection>
      <SectionPrimaryButton
        label="Submit"
        isDisabled={newDifference === currentDifference}
        onClick={handleSubmit}
      />
    </Screen>
  );
};
