import firebase from "firebase/compat/app";
import { firebaseFirestore } from "../firebase/fbenv";
import { AnyDocRefType, docRef } from "./fstore_docref";
import { FUNC_NOOP } from "../../util/constants";
import invariant from "invariant";
import _clone from "lodash/clone";
import _cloneDeepWith from "lodash/cloneDeepWith";
import _omit from "lodash/omit";
import { DocData } from "../apiDefs";

//
// -----  base class for all documents on the client side
//

export class TypedDoc<T extends DocData = DocData> implements DocData {
  private readonly _snapshot: firebase.firestore.DocumentSnapshot;

  // standard props of the base document
  readonly id: string;
  readonly _?: DocData["_"];
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly deleteAt?: Date;

  // the properties of the specific documents
  readonly [key: string]: any;

  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    invariant(snapshot.exists, "snapshot must exist for creating a document");

    this._snapshot = snapshot;
    this.id = snapshot.id;

    // transform the date field
    const data = _cloneDeepWith(this._snapshot.data(), (value: any) => {
      if (value instanceof firebase.firestore.Timestamp) {
        return value.toDate();
      }
      return undefined; // signals to use default behavior to cloneDeep()
    });

    // copy data as properties
    Object.assign(this, data);
  }

  fireRef(): firebase.firestore.DocumentReference<this> {
    return this._snapshot.ref as firebase.firestore.DocumentReference<this>;
  }

  asJSON(): any {
    return this.asData();
  }

  asData(): T {
    let { _snapshot, ...data } = this as unknown as any; // <- only copies props, methods are on prototype
    return data;
  }
}

type DocBaseClassConstructor<T extends DocData> = new (
  snapshot: firebase.firestore.DocumentSnapshot
) => T & TypedDoc<T>;

export function createDocBaseClass<T extends DocData>(): DocBaseClassConstructor<T> {
  return TypedDoc as DocBaseClassConstructor<T>;
}

//
// ----- Firestore converter -----
//

// type signature for the constructor of a document
export type TypeDocCons<D extends TypedDoc> = new (
  snapshot: firebase.firestore.DocumentSnapshot
) => D;

/*
 * Firestore doc converter.
 */
class DocConverter<D extends TypedDoc> implements firebase.firestore.FirestoreDataConverter<D> {
  private docCons: TypeDocCons<D>;

  constructor(docCons: TypeDocCons<D>) {
    this.docCons = docCons;
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): D {
    return new this.docCons(snapshot);
  }

  toFirestore(doc: D): firebase.firestore.DocumentData;
  toFirestore(
    partial: Partial<D>,
    options: firebase.firestore.SetOptions
  ): firebase.firestore.DocumentData;
  toFirestore(
    data: Partial<D>,
    options?: firebase.firestore.SetOptions
  ): firebase.firestore.DocumentData {
    const copy = _clone(data);
    return _omit(copy, ["id", "_snapshot"]);
  }
}

//
// -----  repository for documents -----
//

/*
 * Repository for douments, which is just a thin wrapper around the Firestore compat API.
 */
export class Repo<D extends TypedDoc> {
  protected collectionPath: string;
  protected docCons: TypeDocCons<D>;

  constructor(collectionPath: string, docCons: TypeDocCons<D>) {
    this.collectionPath = collectionPath;
    this.docCons = docCons;
  }

  doc(id: string): firebase.firestore.DocumentReference<D>;
  doc(id: string | null | undefined): firebase.firestore.DocumentReference<D> | null;
  doc(ref: firebase.firestore.DocumentReference<D>): firebase.firestore.DocumentReference<D>;
  doc(
    ref: firebase.firestore.DocumentReference<D> | null | undefined
  ): firebase.firestore.DocumentReference<D> | null;
  doc(doc: D): firebase.firestore.DocumentReference<D>;
  doc(doc: D | null | undefined): firebase.firestore.DocumentReference<D> | null;
  doc(
    refOrId: AnyDocRefType<D> | null | undefined
  ): firebase.firestore.DocumentReference<D> | null {
    if (!refOrId) return null;
    return docRef(refOrId, this.collectionRef());
  }

  resolveDoc(docOrId: D | string): Promise<D | null> {
    if (typeof docOrId === "string") {
      return docGet<D>(this.doc(docOrId));
    } else {
      return Promise.resolve(docOrId);
    }
  }

  protected collectionRef() {
    return firebaseFirestore()
      .collection(this.collectionPath)
      .withConverter<D>(new DocConverter<D>(this.docCons));
  }

  query() {
    return this.collectionRef();
  }

  queryEntity(parentEntityId: string) {
    return this.query().where("epath", "array-contains", parentEntityId);
  }

  queryPartialField(fieldName: string, partial: string) {
    const nextChar = partial.charCodeAt(partial.length - 1) + 1;
    const nextSearchTerm = partial.slice(0, partial.length - 1) + String.fromCharCode(nextChar);
    let fieldPath: string | firebase.firestore.FieldPath;
    if (fieldName === "documentId" || fieldName === "id") {
      fieldPath = firebase.firestore.FieldPath.documentId();
    } else {
      fieldPath = fieldName;
    }
    return this.query().where(fieldPath, ">=", partial).where(fieldPath, "<", nextSearchTerm);
  }
}

/*
 * Repository for sub-douments, which is just a thin wrapper around the Firestore compat API.
 */
export class SubRepo<D extends TypedDoc> extends Repo<D> {
  private docName: string;

  constructor(parentPath: string, docName: string, docCons: TypeDocCons<D>) {
    super(parentPath, docCons);
    this.docName = docName;
  }

  doc(id: string): firebase.firestore.DocumentReference<D>;
  doc(id: string | null | undefined): firebase.firestore.DocumentReference<D> | null;
  doc(ref: firebase.firestore.DocumentReference<D>): firebase.firestore.DocumentReference<D>;
  doc(
    ref: firebase.firestore.DocumentReference<D> | null | undefined
  ): firebase.firestore.DocumentReference<D> | null;
  doc(doc: D): firebase.firestore.DocumentReference<D>;
  doc(doc: D | null | undefined): firebase.firestore.DocumentReference<D> | null;
  doc(
    refOrId: AnyDocRefType<D> | null | undefined
  ): firebase.firestore.DocumentReference<D> | null {
    // @ts-ignore
    const parentRef = super.doc(refOrId);
    if (!parentRef) return null;
    return parentRef
      .collection(this.docName)
      .doc("doc")
      .withConverter<D>(new DocConverter<D>(this.docCons));
  }

  query(): firebase.firestore.CollectionReference<D> {
    throw new Error("query() not supported for sub-repo");
  }

  queryEntity(parentEntityId: string): firebase.firestore.Query<D> {
    throw new Error("queryEntity() not supported for sub-repo");
  }

  queryPartialField(fieldName: string, partial: string): firebase.firestore.Query<D> {
    throw new Error("queryPartialField() not supported for sub-repo");
  }
}

//
// ---- accessor for a single document -----
//

export async function docGet<D extends TypedDoc>(
  docRef: firebase.firestore.DocumentReference<D> | null | undefined
): Promise<D | null> {
  if (!docRef) return null;

  let snapshot = await docRef.get();
  return snapshot.data() ?? null;
}

export async function mustDocGet<D extends TypedDoc>(
  docRef: firebase.firestore.DocumentReference<D>
): Promise<D> {
  let snapshot = await docRef.get();
  invariant(snapshot.exists, `document must exist at '${docRef.path}'`);
  return snapshot.data()!;
}

export async function docUpdate<D extends TypedDoc>(
  docRef: D | firebase.firestore.DocumentReference<D>,
  data: Partial<D>
): Promise<void> {
  if ("fireRef" in docRef) docRef = docRef.fireRef();
  await docRef.update(data);
}

export function docWatch<D extends TypedDoc>(
  docRef: firebase.firestore.DocumentReference<D> | null | undefined,
  callback: (doc: D | null) => void,
  onError?: (error: firebase.firestore.FirestoreError) => void
): () => void {
  if (!docRef) {
    callback(null);
    return FUNC_NOOP;
  }

  onError ??= (error) => {
    console.error("docWatch()", error);
    callback(null);
  };

  return docRef.onSnapshot((snapshot) => {
    callback(snapshot.data() ?? null);
  }, onError);
}

// wait for a document to be created in the backend. typically happens with a small
// delay in a trigger. the method return null if the document is not created in the
// give timeout. it DOES NOT throw an exception.
export function docWaitUntilExists<D extends TypedDoc>(
  docRef: firebase.firestore.DocumentReference<D>,
  ms: number = 10000
): Promise<D | null> {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      unsubscribe();
      console.error(`docWaitUntilExists() at '${docRef.path}' timed out`);
      resolve(null);
    }, ms);
    const unsubscribe = docRef.onSnapshot(
      (snapshot) => {
        if (snapshot.exists) {
          clearTimeout(timer);
          unsubscribe();
          resolve(snapshot.data() ?? null);
        }
      },
      (error) => {
        console.error("docWaitUntilExists(): ", error);
        resolve(null);
      }
    );
  });
}

//
// ---- accessor for a query -----
//

export async function docQuery<D extends TypedDoc>(
  query: firebase.firestore.Query<D>
): Promise<D[]> {
  let querySnapshot = await query.get();
  return querySnapshot.docs.map((doc) => doc.data());
}

export async function docQueryFirst<D extends TypedDoc>(
  query: firebase.firestore.Query<D>
): Promise<D | null> {
  query = query.limit(1);
  const snapshots = await query.get();
  return snapshots.docs.length > 0 ? snapshots.docs[0].data() : null;
}

export function docQueryWatch<D extends TypedDoc>(
  query: firebase.firestore.Query<D>,
  callback: (docs: D[]) => void,
  onError?: (error: firebase.firestore.FirestoreError) => void
): () => void {
  onError ??= (error) => {
    console.error("watchQuery()", error);
    callback([]);
  };

  return query.onSnapshot((querySnapshot) => {
    callback(querySnapshot.docs.map((doc) => doc.data()));
  }, onError);
}
