export const safe = {
  get(value: number | null | undefined) {
    return value ?? 0;
  },

  add(...values: (number | null | undefined)[]) {
    return values.reduce<number>((acc, value) => {
      return acc + (value ?? 0);
    }, 0);
  },

  sub(first: number | null | undefined, ...values: (number | null | undefined)[]) {
    return values.reduce<number>((acc, value) => {
      return acc - (value ?? 0);
    }, first ?? 0);
  },

  neg(value: number | null | undefined) {
    value = value ?? 0; // expo/rect tool change can't handle ??=
    if (value !== 0) value = -value; // check for 0, to avoid -0 result
    return value;
  },

  parseInt(s: string | null | undefined, defaultValue: number = 0): number {
    if (s) {
      return parseInt(s) || defaultValue; // '... || 0' for catching NaN
    }
    return defaultValue;
  },
};
