import React, { useCallback, useEffect, useState } from "react";
import { useRoles } from "../../lib/auth/useRoles";
import { ForwardButtonItem } from "../../components/primitives/ForwardButtonItem";
import { SquareConnectButton } from "./SquareConnectButton";
import { PartnerLocationSelector } from "./PartnerLocationSelector";
import InventoryLevelGauge from "../../components/report/InventoryLevelGauge";
import { Screen } from "../../components/layout/Screen";
import { VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import { LocationDoc } from "../../model/LocationDoc";
import { BackAppScreenProps } from "../../backAppStack";
import { VersionSection } from "../../components/views/VersionSection";
import { authSignOut } from "../../lib/auth/useAuthentication";
import { ifNotEmbeddedApp } from "../../lib/auth/embeddedApp";
import { useDocGet, useDocWatch } from "../../lib/firestore/fstore_hooks";
import { PartnerRepo } from "../../model/PartnerDoc";
import { CardSection } from "../../components/layout/Sections";
import useAsync from "react-use/lib/useAsync";
import { createAdminBackendClient } from "../../lib/trpc/adminBackend";
import AveragesReportView from "../../components/report/AveragesReportView";
import ActivityReportContainer from "../../components/report/ActivityReportContainer";

export const PartnerHomeScreen = ({ navigation, route }: BackAppScreenProps<"AdminHome">) => {
  const roleState = useRoles();
  const { value: partner } = useDocGet(PartnerRepo.doc(roleState.partnerId!));
  const [location, setLocation] = useState<LocationDoc | null>(null);

  function handleTransactionList() {
    navigation.push("AdminLocationTransactionList", {
      partnerId: roleState.partnerId!,
      locationId: location!.id,
      locationName: location!.name,
    });
  }

  function handleInventoryDetails() {
    if (partner) {
      navigation.push("AdminLocationInventoryDetails", {
        partnerId: roleState.partnerId!,
        locationId: location!.id,
      });
    }
  }

  const reportEntityState = useAsync(async () => {
    if (location?.id) {
      const client = createAdminBackendClient();
      return client.reportEntity.mutate({ entityId: location?.id });
    }
  }, [location?.id]);

  const inventoryState = useDocWatch(VInventoryLevelRepo.doc(location ? location.id : null));

  function handleSelectLocation(selectedLocation: LocationDoc) {
    setLocation(selectedLocation);
  }

  const handleSignOut = useCallback(() => {
    authSignOut();
  }, []);

  return (
    <Screen name="Partner Home">
      <PartnerLocationSelector
        partnerId={roleState.partnerId!}
        onSelectLocation={handleSelectLocation}
        menu={{
          ...ifNotEmbeddedApp({ "Sign Out": handleSignOut }),
        }}
      />

      {partner && location ? (
        <>
          <InventoryLevelGauge isLoading={inventoryState.loading} data={inventoryState.doc} />
          <CardSection>
            <ForwardButtonItem
              label="Inventory details"
              onPress={handleInventoryDetails}
              fontSize="sm"
            />
          </CardSection>

          <AveragesReportView
            isLoading={reportEntityState.loading}
            avgBorrowCnt={reportEntityState.value?.avgBorrowCnt ?? 0}
            avgBorrowDays={reportEntityState.value?.avgBorrowDays ?? 0}
          />
          <ActivityReportContainer
            entityId={location.id}
            inclIncomplete={!!partner?.pos || !location.onlineOrder}
          />

          <CardSection>
            <ForwardButtonItem
              label="Recent Transactions"
              onPress={handleTransactionList}
              fontSize="sm"
            />
          </CardSection>
          {partner?.pos === "square" ? <SquareConnectButton partner={partner} /> : null}
          <VersionSection />
        </>
      ) : null}
    </Screen>
  );
};
