import React, { useCallback } from "react";
import { SimpleList } from "../../components/primitives/SimpleList";
import { appUniversalDomain } from "../../util/config";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { PointDoc, PointType, PointRepo } from "../../model/PointDoc";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { IfAdmin } from "../../components/conditional/IfAccess";
import { useDocQueryWatch } from "../../lib/firestore/fstore_hooks";
import { CardSection, CardSectionItem } from "../../components/layout/Sections";
import { Text } from "@chakra-ui/react";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

const pointLabels: Record<PointType, string> = {
  pos: "POS Checkout",
  self_borrow_w_confirm: "Borrow",
  self_return: "Return",
  self_return_w_scan: "Return with scan",
};

export const AdminPointsListScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminPointList">) => {
  const { loading, docs: points } = useDocQueryWatch(
    PointRepo.query().where("locationId", "==", route.params.locationId)
  );

  function qrURL(point: PointDoc) {
    return `https://${appUniversalDomain()}/p/${point.id}`;
  }

  const onSelect = useCallback((point: PointDoc) => {
    const isReturn = point.type === "self_return" || point.type === "self_return_w_scan";
    const hasReturn = !!points.find((p) => p.type === "self_return");
    const hasReturnScan = !!points.find((p) => p.type === "self_return_w_scan");
    const returnUpdate =
      isReturn && ((hasReturn && !hasReturnScan) || (!hasReturn && hasReturnScan));
    navigation.navigate("AdminPoint", { pointId: point.id, returnUpdate });
  }, []);

  function handleCreate() {
    navigation.navigate("AdminCreateQRCode", {
      partnerId: route.params.partnerId,
      locationId: route.params.locationId,
    });
  }

  function renderList(points: PointDoc[] | undefined | null) {
    if (points && points.length > 0) {
      return (
        <SimpleList
          items={points}
          getItemLabel={(point) => {
            return { above: pointLabels[point.type], title: qrURL(point) };
          }}
          onSelect={onSelect}
        />
      );
    } else {
      return (
        <CardSection>
          <CardSectionItem>
            <Text textAlign="center" color="brand.secondaryText">
              No points setup.
            </Text>
          </CardSectionItem>
        </CardSection>
      );
    }
  }

  return (
    <Screen
      name={route.params.locationName ?? "Location"}
      secondaryTitle="QR Codes"
      showTitle={true}
    >
      {!loading ? (
        <>
          {renderList(points)}
          <IfAdmin>
            <SectionPrimaryButton label="Create QR Code" onClick={handleCreate} />
          </IfAdmin>
        </>
      ) : (
        <SectionMsg text="Loading..." type="muted" textSize="md" />
      )}
    </Screen>
  );
};
