// This function is a duplicate of normalizeName.ts in server
// Except for, the server function might receive undefined and return undefined
export function searchableName(name: string) {
  //to lower case
  const lowerCase = name.toLowerCase();

  //handle Diacritic
  const nameNFC = lowerCase.normalize("NFD").replace(/\p{Diacritic}/gu, "");

  //handle Latin letter ø
  const nameNoLatin = nameNFC.replace(/ø/g, "o");

  //remove everything that is not a-zA-Z0-9
  const nameFinal = nameNoLatin.replace(/[^a-zA-Z0-9]+/g, "");

  return nameFinal;
}
