import React from "react";
import { Center, Text } from "@chakra-ui/react";
import { UndecoratedScreen } from "./UndecoratedScreen";

export const MsgScreen = (props: { msg: string }) => {
  return (
    <UndecoratedScreen name="Message">
      <Center w="full" h="full">
        {props.msg}
      </Center>
    </UndecoratedScreen>
  );
};
