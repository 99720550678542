// always adds a sign (+/-), but not for 0
export function integerToSignedString(value: number) {
  let result = value.toLocaleString(undefined, { signDisplay: "always" });
  if (result === "-0" || result === "+0") result = "0";
  return result;
} // this avoids a "-0"

export function integerToString(value: number) {
  return value.toFixed().replace("-0", "0");
}

export function formatDate(date: Date) {
  return date.toLocaleDateString("en-US");
}

export function formatTime(date: Date) {
  return date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
}

export function formatDateTime(date: Date) {
  return formatDate(date) + " " + formatTime(date);
}
