import React, { useCallback } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
} from "@chakra-ui/react";
import { TwoButtonGroup } from "../primitives/TwoButtonGroup";
import { HFlex } from "../layout/HFlex";

export const ModalInput = (props: {
  isOpen: boolean;
  onClose: (succes: boolean, value?: string | number | boolean) => void;
  label: string; // human readable label
  description?: string; // optional longer description
  value: string | number | boolean; // the value
}) => {
  const [input, setInput] = React.useState(props.value);

  const renderInputElement = useCallback(() => {
    switch (typeof input) {
      case "string":
      case "number":
        return (
          <Input
            mt={4}
            mb={2}
            value={String(input)}
            onChange={(e) => setInput(e.target.value)}
            borderWidth={1}
            borderColor="gray.200"
          />
        );

      case "boolean":
        return (
          <HFlex justifyContent="center">
            <Switch
              size="md"
              isChecked={input}
              colorScheme="teal"
              onChange={(e) => setInput(e.target.checked)}
            />
          </HFlex>
        );
    }
  }, [input]);

  return (
    <Modal isOpen={props.isOpen} size={"xl"} onClose={() => props.onClose?.(false)}>
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader textAlign="center" fontWeight="700" fontSize="md">
          {`Enter ${props.label}`}
        </ModalHeader>
        <ModalBody px={6} py={4} borderTopWidth={1} borderBottomWidth={1} borderColor="gray.300">
          {props.description && <Text>{props.description}</Text>}
          {renderInputElement()}
        </ModalBody>
        <ModalFooter justifyContent="center">
          <TwoButtonGroup
            _left={{
              label: "Cancel",
              onClick: () => props.onClose?.(false),
            }}
            _right={{
              label: "Save",
              onClick: () => props.onClose?.(true, input),
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
