import React from "react";
import { BoxProps } from "@chakra-ui/react";
import { VFlex } from "./VFlex";

export const SectionTextIndent = (props: BoxProps) => {
  return (
    <VFlex position="relative" px={6} my={2} w="full" {...props}>
      {props.children}
    </VFlex>
  );
};
