import firebase from "firebase/compat/app";
import { VTransactionData } from "../lib/apiDefs";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";

export class VTransactionDoc extends createDocBaseClass<VTransactionData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const VTransactions = new (class VTransactionRepo extends Repo<VTransactionDoc> {
  constructor() {
    super("v_transactions", VTransactionDoc);
  }
})();
