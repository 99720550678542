import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { ifAdmin } from "../../components/conditional/IfAccess";
import { useAsync } from "../../hooks/reactUse";
import ReportRangeSelector from "../../components/report/ReportRangeSelector";
import { CTransactionItemRepo } from "../../model/CTransactionItemDoc";
import { BackAppScreenParamList } from "../../backAppStack";
import { CTransactionListCard } from "./CTransactionListCard";
import { docQuery } from "../../lib/firestore/fstore";
import { SectionBox } from "../../components/layout/Sections";

const TRANSACTION_LIMIT = 200;

const AccountCTransactionList = (props: { accountId: string; filterProfileId?: string }) => {
  const navigation = useNavigation<NativeStackNavigationProp<BackAppScreenParamList>>();
  const [isProfileFilter, setIsProfileFilter] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const { loading: transactionLoading, value: transactions } = useAsync(async () => {
    const TO_INDEX = [1, 7, 30, 90];
    const toDateString = DateTime.fromMillis(Date.now()).toJSDate();
    const fromDateCalculate = (selectedIndex: number) => {
      return DateTime.fromMillis(Date.now()).minus({ days: TO_INDEX[selectedIndex] }).toJSDate();
    };
    const fromDateString = fromDateCalculate(selectedIndex);
    let transactions = await docQuery(
      CTransactionItemRepo.query()
        .where("accountId", "==", props.accountId)
        .where("transactionAt", ">=", fromDateString)
        .where("transactionAt", "<=", toDateString)
        .orderBy("transactionAt", "desc")
        .limit(TRANSACTION_LIMIT)
    );
    if (isProfileFilter) {
      transactions = transactions.filter((t) => t.profileId === props.filterProfileId);
    }

    return transactions;
  }, [props.accountId, selectedIndex, isProfileFilter]);

  function handleTransactionDetails(transactionId: string) {
    navigation.push("AdminCTransactionDetails", { transactionId });
  }

  return (
    <>
      <SectionBox
        title="Transactions"
        filterLabel="Only Profile"
        onFilterChange={!!props.filterProfileId ? setIsProfileFilter : undefined}
      >
        <ReportRangeSelector
          tabLabels={["Today", "7 days", "30 days", "90 days"]}
          setParentIndex={setSelectedIndex}
        />
      </SectionBox>
      <CTransactionListCard
        isLoading={transactionLoading}
        transactions={transactions}
        limit={TRANSACTION_LIMIT}
        emptyMsg="No transaction in this time frame."
        onPress={ifAdmin(handleTransactionDetails)}
      />
    </>
  );
};

export default AccountCTransactionList;
