import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
  extendTheme,
  theme as baseTheme,
} from "@chakra-ui/react";

const TextStyle = defineStyleConfig({
  baseStyle: {
    color: "brand.text",
  },
});

const InputStyle = createMultiStyleConfigHelpers([
  "addon",
  "field",
  "element",
]).defineMultiStyleConfig({
  baseStyle: {
    field: {
      p: 3,
      py: 2,
      fontSize: "lg",
      fontWeight: "400",
      color: "brand.text",
      _placeholder: {
        color: "brand.text",
      },
      _disabled: {
        opacity: "0.5",
        color: "gray.900",
      },
    },
  },
  defaultProps: {
    variant: "unstyled",
  },
});

const RadioStyle = createMultiStyleConfigHelpers([
  "control",
  "label",
  "container",
]).defineMultiStyleConfig({
  baseStyle: {
    control: {
      borderColor: "brand.text",
    },
  },
  defaultProps: {
    size: "lg",
  },
});

export const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        color: "brand.text",
      },
    },
  },
  semanticTokens: {
    colors: {
      error: "red.700",
      muted: "gray.400",
    },
  },
  colors: {
    brand: {
      bg: "#f0f4f0",
      text: "#010A5A",
      secondaryText: baseTheme.colors.gray[400],
      divider: baseTheme.colors.gray[200],
      primary: baseTheme.colors.teal[800],
      section: {
        bg: "white",
        borderColor: baseTheme.colors.gray[300],
      },
      inventory: {
        borrowed: "#bbf7d0",
        inventory: "#4ade80",
        short: "#d4d4d4",
      },
    },
    muted: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#e5e5e5",
      300: "#d4d4d4",
      400: "#a3a3a3",
      500: "#737373",
      600: "#525252",
      700: "#404040",
      800: "#262626",
      900: "#171717",
    },
  },
  components: {
    Text: TextStyle,
    Input: InputStyle,
    Radio: RadioStyle,
  },
});
