import firebase from "firebase/compat/app";
import { VProfileData } from "../lib/apiDefs";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";

export class VProfileDoc extends createDocBaseClass<VProfileData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const VProfileRepo = new (class VProfileRepo extends Repo<VProfileDoc> {
  constructor() {
    super("v_profiles", VProfileDoc);
  }
})();
