import firebase from "firebase/compat/app";
import { ProductData } from "../lib/apiDefs";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";
export type { PointType } from "../lib/apiDefs";

export class ProductDoc extends createDocBaseClass<ProductData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const ProductRepo = new (class ProductRepo extends Repo<ProductDoc> {
  constructor() {
    super("products", ProductDoc);
  }
})();
