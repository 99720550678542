import { BoxProps, Text } from "@chakra-ui/react";
import { SectionBox } from "./Sections";

export type SectionMsgType = "text" | "error" | "muted";

export const SectionMsg = (
  props: {
    text: string | undefined | null;
    type?: SectionMsgType;
    textSize?: "sm" | "md";
  } & BoxProps
) => {
  const textColor =
    props.type === "text" ? "brand.text" : props.type === "error" ? "error" : "muted";

  return (
    <SectionBox {...props}>
      <Text
        visibility={props.text ? "visible" : "hidden"}
        color={textColor}
        textAlign="center"
        fontSize={props.textSize ?? "sm"}
      >
        {props.text ? props.text : "-"}
      </Text>
    </SectionBox>
  );
};

SectionMsg.defaultProps = { type: "text" };
