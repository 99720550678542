import React from "react";
import { formatDate } from "../../util/format";
import { HFlex } from "../layout/HFlex";
import { VFlex } from "../layout/VFlex";
import { Box, Text } from "@chakra-ui/react";
import { CardSection } from "../layout/Sections";
import { InfoIcon } from "../icons/InfoIcon";
import { DangerIcon } from "../icons/DangerIcon";

type GaugeValue = {
  value: number; // display value
  width: number; // flex width to use
  color: string; // color for the box
  label: string; // label for legend
  ratio: number; // how much off the total value
};

const borrowedColor = "brand.inventory.borrowed";
const inventoryColor = "brand.inventory.inventory";
const shortColor = "brand.inventory.short";

const InventoryAlert = (props: { difference: number; lastCountAt?: Date | null }) => {
  if (props.difference !== 0) {
    return (
      <Box m={1}>
        {props.difference > 0 ? (
          <HFlex mx={2} alignItems="center">
            <InfoIcon boxSize={5} color="yellow.600" />
            <Text mx={2} color="yellow.600" fontSize="sm" fontWeight={600}>
              {props.difference} excess container(s)
              {props.lastCountAt ? ` on [${formatDate(props.lastCountAt)}]` : ""}
            </Text>
          </HFlex>
        ) : null}
        {props.difference < 0 ? (
          <HFlex mx={2} alignItems="center">
            <DangerIcon boxSize={5} color="red.700" />
            <Text mx={2} color="red.700" fontSize="sm" fontWeight={700}>
              {-props.difference} lost container(s)
              {props.lastCountAt ? ` on [${formatDate(props.lastCountAt)}]` : ""}
            </Text>
          </HFlex>
        ) : null}
      </Box>
    );
  } else {
    return null;
  }
};

const GaugeBox = (props: {
  value: number;
  width: number;
  ratio: number;
  color: string;
  first: boolean;
  last: boolean;
  pos: "left" | "right";
}) => {
  return (
    <HFlex
      flex={props.width}
      px={2}
      bgColor={props.color}
      justifyContent="center"
      roundedLeft={props.first ? "sm" : "none"}
      roundedRight={props.last ? "sm" : "none"}
      minW="5"
    >
      {props.ratio > 0.5 || (props.ratio === 0.5 && props.pos === "left") ? (
        <>
          <Box flex={props.pos === "left" ? 50 : props.ratio * 100 - 50} />
          <Text>{props.value}</Text>
          <Box flex={props.pos === "right" ? 50 : props.ratio * 100 - 50} />
        </>
      ) : (
        <Text>&nbsp;</Text>
      )}
    </HFlex>
  );
};

const GaugeBar = (props: { values: GaugeValue[]; pos: "left" | "right" }) => {
  const totalWidth = props.values.reduce((acc, value) => {
    return acc + value.width!;
  }, 0);

  return (
    <HFlex flex={totalWidth} alignItems="flex-start">
      {props.values.map((v, i, a) => {
        return (
          <GaugeBox
            key={v.label}
            color={v.color}
            ratio={v.ratio}
            value={v.value}
            width={v.width!}
            first={i === 0 && props.pos === "left"}
            last={i === a.length - 1 && props.pos === "right"}
            pos={props.pos}
          />
        );
      })}
    </HFlex>
  );
};

const Gauge = (props: {
  values: GaugeValue[];
  line: number;
  subscriptionValue: number;
  extraValue: number;
}) => {
  // split the gauge box in a part before and after the subscription line
  const before: GaugeValue[] = [];
  const after: GaugeValue[] = [];
  let from = 0;
  for (const v of props.values) {
    const to = from + v.width!;
    if (from < props.line) {
      if (to > props.line) {
        const afterValue = to - props.line;
        const beforeValue = v.value - afterValue;
        before.push({
          color: v.color,
          label: v.label,
          value: v.value,
          width: beforeValue,
          ratio: beforeValue / v.value,
        });
        after.push({
          color: v.color,
          label: v.label,
          value: v.value,
          width: afterValue,
          ratio: afterValue / v.value,
        });
      } else {
        before.push(v);
      }
    } else {
      after.push(v);
    }
    from = to;
  }

  // width of the before and after bar
  const beforeWidth = before.reduce((acc, value) => {
    return acc + value.width!;
  }, 0);
  const afterWidth = after.reduce((acc, value) => {
    return acc + value.width!;
  }, 0);

  return (
    <HFlex mx={2} mt={2}>
      <VFlex flex={beforeWidth}>
        <GaugeBar values={before} pos="left" />
        <HFlex justifyContent="flex-end" alignItems="flex-end">
          <Text mx={1}>{props.subscriptionValue}</Text>
          <Box h="36px" w="2px" bgColor="brand.text" />
        </HFlex>
      </VFlex>
      {after.length > 0 ? (
        <VFlex flex={afterWidth}>
          <GaugeBar values={after} pos="right" />
          <VFlex alignItems="flex-start" mx={1} mt={2}>
            <Text>{props.extraValue}</Text>
          </VFlex>
        </VFlex>
      ) : null}
    </HFlex>
  );
};

const LegendItem = (props: { color: string; label: string }) => {
  return (
    <HFlex alignItems="center">
      <Box h={3} w={3} rounded="sm" bgColor={props.color} mx={1}></Box>
      <Text fontSize="sm" fontWeight="400">
        {props.label}
      </Text>
    </HFlex>
  );
};

const Legend = (props: { values: GaugeValue[] }) => {
  return (
    <VFlex flex={3} m={2}>
      {props.values.map((v) => {
        return <LegendItem key={v.label} label={v.label} color={v.color} />;
      })}
    </VFlex>
  );
};

export const InventoryGauge = (props: {
  isLoading: boolean;
  subscription: number;
  borrowed: number;
  inventory: number;
  difference: number; // - -> missing, + -> extra
  lastCountAt?: Date | null;
}) => {
  const gauges: GaugeValue[] = [];

  // where the subscription line should be shown
  let subscriptionLine = props.subscription;

  // borrow box
  const borrowed = props.borrowed;
  if (borrowed) {
    gauges.push({
      color: borrowedColor,
      value: borrowed,
      width: borrowed,
      label: "Borrowed",
      ratio: 1.0,
    });
  }

  // inventory box
  const inventory = props.inventory;
  if (props.inventory !== 0) {
    gauges.push({
      color: inventoryColor,
      value: inventory,
      width: inventory < 0 ? 1 : inventory,
      label: "Current Inventory",
      ratio: 1.0,
    });
    if (inventory < 0) subscriptionLine++;
  }

  // difference box
  // if (difference < 0) {
  //   gauges.push({
  //     color: missingColor,
  //     value: -difference,
  //     width: -difference,
  //     label: "Missing",
  //     ratio: 1.0,
  //   });
  // }
  // if (difference > 0) {
  //   gauges.push({
  //     color: extraColor,
  //     value: difference,
  //     width: difference,
  //     label: "Extra",
  //     ratio: 1.0,
  //   });
  // }

  // short box
  const total = borrowed + (inventory < 0 ? 0 : inventory);
  if (total < props.subscription) {
    const short = props.subscription - total;
    gauges.push({
      color: shortColor,
      value: short,
      width: short,
      label: "Short",
      ratio: 1.0,
    });
  }

  return (
    <CardSection title="Inventory" alignItems="start" w="full">
      {!props.isLoading ? (
        props.subscription ? (
          <VFlex py={1} w="full">
            <Gauge
              values={gauges}
              line={subscriptionLine}
              subscriptionValue={props.subscription}
              extraValue={total > props.subscription ? total - props.subscription : 0}
            />
            <Legend values={gauges} />
            <InventoryAlert difference={props.difference ?? 0} lastCountAt={props.lastCountAt} />
          </VFlex>
        ) : (
          <Text m={5} alignSelf="center">
            No containers delivered
          </Text>
        )
      ) : (
        <Text m={5} alignSelf="center">
          Loading ...
        </Text>
      )}
    </CardSection>
  );
};

export default InventoryGauge;
