import React, { useCallback, useState } from "react";
import { Screen } from "../../components/layout/Screen";
import { PropertiesCard } from "../../components/properties/PropertiesCard";
import { useAsyncFn } from "../../hooks/reactUse";
import { useFocusEffect } from "@react-navigation/native";
import pick from "lodash/pick";
import { StatsRow } from "../../components/composite/StatsRow";
import { useRoles } from "../../lib/auth/useRoles";
import { useAuthentication } from "../../lib/auth/useAuthentication";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { BackAppScreenProps } from "../../backAppStack";
import { ifAdmin } from "../../components/conditional/IfAccess";
import { VBalanceDoc, VBalanceRepo } from "../../model/VBalanceDoc";
import { CProfileDoc, CProfileRepo } from "../../model/CProfileDoc";
import { CPaymentDoc, CPaymentRepo } from "../../model/CPaymentDoc";
import { CActivityDoc, CActivityRepo } from "../../model/CActivityDoc";
import { docGet } from "../../lib/firestore/fstore";
import { PropertiesRecord } from "../../components/properties/types";
import { safe } from "../../util/safe";
import { CAccountDoc } from "../../model/CAccountDoc";
import { AccountInfoCard } from "../account/AccountInfoCard";
import { useBackRpc } from "../../lib/functions/rpc";
import { formatDateTime } from "../../util/format";
import { Box } from "@chakra-ui/react";
import { ProfileInfoCard } from "./ProfileInfoCard";

export const AdminProfileDetailsScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminCustomerDetails">) => {
  const { user } = useAuthentication();
  const { isSuperAdmin } = useRoles();
  const [archiveState, doArchive] = useBackRpc("userArchive");
  const [msg, setMsg] = useState<string>();

  const [dataState, doLoadData] = useAsyncFn(async () => {
    const [profile, activity] = await Promise.all([
      docGet(CProfileRepo.doc(route.params.profileId)),
      docGet(CActivityRepo.doc(route.params.profileId)),
    ]);
    const [account, payment] = await Promise.all([
      profile && CProfileRepo.findAccount(profile),
      profile && docGet(CPaymentRepo.doc(profile.paymentId)),
    ]);
    const balance = account && (await docGet(VBalanceRepo.doc(account.id)));
    return { profile, activity, payment, account, balance };
  });

  const loadData = useCallback(() => {
    doLoadData();
  }, []);

  // regularly reload the screen, to see fresh data
  useFocusEffect(loadData);

  function renderDetails(
    profile: CProfileDoc,
    payment: CPaymentDoc | null | undefined,
    activity: CActivityDoc | null | undefined,
    account: CAccountDoc | null | undefined,
    balance: VBalanceDoc | null | undefined
  ) {
    const kv: PropertiesRecord = {};
    kv["Id"] = { value: profile.id, feature: ["clipboard"] };
    kv["Status"] = profile.status ?? "";
    kv["Type"] = profile.type ?? "app";
    kv["Current Balance"] = balance?.outstandingSum ?? "0";
    kv["Next Expires"] = balance?.getNextExpiresAt()?.toLocaleDateString();
    kv["Payment Info"] = (payment?.status ?? "") + (payment?.card ? " | " + payment.card : "");
    if (profile.authenticated) {
      kv["Sign Up Date"] = profile.signUpAt ? formatDateTime(profile.signUpAt) : "-";
    }
    if (profile.type === "app") {
      kv["App"] = JSON.stringify(pick(activity?.appInfo ?? {}, ["web", "native"])).replace(
        /[\"{}]/g,
        ""
      );
      kv["Installation Id"] = {
        value: profile?.installationIds?.at(-1) ?? "",
        feature: ["clipboard"],
      };
    }

    const borrowed = balance?.total.borrowed.toFixed() ?? "0";
    const returned = safe.add(balance?.total.returned, balance?.total.credits).toFixed();
    const purchased = balance?.total.purchased.toFixed() ?? "0";

    return (
      <Box>
        <ProfileInfoCard profileId={route.params.profileId} />
        {!!msg && <SectionMsg text={msg} type="error" />}
        {!!account && (
          <AccountInfoCard
            title={account.ownerId === profile.id ? "Account owned" : "Using account"}
            accountId={account.id}
            onPress={handleAccountDetails}
          />
        )}
        <StatsRow title="Lifetime" borrowed={borrowed} returned={returned} purchased={purchased} />
        <PropertiesCard title="Details" properties={kv} />
      </Box>
    );
  }

  const handleAccountDetails = useCallback((accountId: string) => {
    navigation.navigate("AdminAccountDetails", { accountId });
  }, []);

  const handleChangeRole = useCallback(() => {
    if (dataState.value?.profile?.email) {
      navigation.navigate("AdminChangeRole", {
        profileId: dataState.value.profile.id,
        email: dataState.value.profile.email,
      });
    }
  }, [dataState.value?.profile]);

  const handleSendText = useCallback(() => {
    if (dataState.value?.profile) {
      navigation.navigate("AdminCustomerSendText", {
        profileId: dataState.value.profile.id,
      });
    }
  }, [dataState.value?.profile]);

  const handleArchive = useCallback(async () => {
    console.log("handle archive called", dataState.value);
    if (dataState.value?.profile) {
      const result = await doArchive({ userId: dataState.value?.profile.id });
      if (result.success) {
        setMsg("Customer Archived");
      } else {
        setMsg(result.msg);
      }
    }
  }, [dataState.value?.profile]);

  const allowChangeRole =
    isSuperAdmin && // only @recirclable can change the role
    dataState.value?.profile?.status !== "archived" && // user must not be archived
    !!dataState.value?.profile?.email && // user must have an email
    user?.uid !== dataState.value?.profile?.id; // don't change your own role

  return (
    <Screen
      name="Profile Details"
      showTitle={true}
      titleMenu={ifAdmin(
        {
          "Send Text Message": !!dataState.value?.profile?.phone && handleSendText,
          "Change Role": allowChangeRole && handleChangeRole,
          "Archive Customer":
            dataState.value?.profile?.status !== "archived" &&
            !dataState.value?.balance?.outstandingSum &&
            handleArchive,
        },
        {}
      )}
    >
      {dataState.value
        ? renderDetails(
            dataState.value?.profile!,
            dataState.value?.payment,
            dataState.value?.activity,
            dataState.value?.account,
            dataState.value?.balance
          )
        : null}
    </Screen>
  );
};
