import firebase from "firebase/compat/app";
import { CPaymentData } from "../lib/apiDefs";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";

export class CPaymentDoc extends createDocBaseClass<CPaymentData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const CPaymentRepo = new (class CPaymentRepo extends Repo<CPaymentDoc> {
  constructor() {
    super("c_payments", CPaymentDoc);
  }
})();
