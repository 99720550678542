import React from "react";
import { CProfileData } from "../../lib/apiDefs";
import { formatDate } from "../../util/format";
import { VFlex } from "../../components/layout/VFlex";
import { HFlex } from "../../components/layout/HFlex";
import { Box, Text } from "@chakra-ui/react";
import { AccountIcon } from "../../components/icons/AccountIcon";
import { AccountOutlineIcon } from "../../components/icons/AccountOutlineIcon";
import { CardSectionItem } from "../../components/layout/Sections";

export type SearchResultData = {
  accountId: string;
  profileId?: string; // <- not provided for balance
  status?: CProfileData["status"];
  name?: string | null;
  email?: string | null;
  phone?: string | null;

  // only for balance results
  outstandingSum?: number;
  expiresAt?: Date;
};

export const SearchResultRow = (props: {
  resultData: SearchResultData;
  onPress?: (profileId: string) => void;
}) => {
  const determineStatus = (profile: SearchResultData) => {
    if (profile.status) {
      return profile.status;
    } else {
      if (profile.email?.startsWith("*") || profile.phone?.startsWith("*")) {
        return "archived";
      } else {
        return "active";
      }
    }
  };
  const status = determineStatus(props.resultData);

  return (
    <CardSectionItem
      onClick={
        props.onPress
          ? () => {
              props.onPress?.(props.resultData.accountId);
            }
          : undefined
      }
      w="full"
    >
      <VFlex w="full">
        <HFlex justifyContent="space-between" alignContent="center" w="100%">
          <HFlex alignItems="center">
            {status === "active" ? (
              <AccountIcon color="brand.text" boxSize={5} />
            ) : (
              <AccountOutlineIcon color="brand.text" boxSize={5} />
            )}
            <Text
              ml={1}
              color="brand.text"
              fontSize="md"
              fontWeight={status === "archived" ? "400" : "700"}
              textDecoration={status === "archived" ? "line-through" : "none"}
            >
              {props.resultData.name ?? "[no name]"}
            </Text>
          </HFlex>
          <Text color="brand.text" fontSize="sm" fontWeight="400">
            {props.resultData.email ?? "[no email]"}
          </Text>
        </HFlex>
        <HFlex justifyContent="space-between" alignContent="center" w="100%">
          {props.resultData.profileId && (
            <Text color="brand.secondaryText" fontSize="sm" fontWeight="400">
              {`${props.resultData.profileId.slice(0, 10)}...`}
            </Text>
          )}
          {!!props.resultData.outstandingSum && props.resultData.outstandingSum > 0 && (
            <Text fontSize="sm" fontWeight="400">
              {props.resultData.outstandingSum ?? 0} outstanding, due{" "}
              {props.resultData.expiresAt ? formatDate(props.resultData.expiresAt) : ""}
            </Text>
          )}
          <Box flex={1} />
          <Text color="brand.text" fontSize="sm" fontWeight="400">
            {props.resultData.phone ?? "[no phone]"}
          </Text>
        </HFlex>
      </VFlex>
    </CardSectionItem>
  );
};
