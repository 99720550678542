import * as Sentry from "@sentry/react";
import { appConfigExtra, isProductionLike } from "../../util/config";

export function initSentry() {
  Sentry.init({
    dsn: appConfigExtra().sentry.dsn,
    tracesSampleRate: 1.0,
    environment: appConfigExtra().projectId,
    enabled: isProductionLike(),
  });
}

export function sentry() {
  return Sentry;
}
