import React from "react";
import { Text, TextProps, keyframes } from "@chakra-ui/react";

export const TextWithLoading = (props: TextProps & { isLoading?: boolean }) => {
  const { isLoading, ...restProps } = props;

  if (!isLoading) {
    return <Text {...restProps}>{props.children}</Text>;
  } else {
    const animationKeyframes = keyframes`
      0% { opacity: 0.2; }
      50% { opacity: 1.0; }
      100% { opacity: 0.2; }
    `;
    const animation = `${animationKeyframes} 1.2s ease infinite`;

    return (
      <Text {...restProps} color="gray.500" animation={animation}>
        {"\u25A2"}
      </Text>
    );
  }
};
