import _cloneDeepWith from "lodash/cloneDeepWith";
import _isPlainObject from "lodash/isPlainObject";
import superjson from "superjson";

export const defaultTransformer = {
  input: {
    // client
    serialize: (object: any) => {
      return superjson.serialize(object);
    },

    // server
    deserialize: (object: any) => {
      return superjson.deserialize(object);
    },
  },

  output: {
    // server
    serialize: (object: any) => {
      return superjson.serialize(object);
    },

    // client
    deserialize: (object: any) => {
      // --> see below for the fix
      return superjson.deserialize(fixObjectPrototype(object));
    },
  },
};

// workaround for a bug either in superjson or somewhere else.
//
// superjson internally checks whether an object is a plain object. that check compares the
// value's prototype to Object.prototype. for some reason the prototype of plain object IS NOT
// that Object.prototype. on the other hand lodash's isPlainObject does it correct. but they have
// the check implement differently. my suspicion is that JSON parsing happens in a different JS
// context and because of that the prototype is from a different context.
//
// the fix clones the object and that way adjust the prototype.
function fixObjectPrototype(outerValue: any): any {
  return _cloneDeepWith(outerValue, (innerValue) => {
    if (_isPlainObject(innerValue)) {
      if (Object.getPrototypeOf(innerValue) !== Object.prototype) {
        return fixObjectPrototype({ ...innerValue });
      }
    }
  });
}
