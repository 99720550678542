import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { HFlex } from "../../components/layout/HFlex";
import { ScreenTitle } from "../../components/layout/ScreenTitle";
import { LocationDoc, LocationRepo } from "../../model/LocationDoc";
import { MenuDef } from "../../components/composite/ActionMenu";
import { useDocQuery } from "../../lib/firestore/fstore_hooks";
import { ChevronDownCircleIcon } from "../../components/icons/ChevronDownCircleIcon";

const LocationMenu = (props: {
  locations: LocationDoc[];
  onSelect: (location: LocationDoc) => void;
}) => {
  return (
    <Menu placement="bottom-end" gutter={4}>
      <MenuButton>
        <ChevronDownCircleIcon mx={1} boxSize={5} color="brand.text" />
      </MenuButton>
      <MenuList py={1}>
        {props.locations.map((location) => {
          return (
            <MenuItem
              key={location.id}
              onClick={() => props.onSelect(location)}
              _focus={{ bg: "white" }}
            >
              {location.name}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export const PartnerLocationSelector = (props: {
  partnerId: string;
  menu?: MenuDef;
  onSelectLocation: (location: LocationDoc) => void;
}) => {
  const { loading: loadingLocations, value: locations } = useDocQuery(
    LocationRepo.queryEntity(props.partnerId)
  );
  const [location, setLocation] = useState<LocationDoc | null>(null);

  useEffect(() => {
    if (!loadingLocations && !!locations && locations.length > 0) {
      handleSelectLocation(locations[0]);
    }
  }, [loadingLocations]);

  function handleSelectLocation(selected: LocationDoc) {
    setLocation(selected);
    props.onSelectLocation(selected);
  }

  return (
    <HFlex>
      <ScreenTitle
        title={location ? location.name : "..."}
        secondaryTitle="Dashboard"
        titleButton={
          locations && locations.length > 1 ? (
            <LocationMenu locations={locations} onSelect={handleSelectLocation} />
          ) : null
        }
        menu={props.menu}
      />
    </HFlex>
  );
};
