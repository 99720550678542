import firebase from "firebase/compat/app";
import { TypedDoc } from "./fstore";

export type AnyDocRefType<D extends TypedDoc> =
  | string
  | firebase.firestore.DocumentReference<D>
  | D;

// convert any reference to a document into a Firestore doc ref
export function docRef<D extends TypedDoc>(
  id_ref_doc: null,
  collectionRef: firebase.firestore.CollectionReference<D>
): null;
export function docRef<D extends TypedDoc>(
  id_ref_doc: undefined,
  collectionRef: firebase.firestore.CollectionReference<D>
): undefined;
export function docRef<D extends TypedDoc>(
  id_ref_doc: AnyDocRefType<D>,
  collectionRef: firebase.firestore.CollectionReference<D>
): firebase.firestore.DocumentReference<D>;
export function docRef<D extends TypedDoc>(
  id_ref_doc: AnyDocRefType<D> | null | undefined,
  collectionRef: firebase.firestore.CollectionReference<D>
): firebase.firestore.DocumentReference<D>;
export function docRef<D extends TypedDoc>(
  id_ref_doc: AnyDocRefType<D> | null | undefined,
  collectionRef: firebase.firestore.CollectionReference<D>
): firebase.firestore.DocumentReference<D> | null | undefined {
  if (id_ref_doc === null || id_ref_doc === undefined) return id_ref_doc;
  if (typeof id_ref_doc === "string") {
    return collectionRef.doc(id_ref_doc);
  } else if ("fireRef" in id_ref_doc) {
    return id_ref_doc.fireRef();
  }
  // a Firestore reference
  return id_ref_doc;
}

// extract from any reference a unique identifier
export function docRefId(
  id_ref_doc: AnyDocRefType<TypedDoc> | null | undefined
): string | null | undefined {
  if (!id_ref_doc) return id_ref_doc;

  if (typeof id_ref_doc === "string") {
    return id_ref_doc;
  }

  // .id works both for the doc and the ref
  return id_ref_doc.id;
}
