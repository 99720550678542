import React from "react";
import { Text } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { VInventoryLevelDoc } from "../../model/VInventoryLevelDoc";
import { HFlex } from "../layout/HFlex";
import { CardSection, CardSectionItem } from "../layout/Sections";

export function LocationMetricList(
  props: PropsWithChildren<{
    items: Array<VInventoryLevelDoc>;
    onSelect: (item: VInventoryLevelDoc) => void;
  }>
) {
  function renderRow(item: VInventoryLevelDoc) {
    return (
      <CardSectionItem
        key={item.id}
        alignItems="center"
        justifyContent="space-between"
        onClick={() => props.onSelect(item)}
      >
        <HFlex onClick={() => props.onSelect(item)} justifyContent="space-between" w="full">
          <Text flex={2} color="brand.text" fontSize="md" fontWeight="600">
            {item.test ? `${item.locationName} [TEST]` : item.locationName}
          </Text>
          <HFlex flex={1} justifyContent="space-around">
            <Text flex={1} textAlign="end" color="brand.text" fontSize="md" fontWeight="600">
              {item.currentOutstanding}
            </Text>
            <Text flex={1} textAlign="end" color="brand.text" fontSize="md" fontWeight="600">
              {item.currentQuantity}
            </Text>
            <Text flex={1} textAlign="end" color="brand.text" fontSize="md" fontWeight="600">
              {item.currentDelta}
            </Text>
          </HFlex>
        </HFlex>
      </CardSectionItem>
    );
  }

  return (
    <CardSection withDivider={true}>
      {props.items.map((item) => {
        return renderRow(item);
      })}
    </CardSection>
  );
}
