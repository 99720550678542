import React from "react";
import { ScreenTitle } from "./ScreenTitle";
import { VSpace } from "./VSpace";
import { MenuDef } from "../composite/ActionMenu";
import { Header } from "./Header";
import { useCloseScreen } from "../navigation/useCloseScreen";
import { useRoute } from "@react-navigation/native";
import { isEmbeddedWebApp } from "../../lib/auth/embeddedApp";
import { VFlex } from "./VFlex";

export type ScreenProps = {
  name: string;
  secondaryTitle?: string;
  children?: JSX.Element | JSX.Element[] | string | any;
  showTitle?: boolean;
  titleMenu?: MenuDef;
  onBack?: () => void;
};

export const Screen = (props: ScreenProps) => {
  const closeScreen = useCloseScreen();
  const route = useRoute();

  const isHome = route.name.includes("Home");
  const onBack = !isHome ? props.onBack ?? closeScreen : undefined;

  return (
    <VFlex key={props.name} w="100vw" h="100vh" alignSelf="center" bg="brand.bg" overflow="hidden">
      {!isEmbeddedWebApp() && (
        <Header title={"Recirclable"} backMode={!isHome ? "back" : undefined} onBack={onBack} />
      )}
      <VFlex px={[0, 0, 4, 16]} width="full" height="full" overflow="scroll">
        {props.showTitle ? (
          <ScreenTitle
            title={props.name}
            secondaryTitle={props.secondaryTitle}
            menu={props.titleMenu}
          />
        ) : null}
        {props.children}
        {/* extra space helps with reaching elements at the botton, which might be covered */}
        <VSpace minH="60px" />
      </VFlex>
    </VFlex>
  );
};
