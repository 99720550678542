const REPLACE_REGEX = /[\(\)\-\/\.\s]/g;
export function fixPhone(phone: string | null | undefined): string {
  if (!phone) phone = "";

  phone = phone.trim();
  phone = phone.replace(REPLACE_REGEX, "");
  if (phone.startsWith("0")) return phone;
  if (phone.startsWith("1")) phone = "+" + phone;
  if (!phone.startsWith("+")) phone = "+1" + phone;

  return phone;
}

const PHONE_REGEX = /^\+(?:[0-9]●?){6,14}[0-9]$/g;
export function validatePhone(phone: string | null | undefined) {
  return !!phone && !!phone.match(PHONE_REGEX) && (!phone.startsWith("+1") || phone.length == 12);
}
