// icon:clock-alert-outline | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";
import { createIcon } from "@chakra-ui/react";

export const ExpiredIcon = createIcon({
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M11 7v6l5.2 3.1.8-1.2-4.5-2.7V7H11m9 5v6h2v-6h-2m0 8v2h2v-2h-2m-2 0c-1.7 1.3-3.7 2-6 2-5.5 0-10-4.5-10-10S6.5 2 12 2c4.8 0 8.9 3.4 9.8 8h-2.1c-.9-3.4-4-6-7.7-6-4.4 0-8 3.6-8 8s3.6 8 8 8c2.4 0 4.5-1.1 6-2.7V20z"
    />
  ),
});
