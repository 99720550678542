import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { VFlex } from "../layout/VFlex";
import { HFlex } from "../layout/HFlex";
import { ContainerDoc } from "../../model/ContainerDoc";
import { BowlEmptyIcon } from "../icons/BowlEmptyIcon";

export const ContainerList: React.FunctionComponent<{
  containers: ContainerDoc[];
  showProductInfo?: boolean;
  emptyMsg?: string;
  continueMsg?: string;
}> = (props) => {
  const msg = props.containers.length > 0 ? props.continueMsg : props.emptyMsg;

  return (
    <VFlex flex={1}>
      {props.containers.map((item) => {
        return (
          <HFlex key={item.id} px={3} py={1} alignItems="center">
            <Box marginTop={-2} marginRight={2}>
              <BowlEmptyIcon boxSize={8} color="brand.text" />
            </Box>
            <Text color="brand.text" fontSize="md" fontWeight="600">
              {item.name}
              {props.showProductInfo ? ` (${item.productId})` : null}
            </Text>
          </HFlex>
        );
      })}
      {!!msg && (
        <HFlex key={"msg"} px={3} py={1} alignItems="center">
          <Box marginTop={-2} marginRight={2}>
            <BowlEmptyIcon boxSize={8} color="brand.text" />
          </Box>
          <Text color="muted.400" fontSize="sm" fontWeight="600" noOfLines={2}>
            {msg}
          </Text>
        </HFlex>
      )}
    </VFlex>
  );
};
