import React from "react";
import { TabButton } from "./TabButton";
import { ButtonGroup } from "@chakra-ui/react";

type Props = {
  values: string[];
  selectedIndex: number;
  onTabPress: Function;
};

const TabControl = (props: Props) => {
  const handleTabPress = (index: number, selectedIndex: number, onTabPress: Function) => {
    if (selectedIndex !== index) {
      onTabPress(index);
    }
  };

  const buttonWidth: string = `${100 / props.values.length}%`;

  return (
    <ButtonGroup isAttached borderRadius="md" w="full">
      {props.values &&
        props.values.map((item, index: number) => {
          return (
            <TabButton
              width={buttonWidth}
              key={item}
              label={item}
              index={index}
              isPressed={props.selectedIndex === index}
              onTabPress={(index: number) =>
                handleTabPress(index, props.selectedIndex, props.onTabPress)
              }
            />
          );
        })}
    </ButtonGroup>
  );
};

export default TabControl;
