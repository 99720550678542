// icon:exit-outline | Ionicons https://ionicons.com/ | Ionic Framework
import * as React from "react";
import { createIcon } from "@chakra-ui/react";

export const InventoryToOtherIcon = createIcon({
  viewBox: "0 0 512 512",
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M320 176v-40a40 40 0 00-40-40H88a40 40 0 00-40 40v240a40 40 0 0040 40h192a40 40 0 0040-40v-40M384 176l80 80-80 80M191 256h273"
    />
  ),
});
