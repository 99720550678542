import { ButtonProps } from "@chakra-ui/react";
import { PrimaryButton } from "../primitives/PrimaryButton";
import { SectionBox } from "./Sections";

export const SectionPrimaryButton = (props: { label: string } & ButtonProps) => {
  return (
    <SectionBox>
      <PrimaryButton {...props} />
    </SectionBox>
  );
};
