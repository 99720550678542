import React from "react";
import { useAsync } from "../../hooks/reactUse";
import { useRoles } from "../../lib/auth/useRoles";
import { CTransactionItemRepo } from "../../model/CTransactionItemDoc";
import { CTransactionListCard } from "./CTransactionListCard";
import { docQuery } from "../../lib/firestore/fstore";

const TRANSACTION_LIMIT = 200;

export const LocationCTransactionList: React.FunctionComponent<{
  partnerId: string;
  locationId: string;
  onPress?: (itemId: string) => void;
}> = (props) => {
  const { eroot } = useRoles();
  const result = useAsync(async () => {
    return await docQuery(
      CTransactionItemRepo.queryEntity(eroot)
        .where("locationId", "==", props.locationId)
        .orderBy("transactionAt", "desc")
        .limit(TRANSACTION_LIMIT)
    );
  });

  return (
    <CTransactionListCard
      isLoading={result.loading}
      transactions={result.value}
      limit={TRANSACTION_LIMIT}
      showLocation={false}
      onPress={props.onPress}
    />
  );
};
