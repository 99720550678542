import React from "react";
import { UndecoratedScreen } from "./UndecoratedScreen";
import { ONE_SEC } from "../../util/constants";
import { Center, Spinner } from "@chakra-ui/react";
import { useError, useTimeout, useTimeoutFn } from "react-use";

// how long to wait until error?
// - must be longer than cold start (have seen several seconds) + POS checkout wait time
// - must be shorter then Wait Screen time out, otherweise a function timeout can't be
//   handled gracefully anymore in the app,
export const WAIT_SCREEN_TIMEOUT = 20 * ONE_SEC;

export const WaitScreen = (props: { name: string }) => {
  const dispatchError = useError();
  const [showSpinner] = useTimeout(1000); // Spinner after 1sec wait

  // timeout with error after 20sec. useTimeoutFn cancels the timer when the
  // component is umounted.
  useTimeoutFn(() => {
    dispatchError(new Error("WaitScreen timeout"));
  }, WAIT_SCREEN_TIMEOUT);

  return (
    <UndecoratedScreen name={props.name}>
      <Center w="full" h="full">
        {showSpinner() && <Spinner color="brand.text" size="lg" />}
      </Center>
    </UndecoratedScreen>
  );
};
