import React, { useState } from "react";
import { useAsync } from "../../hooks/reactUse";
import { expandProtoRelUrl } from "../../util/url";
import { CardSection } from "../layout/Sections";
import { LocationRepo } from "../../model/LocationDoc";
import { PartnerRepo } from "../../model/PartnerDoc";
import { LBillingRepo } from "../../model/LBillingDoc";
import { docGet } from "../../lib/firestore/fstore";
import { VFlex } from "../layout/VFlex";
import { HFlex } from "../layout/HFlex";
import { Box, Image, Text } from "@chakra-ui/react";

const PARTNER_IMAGE_SIZE = "80px";

export const LocationInfoCard = (props: {
  title?: string;
  partnerId?: string; // DEPRECATED: not used anymore
  locationId: string;
}) => {
  const [imageUri, setImageUri] = useState<string>("/partner-placeholder-gray.png");
  const [locationName, setLocationName] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");

  const { value: location } = useAsync(async () => {
    const location = await docGet(LocationRepo.doc(props.locationId));
    if (location) {
      setLocationName(location.name);
      setAddressLine1(location.address.address1 ?? "");
      setAddressLine2(
        `${location.address?.city ?? ""} ${location.address?.state ?? ""} ${
          location.address?.postalCode ?? ""
        }`
      );
    }
    if (location?.image) {
      setImageUri(expandProtoRelUrl(location.image));
    }
    return location;
  });

  return (
    <CardSection
      title={props.title ?? "Location"}
      json={{
        name: "Location",
        data: async () => {
          const partner = await docGet(PartnerRepo.doc(location!.partnerId));
          const billing = await docGet(LBillingRepo.doc(location!.id));
          return { location, partner, billing };
        },
      }}
    >
      <HFlex alignItems="center">
        {location ? (
          <Image
            src={imageUri}
            fallbackSrc="assets/images/partner-placeholder-gray.png"
            alt={locationName ?? "Loading ..."}
            objectFit="contain"
            p={1}
            w={PARTNER_IMAGE_SIZE}
            h={PARTNER_IMAGE_SIZE}
          />
        ) : (
          <Box backgroundColor="white" w={PARTNER_IMAGE_SIZE} h={PARTNER_IMAGE_SIZE}></Box>
        )}
        <VFlex py={1} px={3} flex={1}>
          <HFlex alignItems="center" justifyContent="space-between">
            <Text lineHeight="2xl" fontWeight={700}>
              {locationName}
            </Text>
          </HFlex>
          <Text fontWeight={400} fontSize="sm">
            {addressLine1}
          </Text>
          <Text fontWeight={400} fontSize="sm">
            {addressLine2}
          </Text>
        </VFlex>
      </HFlex>
    </CardSection>
  );
};
