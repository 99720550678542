import React from "react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { HFlex } from "../../components/layout/HFlex";
import { DeliverReasonCode } from "../../model/LTransactionItemDoc";

export const DeliverReasonCodeSelector = (props: {
  mode?: "deliver" | "remove";
  value: DeliverReasonCode | null;
  quantity: number;
  onReasonCode: (code: DeliverReasonCode) => void;
}) => {
  return (
    <HFlex my={1} justifyContent="center">
      <RadioGroup
        name="Reason Code"
        colorScheme="teal"
        value={props.value ?? ""}
        onChange={(nextValue) => {
          props.onReasonCode(nextValue as DeliverReasonCode);
        }}
      >
        <Stack direction="column" gap={1}>
          <Radio value="rebalance" isDisabled={props.quantity === 0}>
            Rebalance
          </Radio>
          {props.mode !== "remove" && (
            <Radio value="subscription" isDisabled={props.quantity <= 0}>
              Subscription Delivery
            </Radio>
          )}
          {props.mode !== "remove" && (
            <Radio value="replacement" isDisabled={props.quantity <= 0}>
              Expire/Broken Replacement
            </Radio>
          )}
          {props.mode !== "remove" && (
            <Radio value="lost_replacement" isDisabled={props.quantity <= 0}>
              Lost Bowl Replacement
            </Radio>
          )}
          {props.mode !== "deliver" && (
            <Radio value="end_subscription" isDisabled={props.quantity >= 0}>
              End Subscription
            </Radio>
          )}
        </Stack>
      </RadioGroup>
    </HFlex>
  );
};
