import React from "react";
import { Box, BoxProps, Divider, Text, useDisclosure } from "@chakra-ui/react";
import { useAsync } from "../../hooks/reactUse";
import { getApiVersion, getBuildNumber, getProjectId } from "../../util/config";
import { SectionBox } from "../layout/Sections";
import { FrontRpc } from "../../lib/functions/rpc";
import { isEmbeddedWebApp } from "../../lib/auth/embeddedApp";
import { useAuthentication } from "../../lib/auth/useAuthentication";

export const VersionDetails = () => {
  const authState = useAuthentication();
  const versionState = useAsync(async () => FrontRpc.call("versionInfo", {}), []);

  function sliceHostInfo() {
    let userAgent = window.navigator.userAgent;
    if (userAgent.includes("Recirclable")) {
      userAgent = userAgent.substring(userAgent.indexOf("Recirclable"));
    } else {
      userAgent = "other";
    }
    return userAgent;
  }

  return (
    <Box>
      <Divider orientation="horizontal" borderColor="brand.divider" my={4} />
      <Text textAlign="center" color="muted.400" fontSize="xs">
        User: {authState.user?.email ?? authState.user?.phoneNumber}
      </Text>
      <Text textAlign="center" color="muted.400" fontSize="xs">
        User Id: {authState.user?.uid}
      </Text>
      <Text textAlign="center" color="muted.400" fontSize="xs">
        Project: {getProjectId()} (admin-app)
      </Text>
      <Text mt={3} textAlign="center" color="muted.400" fontSize="xs">
        Location: {window.location.hostname}
      </Text>
      <Text textAlign="center" color="muted.400" fontSize="xs">
        Version: {getBuildNumber()}
      </Text>
      {isEmbeddedWebApp() ? (
        <Text textAlign="center" color="muted.400" fontSize="xs">
          Host: {sliceHostInfo()}
        </Text>
      ) : null}
      <Text textAlign="center" color="muted.400" fontSize="xs">
        API: {getApiVersion()}
      </Text>
      {versionState.value ? (
        <Text textAlign="center" color="muted.400" fontSize="xs">
          Backend: {versionState.value.version}
        </Text>
      ) : null}
    </Box>
  );
};

export const VersionSection = (props: BoxProps) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <SectionBox mb={8} {...props}>
      <Text textAlign="center" color="muted.400" fontSize="xs" onClick={onToggle}>
        {`\u00A9 Recirclable LLC, ${new Date().getFullYear()}`}
      </Text>
      {isOpen ? <VersionDetails /> : null}
    </SectionBox>
  );
};
