import firebase from "firebase/compat/app";
import { PartnerData } from "../lib/apiDefs";
import { createDocBaseClass } from "../lib/firestore/fstore";
import { Repo } from "../lib/firestore/fstore";

export class PartnerDoc extends createDocBaseClass<PartnerData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const PartnerRepo = new (class PartnerRepo extends Repo<PartnerDoc> {
  constructor() {
    super("partners", PartnerDoc);
  }
})();
