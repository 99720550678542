import { createTRPCProxyClient, httpLink } from "@trpc/client";
import type { AdminAppRouter } from "../../../../backend/src/bff/admin/adminBff";
import { defaultTransformer } from "./transformer";
import { emulatorPorts, firebaseAuth, getEmulatorHostName } from "../firebase/fbenv";
import { getProjectId, isEmulator } from "../../util/config";

export const FUNCTIONS_REGION = "us-east4";

async function headers() {
  const idToken = await firebaseAuth().currentUser?.getIdToken();
  return {
    authorization: `Bearer ${idToken}`,
    "x-recirclable-version": "1.0",
  };
}

export function createAdminBackendClient() {
  let url = `https://${FUNCTIONS_REGION}-${getProjectId()}.cloudfunctions.net/admin_bff`;
  if (isEmulator()) {
    console.log(
      "🚀 using Firebase Functions emulator",
      getEmulatorHostName(),
      emulatorPorts.functions
    );
    url = `http://${getEmulatorHostName()}:${
      emulatorPorts.functions
    }/${getProjectId()}/${FUNCTIONS_REGION}/admin_bff`;
  }
  console.log("🚀 configure backend end at", url);

  return createTRPCProxyClient<AdminAppRouter>({
    transformer: defaultTransformer,
    links: [
      httpLink({
        url,
        headers,
      }),
    ],
  });
}
