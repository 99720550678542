import React from "react";
import { CProfileData } from "../../lib/apiDefs";
import { Text } from "@chakra-ui/react";
import { VFlex } from "../../components/layout/VFlex";
import { HFlex } from "../../components/layout/HFlex";
import { AccountIcon } from "../../components/icons/AccountIcon";
import { AccountOnlineIcon } from "../../components/icons/AccountIconOnline";
import { AccountTerminalIcon } from "../../components/icons/AccountTerminalIcon";
import { AccountAnonymousIcon } from "../../components/icons/AccountAnonymousIcon";
import { UnknownIcon } from "../../components/icons/UnknownIcon";

export const ProfileListRow = (props: {
  profileData: CProfileData;
  onPress?: (profileId: string) => void;
}) => {
  const typeIcon = {
    app: <AccountIcon boxSize={5} color="brand.text" />,
    online: <AccountOnlineIcon boxSize={5} color="brand.text" />,
    terminal: <AccountTerminalIcon boxSize={5} color="brand.text" />,
    anonymous: <AccountAnonymousIcon boxSize={5} color="brand.text" />,
    unknown: <UnknownIcon boxSize={5} color="brand.text" />,
  };

  const determineStatus = (profile: CProfileData) => {
    if (profile.status) {
      return profile.status;
    } else {
      if (profile.email?.startsWith("*") || profile.phone?.startsWith("*")) {
        return "archived";
      } else {
        return "active";
      }
    }
  };

  const customerStatus = determineStatus(props.profileData);

  return (
    <VFlex
      px={3}
      py={2}
      w="full"
      onClick={
        props.onPress
          ? () => {
              props.onPress?.(props.profileData.id);
            }
          : undefined
      }
    >
      <HFlex justifyContent="space-between" alignContent="center" w="100%">
        <HFlex alignItems="center">
          {typeIcon[props.profileData.type ?? "app"]}
          <Text
            ml={1}
            color="brand.text"
            fontSize="md"
            fontWeight={customerStatus === "archived" ? "400" : "700"}
            textDecoration={customerStatus === "archived" ? "line-through" : "none"}
          >
            {props.profileData.name ??
              (props.profileData.type === "app" ? "[no name]" : props.profileData.type)}
          </Text>
        </HFlex>
        <Text color="brand.text" fontSize="sm" fontWeight="400">
          {props.profileData.email ?? (props.profileData.type === "app" ? "[no email]" : "")}
        </Text>
      </HFlex>
      <HFlex justifyContent="space-between" alignContent="center" w="100%">
        <Text color="brand.secondaryText" fontSize="sm" fontWeight="400">
          {props.profileData.id.slice(0, 10)}...
        </Text>
        <Text color="brand.text" fontSize="sm" fontWeight="400">
          {props.profileData.phone ?? "[no phone]"}
        </Text>
      </HFlex>
    </VFlex>
  );
};
