import firebase from "firebase/compat/app";
import { PointData } from "../lib/apiDefs";
import { Repo, createDocBaseClass, docQuery } from "../lib/firestore/fstore";
export type { PointType } from "../lib/apiDefs";

export class PointDoc extends createDocBaseClass<PointData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const PointRepo = new (class PointRepo extends Repo<PointDoc> {
  constructor() {
    super("points", PointDoc);
  }

  async pointsAtSameLocation(point: PointDoc) {
    return docQuery(this.query().where("locationId", "==", point.locationId));
  }
})();
