import { createIcon } from "@chakra-ui/react";

// Ionicons: chevron-back-circle-outline

export const BackIcon = createIcon({
  viewBox: "0 0 512 512",
  path: (
    <g>
      <path
        d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="32"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M296 352l-96-96 96-96"
      />
    </g>
  ),
});
