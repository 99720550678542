import firebase from "firebase/compat/app";
import { EntityData } from "../lib/apiDefs";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";

export class EntityDoc extends createDocBaseClass<EntityData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const EntityRepo = new (class EntityRepo extends Repo<EntityDoc> {
  constructor() {
    super("entities", EntityDoc);
  }
})();
