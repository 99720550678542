import React from "react";
import { CardSection } from "../layout/Sections";
import { HFlex } from "../layout/HFlex";
import { Box, Text } from "@chakra-ui/react";
import { TextWithLoading } from "../primitives/TextWithLoading";

const AverageReportRow = (props: { isLoading?: boolean; label: string; value: string }) => {
  return (
    <HFlex marginLeft={2} my={1} alignItems="center" justifyItems="flex-start">
      <Box minW={8} mr={1}>
        <TextWithLoading
          color="brand.text"
          textAlign="right"
          fontSize="lg"
          fontWeight={700}
          isLoading={props.isLoading}
        >
          {props.value}
        </TextWithLoading>
      </Box>
      <Text fontSize="sm">{props.label}</Text>
    </HFlex>
  );
};

const AveragesReportView = (props: {
  isLoading: boolean;
  avgBorrowCnt: number;
  avgBorrowDays: number;
}) => {
  return (
    <>
      <CardSection title="Averages" withDivider={true} alignItems="start">
        <AverageReportRow
          isLoading={props.isLoading}
          label="Average containers per order"
          value={(Math.round(props.avgBorrowCnt * 10) / 10).toLocaleString("en-US", {
            minimumFractionDigits: 1,
          })}
        />
        <AverageReportRow
          isLoading={props.isLoading}
          label="Average days borrowed"
          value={(Math.round(props.avgBorrowDays * 10) / 10).toLocaleString("en-US", {
            minimumFractionDigits: 1,
          })}
        />
      </CardSection>
    </>
  );
};

export default AveragesReportView;
