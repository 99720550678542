import React from "react";
import { Text } from "@chakra-ui/react";
import { formatDate, formatTime } from "../../util/format";
import { CTransactionItemDoc } from "../../model/CTransactionItemDoc";
import { HFlex } from "../../components/layout/HFlex";
import { VFlex } from "../../components/layout/VFlex";
import { VoidIcon } from "../../components/icons/VoidIcon";
import { CardIcon } from "../../components/icons/CardIcon";
import { BowlEmptyIcon } from "../../components/icons/BowlEmptyIcon";
import { BowlFullIcon } from "../../components/icons/BowlFullIcon";
import { IncompleteIcon } from "../../components/icons/IncompleteIcon";
import { CardSectionItem } from "../../components/layout/Sections";

export const CTransactionRow: React.FunctionComponent<{
  transaction: CTransactionItemDoc;
  showLocation?: boolean;
  onPress?: (transactionId: string) => void;
}> = (props) => {
  let showLocation = props.showLocation !== undefined ? props.showLocation : true;
  let locationName = props.transaction.locationName;

  let icon: React.ReactNode;
  let info: string;
  switch (props.transaction.type) {
    case "borrow":
      info = `${props.transaction.qty} borrowed`;
      icon = <BowlFullIcon boxSize={8} color="brand.text" />;

      // handle matchable/unmatched
      if (props.transaction.matchable === true) info += " (waiting for register)";
      if (props.transaction.matchable === false) info += " (incomplete)";
      if (props.transaction.matchable !== null) {
        icon = <IncompleteIcon boxSize={8} color="brand.text" />;
      } else {
        if (props.transaction.outstandingQty) {
          // visulize outstanding
          info += ` (${props.transaction.outstandingQty})`;
        }
      }
      break;

    case "return":
      info = `${-props.transaction.qty} returned`;
      if (props.transaction.creditQty) {
        info += ` + ${props.transaction.creditQty} extra`;
      }
      icon = <BowlEmptyIcon boxSize={8} color="brand.text" />;
      break;

    case "expire":
      info = `${-props.transaction.qty} purchased`;
      if (props.transaction.relations?.voidIds?.length) {
        if (props.transaction.relations?.returnIds?.length) {
          info += " (refund + return)";
        } else {
          info += " (refund)";
        }
      }
      icon = <CardIcon boxSize={8} color="brand.text" />;
      break;

    case "void":
      info = `${props.transaction.qty} refunded`;
      icon = <VoidIcon boxSize={8} color="brand.text" />;
      break;
  }

  // additional information for pos orders. can be both a borrow or a return
  let label1: string;
  let text1: string;
  let label2: string | null;
  let text2: string | null;
  switch (props.transaction.external?.source) {
    case "square":
      label1 = "Receipt Nr:";
      text1 = props.transaction.external?.receiptNr ?? "-";
      label2 = "Order Nr:";
      text2 = props.transaction.external?.isOnline ? props.transaction.external?.orderNr! : "-";
      break;

    case "toast":
      label1 = "Check Nr:";
      text1 = props.transaction.external?.receiptNr ?? "-";
      label2 = "Payment Id:";
      text2 = props.transaction.external?.paymentId ?? "-";
      break;

    default:
      label1 = "Mode:";
      text1 = `${props.transaction.source} / ${props.transaction.mode}`;
      label2 = null;
      text2 = null;
      break;
  }

  return (
    <CardSectionItem
      alignItems="center"
      w="full"
      onClick={
        props.onPress
          ? () => {
              props.onPress?.(props.transaction.id);
            }
          : undefined
      }
    >
      {icon}
      <VFlex flex={1} pl={3} w="full">
        <HFlex justifyContent="space-between" alignContent="center" w="full">
          <Text color="brand.text" fontSize="md" fontWeight="700">
            {showLocation ? locationName : info}
          </Text>
          <Text color="brand.text" fontSize="sm" fontWeight="400">
            {formatDate(props.transaction.transactionAt)}
          </Text>
        </HFlex>
        {showLocation && (
          <HFlex justifyContent="space-between" alignContent="center" w="full">
            <Text color="brand.text" fontSize="sm" fontWeight="400">
              {info}
            </Text>
            <Text color="brand.text" fontSize="sm" fontWeight="400">
              {formatTime(props.transaction.transactionAt)}
            </Text>
          </HFlex>
        )}
        <HFlex justifyContent="space-between" alignContent="center" w="100%">
          <HFlex alignContent="center">
            <Text color="brand.text" fontSize="sm" fontWeight="400">
              {label1}
            </Text>
            <Text pl={1} color="brand.text" fontSize="sm" fontWeight="700">
              {text1}
            </Text>
          </HFlex>
          {!showLocation && (
            <Text color="brand.text" fontSize="sm" fontWeight="400">
              {formatTime(props.transaction.transactionAt)}
            </Text>
          )}
        </HFlex>
        {!!label2 && (
          <HFlex alignContent="center">
            <Text color="brand.text" fontSize="sm" fontWeight="400">
              {label2}
            </Text>
            <Text pl={1} color="brand.text" fontSize="sm" fontWeight="700">
              {text2}
            </Text>
          </HFlex>
        )}
      </VFlex>
    </CardSectionItem>
  );
};
