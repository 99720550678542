import React, { useCallback } from "react";
import { ScreenTitle } from "../../components/layout/ScreenTitle";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { LocationInfoCard } from "../../components/views/LocationInfoCard";
import { PropertiesCard } from "../../components/properties/PropertiesCard";
import { LBillingRepo } from "../../model/LBillingDoc";
import { LocationRepo } from "../../model/LocationDoc";
import { useDocWatch } from "../../lib/firestore/fstore_hooks";
import { docQuery, docUpdate } from "../../lib/firestore/fstore";
import { DateTime } from "luxon";
import { RECIRCLABLE_TIMEZONE } from "../../util/constants";
import { useAsync } from "../../hooks/reactUse";
import { LInvoiceRepo } from "../../model/LInvoiceDoc";
import { useBackRpc } from "../../lib/functions/rpc";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminLocationDetailsScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationDetails">) => {
  const { doc: location } = useDocWatch(LocationRepo.doc(route.params.locationId));
  const { doc: billing } = useDocWatch(LBillingRepo.doc(route.params.locationId));

  const invoiceDate = DateTime.now()
    .setZone(RECIRCLABLE_TIMEZONE)
    .minus({ months: 1 })
    .endOf("month");
  const invoicingMonth = invoiceDate.toFormat("yyyy-MM");
  const invoiceMonth = invoiceDate.toFormat("LLLL");

  const [createInvoiceState, sendCreateInvoice] = useBackRpc("createLocationInvoice");

  const { value: canCreateInvoice } = useAsync(async () => {
    if (location && billing?.enabled && billing?.email) {
      const invoices = await docQuery(
        LInvoiceRepo.query()
          .where("locationId", "==", location.id)
          .where("month", "==", invoicingMonth)
      );
      console.log("invoices", invoices);
      return invoices.length === 0;
    }
    return false;
  }, [location?.id, billing?.enabled, billing?.email, createInvoiceState.loading]);

  const handleInvoice = useCallback(() => {
    if (location && canCreateInvoice) {
      sendCreateInvoice({ locationId: location.id, invoiceDate: invoiceDate.toJSDate() });
    }
  }, [canCreateInvoice, location?.id]);

  function renderBody() {
    if (location) {
      const kv: Record<string, string | number | boolean> = {};
      kv["Location Id"] = location.id.substring(0, 8) + " ...";
      kv["Location Name"] = location.name;
      // kv["POS Linked"] = "" + (partner.pos ?? false);

      return (
        <>
          <ScreenTitle title={location.name} secondaryTitle={"Details"} />

          <LocationInfoCard title="Overview" locationId={route.params.locationId} />

          <PropertiesCard
            title="Contact"
            properties={{
              Name: location.name,
              "Address 1": location.address.address1,
              "Address 2": location.address.address2,
              City: location.address.city,
              State: location.address.state,
              "Zip Code": location.address.postalCode,
              Phone: location.phone,
              "Home page": location.url,
            }}
          />

          <PropertiesCard
            title="Content"
            properties={{
              Visible: location.visible,
              "Contentful Id": location.external?.contentfulId,
            }}
          />

          <PropertiesCard
            title="Billing"
            properties={{
              "Billing Enabled": {
                feature: ["edit"],
                key: "enabled",
                value: billing?.enabled ?? true,
                type: "boolean",
              },
              "Billing Email": {
                feature: ["edit"],
                key: "email",
                value: billing?.email,
                type: "string",
                description:
                  "This email address is used to connect to a Stripe customer for billing.",
              },
              "Stripe Customer Id": billing?.stripe.customerId,
              "Container Fee (cent)": {
                feature: ["edit"],
                key: "plan.containerFee",
                value: billing?.plan.containerFee,
                type: "number",
              },
              "Tracking Fee (cent)": {
                feature: ["edit"],
                key: "plan.trackingFee",
                value: billing?.plan.trackingFee,
                type: "number",
              },
              "Min Tracking": {
                feature: ["edit"],
                key: "plan.minTracking",
                value: billing?.plan.minTracking,
                type: "number",
              },
              "Tracking Discount (0, 25, 50, 100)": {
                feature: ["edit"],
                key: "plan.trackingDiscount",
                value: billing?.plan.trackingDiscount ?? 0,
                type: "number",
              },
            }}
            onChange={(key, value) => {
              if (billing) docUpdate(billing, { [key]: value });
            }}
          />

          <SectionPrimaryButton
            label={`Invoice ${invoiceMonth}`}
            isDisabled={!canCreateInvoice}
            isLoading={createInvoiceState.loading}
            onClick={handleInvoice}
          />

          <PropertiesCard
            title="Online Ordering"
            properties={{
              "Online Ordering": location.onlineOrder,
              "Order URL": location.onlineOrderUrl,
              "Inbound Email": location.onlineOrder
                ? {
                    value: `orders.${location.inboundId}@inbound.recirclable.app`,
                    feature: ["clipboard"],
                  }
                : undefined,
            }}
          />
        </>
      );
    } else {
      return null;
    }
  }

  return <Screen name="Location Details">{renderBody()}</Screen>;
};
