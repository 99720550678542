import React, { useState } from "react";
import { useAsync } from "../../hooks/reactUse";
import { createAdminBackendClient } from "../../lib/trpc/adminBackend";
import ActivityReportView from "./ActivityReportView";
import { DateTime } from "luxon";

const ActivityReportContainer = (props: { entityId: string; inclIncomplete: boolean }) => {
  const [rangeIndex, setRangeIndex] = useState<number>(0);
  const nowDate = Date.now();

  const reportRangeState = useAsync(async () => {
    let range: "day" | "month" | "total";
    let date: string | undefined;
    switch (rangeIndex) {
      case 0:
        date = DateTime.fromMillis(nowDate).toFormat("yyyy-MM-dd");
        range = "day";
        break;
      case 1:
        date = DateTime.fromMillis(nowDate).startOf("month").toFormat("yyyy-MM-dd");
        range = "month";
        break;
      case 2:
        date = DateTime.fromMillis(nowDate)
          .startOf("month")
          .minus({ months: 1 })
          .toFormat("yyyy-MM-dd");
        range = "month";
        break;
      default:
        range = "total";
    }

    const client = createAdminBackendClient();
    return client.reportRange.mutate({ entityId: props.entityId, range, date });
  }, [props.entityId, rangeIndex]);

  return (
    <ActivityReportView
      onRangeChange={setRangeIndex}
      loading={reportRangeState.loading}
      customerTile={props.entityId === "R" ? "signups" : "new"}
      inclIncomplete={props.inclIncomplete}
      reportData={
        reportRangeState.value ?? {
          borrowCnt: 0,
          borrowQty: 0,
          returnCnt: 0,
          returnQty: 0,
          matchableCnt: 0,
          matchableQty: 0,
          unmatchedCnt: 0,
          unmatchedQty: 0,
          expireCnt: 0,
          expireQty: 0,
          voidCnt: 0,
          voidQty: 0,
          customersActive: 0,
          customersNew: 0,
          signUps: 0,
        }
      }
    />
  );
};

export default ActivityReportContainer;
