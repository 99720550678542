import React from "react";
import { IconButton } from "@chakra-ui/react";
import { useCloseScreen } from "../navigation/useCloseScreen";
import { CloseIcon } from "../icons/CloseIcon";
import { UndecoratedScreen } from "./UndecoratedScreen";

export type ScreenProps = {
  name: string;
  children?: JSX.Element | JSX.Element[] | string | any;
};

export const FullScreen = (props: ScreenProps) => {
  const closeScreen = useCloseScreen();

  return (
    <UndecoratedScreen {...props}>
      {props.children}
      <IconButton
        aria-label="Close"
        position="absolute"
        top={2}
        right={4}
        alignSelf="flex-start"
        backgroundColor="rgba(128, 128, 128, 0.5)"
        borderRadius={99}
        variant="unstyled"
        icon={<CloseIcon color={"white"} boxSize={7} />}
        onClick={() => closeScreen()}
      />
    </UndecoratedScreen>
  );
};
