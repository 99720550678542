import firebase from "firebase/compat/app";
import { firebaseAuth } from "../firebase/fbenv";
import { create, StoreApi } from "zustand";
import { FUNC_NOOP } from "../../util/constants";
import { sentry } from "../sentry/sentry";

//
// Auth State definition
//

export type AuthStatePending = {
  isPending: true;
  isAuthenticated: false;
  redirectError: undefined;
  user: undefined;
  signInProviderId?: undefined;
  claims?: undefined;
  start: () => void;
  stop: () => Promise<void>;
  signOut: () => Promise<void>;
  refresh: (userId?: string) => void;
};

const AUTH_PENDING: Omit<AuthStatePending, "start" | "stop" | "signOut" | "refresh"> = {
  isPending: true,
  isAuthenticated: false,
  redirectError: undefined,
  user: undefined,
};

export type AuthStateNotAuthenticated = {
  isPending: false;
  isAuthenticated: false;
  redirectError?: any;
  user: undefined;
  signInProviderId?: undefined;
  claims?: undefined;
  start: () => void;
  stop: () => Promise<void>;
  signOut: () => Promise<void>;
  refresh: (userId?: string) => void;
};

const AUTH_NOT_AUTHENTICATED: Omit<
  AuthStateNotAuthenticated,
  "start" | "stop" | "signOut" | "refresh"
> = {
  isPending: false,
  isAuthenticated: false,
  // note: don't set redirectError here
  user: undefined,
};

export type AuthStateAuthenticated = {
  isPending: false;
  isAuthenticated: true;
  redirectError: undefined;
  user: firebase.User;
  signInProviderId?: string | null;
  claims?: any;
  start: () => void;
  stop: () => Promise<void>;
  signOut: () => Promise<void>;
  refresh: (userId?: string) => Promise<void>;
};

export type AuthState = AuthStatePending | AuthStateAuthenticated | AuthStateNotAuthenticated;

//
// Auth State logic
//

function authSubscribe(set: StoreApi<AuthState>["setState"]) {
  return firebaseAuth().onAuthStateChanged((user) => {
    console.log("AUTH STATE: change", user);

    // NOTE: when opened from the web consumer app, we pass the user id as query parameter. that
    // way we can ensure that if a user is already authenticated, it's the same as the one requested
    const params = new URLSearchParams(document.location.search);
    const requestUserId = params.get("__userId");
    if (requestUserId) {
      // clean the url. don't want that user bookmark the URL with the user id
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    if (user) {
      if ((user.email || user.phoneNumber) && (!requestUserId || requestUserId === user.uid)) {
        // console.log("AUTH STATE: authenticated", user.email, user.phoneNumber);

        // also retrieve the claims for a user
        user
          .getIdTokenResult()
          .then((result) => {
            set({
              isPending: false,
              isAuthenticated: true,
              redirectError: undefined,
              user,
              signInProviderId: result.signInProvider, // which provider was used for sign-in?
              claims: result.claims, // claims, will be used for roleState
            });
          })
          .catch((error) => {
            set({
              isPending: false,
              isAuthenticated: true,
              redirectError: undefined,
              user,
            });
          });

        // track user id
        sentry().setUser({ id: user.uid });
      } else {
        // either wrong user, or no phone and email. sign out.
        firebaseAuth().signOut();
        set(AUTH_NOT_AUTHENTICATED);
        sentry().configureScope((scope) => scope.setUser(null));
      }
    } else {
      // console.log("AUTH STATE: user is null");

      set(AUTH_NOT_AUTHENTICATED);
      sentry().configureScope((scope) => scope.setUser(null));
    }
  });
}

// the core authentication state
export const useAuthentication = create<AuthState>((set, get) => {
  let unsubscribe = FUNC_NOOP;

  let store: AuthState = {
    ...AUTH_PENDING,

    start: () => {
      // IMPORTANT: when the store is initialized, it's not yet listening to the auth state.
      // if we would listen then, firebase auth is required, which requires the projectId,
      // which requires the app manifest, which is on production not available while JS global
      // code executes. this is because expo runs it's update logic independent of the app.
      // so start listening lazy with start()
      if (unsubscribe === FUNC_NOOP) {
        unsubscribe = authSubscribe(set);
      }
    },

    stop: async () => {
      // first sign out. this should also trigger all subscribers.
      await firebaseAuth().signOut();

      // ... then stop listening to Firebase.
      unsubscribe();
      unsubscribe = FUNC_NOOP;
    },

    // NOTE:
    // (1) can be used to force a reload of the auth state. currently used
    //     after the name is changed. a name change does NOT trigger an auth
    //     state change. that's we must enforce a refresh. that's the best way
    //     to inform all derived states.
    // (2) it can be used to pre-announce a user change. in that case the auth
    //     state is put into the pending state. overall causes the sign-up
    //     screen to disappear fast and replace it with a wait screen from the
    //     AuthGuard.
    refresh: async (userId?: string) => {
      if (!userId) {
        unsubscribe();
        unsubscribe = await authSubscribe(set);
      } else if (get().user?.uid !== userId) {
        set(AUTH_PENDING);
      }
    },

    signOut: () => {
      return authSignOut();
    },
  };

  return store;
});

// -----  utility functions  -----

// sign out the current user
export async function authSignOut() {
  // this doesn't work. error message says, that the cache can be cleared only before the
  // firestore instances is initialized or after it's terminated. research whether we really
  // need to clear the cache. probably not.
  // await firebaseFirestore().clearPersistence();
  await firebaseAuth().signOut();
  return;
}
