import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { HFlex } from "../layout/HFlex";
import { VFlex } from "../layout/VFlex";
import { CardSection, CardSectionItem } from "../layout/Sections";

export type DetailsLabel = {
  icon?: JSX.Element;
  above?: string;
  title: string;
  below?: string;
  right?: string;
};

export function SimpleList<T extends { id: string }>(
  props: PropsWithChildren<{
    title?: string;
    items: Array<T> | null | undefined;
    getItemLabel: (item: T) => string | DetailsLabel;
    onSelect?: (item: T) => void;
    onAction?: (item: T) => void;
  }>
) {
  const list = props.items ?? [];

  function renderRowContent(label: string | DetailsLabel) {
    if (typeof label === "string") {
      return (
        <Text color="brand.text" fontSize="md" fontWeight="600">
          {label}
        </Text>
      );
    } else {
      return (
        <HFlex w="100%" alignItems={"center"}>
          {!!label.icon && <Box mr={2}>{label.icon}</Box>}
          <VFlex flex={1}>
            {!!label.above && (
              <Text color="brand.secondaryText" fontSize="sm" fontWeight="600">
                {label.above}
              </Text>
            )}
            <HFlex justifyContent={"space-between"}>
              <Text color="brand.text" fontSize="md" fontWeight="700">
                {label.title}
              </Text>
              {!!label.right && (
                <Text fontSize="sm" fontWeight="400">
                  {label.right}
                </Text>
              )}
            </HFlex>
            {!!label.below && (
              <Text color="brand.text" fontSize="sm" fontWeight="600">
                {label.below}
              </Text>
            )}
          </VFlex>
        </HFlex>
      );
    }
  }

  function renderRow(item: T, label: string | DetailsLabel) {
    return (
      <CardSectionItem
        key={item.id}
        alignItems="center"
        justifyContent="space-between"
        onClick={() => props.onSelect?.(item)}
      >
        {renderRowContent(label)}
      </CardSectionItem>
    );
  }

  return (
    <CardSection title={props.title} withDivider={true}>
      {list.map((item) => {
        return renderRow(item, props.getItemLabel(item));
      })}
    </CardSection>
  );
}
