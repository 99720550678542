import Constants from "expo-constants";

// note: this is the extra data from the Expo app.json manifest
export const appConfig = () => {
  return Constants.manifest2?.extra?.expoClient ?? Constants.manifest;
};

// note: this is the extra data from the Expo app.json manifest
export const appConfigExtra = () => {
  const config = appConfig()?.extra || {};
  return config;
};

export const appName = () => {
  return appConfig()?.name;
};

export function appUniversalDomain() {
  if (isProduction()) {
    return "recirclable.app";
  } else {
    return getPrjId() + ".recirclable.app";
  }
}

export function getAppScheme() {
  if (isProduction()) return "recirclable";
  return getProjectId();
}

export function getProjectId() {
  return appConfigExtra().firebase.projectId;
}

export function getPrjId() {
  return appConfigExtra().prj;
}

export function getAppVersion() {
  return appConfig()?.version ?? "N/A";
}

export function getBuildNumber() {
  return appConfigExtra().buildNumber ?? "N/A";
}

export function getApiVersion() {
  return appConfigExtra().apiVersion ?? "N/A";
}

export function isEmulator(): boolean {
  // NOTE: 'undefined' happens for unit tests. we basically treat unit test like emulator runs.
  return getProjectId() === "recirclable-emu" || getProjectId() === undefined;
}

export function isDevelopment(): boolean {
  return getProjectId().startsWith("recirclable-dev");
}

export function isProduction(): boolean {
  return getProjectId() === "recirclable-prd";
}

export function ifProduction<T>(trueValue: T): T | undefined;
export function ifProduction<T>(trueValue: T, falseValue: T): T;
export function ifProduction<T>(trueValue: T, falseValue?: T): T | undefined {
  return isProduction() ? trueValue : falseValue;
}

export function ifNotProduction<T>(trueValue: T): T | undefined;
export function ifNotProduction<T>(trueValue: T, falseValue: T): T;
export function ifNotProduction<T>(trueValue: T, falseValue?: T): T | undefined {
  return !isProduction() ? trueValue : falseValue;
}

export function isProductionLike(): boolean {
  return getProjectId() === "recirclable-prd" || getProjectId() === "recirclable-stg";
}
