import firebase from "firebase/compat/app";
import { VInventoryLevelData } from "../lib/apiDefs";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";

export class VInventoryLevelDoc extends createDocBaseClass<VInventoryLevelData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }

  get currentOutstanding() {
    return -this.outstandingSum;
  }

  get currentQuantity() {
    return this.deliverSum + this.countSum + this.itemSum;
  }

  get currentDelta() {
    const total = this.currentOutstanding + this.currentQuantity;
    return total - this.subscriptionSum;
  }
}

export const VInventoryLevelRepo = new (class VInventoryLevels extends Repo<VInventoryLevelDoc> {
  constructor() {
    super("v_inventory_levels", VInventoryLevelDoc);
  }
})();
