import React from "react";
import type { inferRouterOutputs } from "@trpc/server";
import type { AdminAppRouter } from "../../../../backend/src/bff/admin/adminBff";
import { Metric, ReportTile } from "./ActivityReportTile";
import ReportRangeSelector from "./ReportRangeSelector";
import { ReportRow } from "./ActivityReportRow";
import { HFlex } from "../layout/HFlex";
import { IfAdmin } from "../conditional/IfAccess";
import { safe } from "../../util/safe";
import { CardSection, SectionBox } from "../layout/Sections";
import { Box, Text } from "@chakra-ui/react";
import { ReportCountIcon } from "../icons/ReportCountIcon";
import { CardIcon } from "../icons/CardIcon";
import { BowlEmptyIcon } from "../icons/BowlEmptyIcon";
import { BowlFullIcon } from "../icons/BowlFullIcon";
import { ReportExpireQtyIcon } from "../icons/ReportExpireQtyIcon";
import { ReportAccountPlusIcon } from "../icons/ReportAccountPlusIcon";
import { ReportAccountStarIcon } from "../icons/ReportAccountStarIcon";
import { ReportIncompleteCntIcon } from "../icons/ReportIncompleteCntIcon";
import { BowlIcon } from "../icons/BowlIcon";

const ActivityReportView = (props: {
  onRangeChange: (index: number) => void;
  loading: boolean;
  customerTile: "signups" | "new";
  inclIncomplete: boolean;
  reportData: inferRouterOutputs<AdminAppRouter>["reportRange"];
}) => {
  const customersBorrowedComplex: Metric = {
    label: "customersBorrowed",
    value: props.reportData.borrowCnt,
    icon: <ReportCountIcon color="brand.text" boxSize={7} />,
    iconTopMargin: 0,
    iconSize: 7,
  };
  const bowlsBorrowedComplex: Metric = {
    label: "bowlsBorrowed",
    value: props.reportData.borrowQty,
    icon: <BowlFullIcon color="brand.text" boxSize={7} />,
    iconTopMargin: -2,
    iconSize: 7,
  };
  const customersReturnedComplex: Metric = {
    label: "customersReturned",
    value: props.reportData.returnCnt,
    icon: <ReportCountIcon color="brand.text" boxSize={7} />,
    iconTopMargin: 0,
    iconSize: 7,
  };
  const bowlsReturnedComplex: Metric = {
    label: "bowlsReturned",
    value: props.reportData.returnQty,
    icon: <BowlEmptyIcon color="brand.text" boxSize={7} />,
    iconTopMargin: -2,
    iconSize: 7,
  };
  const customersPurchasedComplex: Metric = {
    label: "customersPurchased",
    value: safe.sub(props.reportData.expireCnt, props.reportData.voidCnt),
    icon: <CardIcon color="brand.text" boxSize={7} />,
    iconSize: 7,
    iconTopMargin: 0.5,
  };
  const bowlsPurchasedComplex: Metric = {
    label: "bowlsPurchased",
    value: safe.add(props.reportData.expireQty, props.reportData.voidQty), // NOTE: voidQty is a negative value
    icon: <ReportExpireQtyIcon color="brand.text" boxSize={7} />,
    iconTopMargin: -2,
    iconSize: 7,
  };
  const incompleteTransactionsComplex: Metric = {
    label: "incompleteTransactions",
    value: safe.add(props.reportData.unmatchedCnt, props.reportData.matchableCnt),
    icon: <ReportIncompleteCntIcon color="brand.text" boxSize={7} />,
    iconSize: 7,
    iconTopMargin: 0.5,
  };
  const incompleteBowlsComplex: Metric = {
    label: "incompleteBowls",
    value: safe.add(props.reportData.unmatchedQty, props.reportData.matchableQty),
    icon: <BowlIcon color="brand.text" boxSize={7} />,
    iconTopMargin: -1,
    iconSize: 7,
  };

  const signUpsComplex: Metric = {
    label: "signUps",
    value: props.reportData.signUps,
    icon: <ReportAccountPlusIcon color="brand.text" boxSize={7} />,
    iconSize: 7,
    iconTopMargin: 0,
  };
  const activeCustomersComplex: Metric = {
    label: "activeCustomers",
    value: props.reportData.customersActive,
    icon: <ReportAccountStarIcon color="brand.text" boxSize={7} />,
    iconSize: 7,
    iconTopMargin: 0,
  };
  const newCustomersComplex: Metric = {
    label: "newCustomers",
    value: props.reportData.customersNew,
    icon: <ReportAccountStarIcon color="brand.text" boxSize={7} />,
    iconSize: 7,
    iconTopMargin: 0,
  };
  return (
    <>
      <SectionBox title="Activity">
        <ReportRangeSelector
          tabLabels={["Today", "This Mth", "Last Mth", "All"]}
          setParentIndex={props.onRangeChange}
        />
      </SectionBox>
      <CardSection alignItems="start" withDivider={true}>
        <ReportRow
          title="Borrowed"
          isLoading={props.loading}
          metrics={[customersBorrowedComplex, bowlsBorrowedComplex]}
        />
        <ReportRow
          title="Returned"
          isLoading={props.loading}
          metrics={[customersReturnedComplex, bowlsReturnedComplex]}
        />
        <ReportRow
          title="Net Purchased"
          isLoading={props.loading}
          metrics={[customersPurchasedComplex, bowlsPurchasedComplex]}
        />
        {props.inclIncomplete && (
          <ReportRow
            title="Incomplete"
            type="error"
            isLoading={props.loading}
            metrics={[incompleteTransactionsComplex, incompleteBowlsComplex]}
          />
        )}

        {props.customerTile === "signups" && (
          <IfAdmin>
            <HFlex py={2} px={5} alignItems="center" w="full">
              <Box flex={1}>
                <Text fontSize="sm">New sign-ups</Text>
              </Box>
              <Box flex={1}>
                <ReportTile
                  value={signUpsComplex.value}
                  icon={signUpsComplex.icon}
                  isLoading={props.loading}
                  iconSize={signUpsComplex.iconSize}
                  iconMarginTop={signUpsComplex.iconTopMargin}
                />
              </Box>
              <Box flex={1} />
            </HFlex>
          </IfAdmin>
        )}

        <HFlex py={2} px={5} alignItems="center" w="full">
          <Box flex={1}>
            <Text fontSize="sm">Active customers</Text>
          </Box>
          <Box flex={1}>
            <ReportTile
              value={activeCustomersComplex.value}
              icon={activeCustomersComplex.icon}
              isLoading={props.loading}
              iconSize={activeCustomersComplex.iconSize}
              iconMarginTop={activeCustomersComplex.iconTopMargin}
            />
          </Box>
          <Box flex={1} />
        </HFlex>

        {props.customerTile === "new" && (
          <HFlex py={2} px={5} alignItems="center" w="full">
            <Box flex={1}>
              <Text fontSize="sm">First time customers</Text>
            </Box>
            <Box flex={1}>
              <ReportTile
                value={newCustomersComplex.value}
                icon={newCustomersComplex.icon}
                isLoading={props.loading}
                iconSize={newCustomersComplex.iconSize}
                iconMarginTop={newCustomersComplex.iconTopMargin}
              />
            </Box>
            <Box flex={1} />
          </HFlex>
        )}
      </CardSection>
    </>
  );
};

export default ActivityReportView;
