import React from "react";
import { ReportTile } from "./ActivityReportTile";
import { Metric } from "./ActivityReportTile";
import { HFlex } from "../layout/HFlex";
import { Box, Text } from "@chakra-ui/react";

export const ReportRow = (props: {
  title: string;
  isLoading?: boolean;
  type?: "warning" | "error";
  metrics: Metric[];
}) => {
  const dataColor = (tileType: string | undefined, value: number) => {
    if (value !== 0 && tileType) {
      return tileType === "warning" ? "yellow.600" : "errorMessage";
    } else {
      return "brand.text";
    }
  };

  return (
    <HFlex py={2} px={5} w="100%" alignItems="center">
      <Box flex={1}>
        <Text color={dataColor(props.type, props.metrics[0].value)} fontSize="sm" fontWeight="400">
          {props.title}
        </Text>
      </Box>

      <HFlex flex={2}>
        {props.metrics.map((item) => {
          return (
            <ReportTile
              key={item.label}
              value={item.value}
              icon={item.icon}
              isLoading={props.isLoading}
              type={props.type}
              iconMarginTop={item.iconTopMargin}
              iconSize={item.iconSize}
            />
          );
        })}
      </HFlex>
    </HFlex>
  );
};
