import React from "react";
import _omit from "lodash/omit";
import { Button, ButtonProps } from "@chakra-ui/react";

export const TabButton = (
  props: {
    isPressed: boolean;
    label: string;
    index: number;
    onTabPress: Function;
  } & ButtonProps
) => {
  const extraProps: any = _omit(props, ["onTabPress", "index", "label", "isPressed"]);

  return (
    <Button
      variant={props.isPressed ? "solid" : "outline"}
      fontSize={"sm"}
      borderColor={"teal.800"}
      bg="white"
      _active={{ bg: "teal.800", color: "brand.section.bg" }}
      _hover={{ bg: "coolGray.100", borderColor: "teal.800" }}
      isActive={props.isPressed}
      isDisabled={props.isDisabled || props.isLoading}
      isLoading={props.isLoading}
      onClick={() => props.onTabPress(props.index)}
      {...extraProps}
    >
      {props.label}
    </Button>
  );
};
