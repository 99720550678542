import { appUniversalDomain, isDevelopment, isEmulator } from "../../util/config";

export type CodeTypes = "item" | "point";

export type CodeResultSuccess = {
  type: CodeTypes;
  code: string;
  errorMsg?: undefined;
};
export type CodeResultError = {
  type?: undefined;
  code?: undefined;
  errorMsg: string;
};

export type CodeResult = CodeResultSuccess | CodeResultError;

const LEGACY_URL = "https://wecrcl.com/cc/1000/";

export function parseQRCode(
  qrCode: string,
  expectedCodes: ("item" | "point")[],
  forceAny: boolean = false // used for test cases
) {
  let result: CodeResult;

  // expand the short domain from the bowls to the long domain
  qrCode = qrCode.replace("HTTPS://RCRL.ME/", "https://recirclable.app/i/");

  const urlParts = /^([A-Za-z]+):\/\/([^\/?#]+)(\/[^?#]*)/.exec(qrCode);

  if (!urlParts) {
    result = { errorMsg: "Not a Recirclable code." };
    return result;
  }
  let [_, protocol = "http", host, path = "/"] = urlParts;
  protocol = protocol.toLowerCase();
  qrCode = `${protocol}://${host}${path}`;

  if (qrCode.startsWith("https://") && qrCode.includes("recirclable.app")) {
    const noProtocol = qrCode.substring("https://".length);
    const idx = noProtocol.search("/");
    if (idx > 0) {
      const hostname = noProtocol.slice(0, idx);
      const path = noProtocol.slice(idx);
      // for the emulator or on development we accept any code, otherwise the environment must match
      if (forceAny || isEmulator() || isDevelopment() || hostname === appUniversalDomain()) {
        const slash = path.lastIndexOf("/");
        if (slash > 0) {
          const prefix = path.substring(0, slash);
          const code = path.substring(slash + 1);
          console.log(`code is: ${prefix} and ${code}`);

          if (prefix === "/i") {
            if (expectedCodes.includes("item")) {
              result = { type: "item", code };
            } else {
              result = { errorMsg: "Please scan the QR code on the signage." };
            }
          } else if (prefix === "/p" || prefix === "/a/point") {
            if (expectedCodes.includes("point")) {
              result = { type: "point", code };
            } else {
              result = { errorMsg: "Please scan the code on a bowl." };
            }
          } else {
            result = { errorMsg: "Unknown Recirclable code." };
          }
        } else {
          result = { errorMsg: "Unknown Recirclable code." };
        }
      } else {
        result = { errorMsg: "Mismatch. Recirclable test code or test app." };
      }
    } else {
      result = { errorMsg: "Not a Recirclable code." };
    }
  } else if (qrCode.startsWith(LEGACY_URL)) {
    if (expectedCodes.includes("item")) {
      const code = qrCode.substring(LEGACY_URL.length);
      result = { type: "item", code };
    } else {
      result = { errorMsg: "Please scan the QR code on the signage." };
    }
  } else {
    result = { errorMsg: "Not a Recirclable code." };
  }

  return result;
}
