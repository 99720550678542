import { ChakraProvider } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AppLoading } from "./AppLoading";
import { ErrorBoundary } from "./features/error/ErrorBoundary";
import { AlertProvider } from "./components/modals/AlertProvider";
import { initSentry } from "./lib/sentry/sentry";
import { BackAppNavigation } from "./BackAppNavigation";
import { GuardAuth } from "./features/auth/GuardAuth";
import { GuardRole } from "./features/auth/GuardRole";
import { theme } from "./theme";

export default function BackAppApp() {
  useEffect(() => {
    // not done globally because we need the settings from app.json (seee below)
    initSentry();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <AppLoading>
          <GuardAuth>
            <GuardRole>
              <AlertProvider>
                <BackAppNavigation />
              </AlertProvider>
            </GuardRole>
          </GuardAuth>
        </AppLoading>
      </ErrorBoundary>
    </ChakraProvider>
  );
}
