import { useCallback, useState } from "react";
import { SecondaryButton } from "../../components/primitives/SecondaryButton";
import { ForwardButtonItem } from "../../components/primitives/ForwardButtonItem";
import { VSpace } from "../../components/layout/VSpace";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { BackRpc } from "../../lib/functions/rpc";
import { LocationRepo } from "../../model/LocationDoc";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import { SectionBox } from "../../components/layout/Sections";
import { PrimaryButton } from "../../components/primitives/PrimaryButton";

export const AdminChangeRole = ({ navigation, route }: BackAppScreenProps<"AdminChangeRole">) => {
  const closeScreen = useCloseScreen();
  const locationState = useDocGet(LocationRepo.doc(route.params.locationId));
  const [msg, setMsg] = useState<string>();

  const handleChangeRole = useCallback(
    async (role: "admin" | "partner" | "beta") => {
      // make sure a location is set, if partner role is selected
      if (role === "partner" && !locationState.value) return;

      // ask again --> then change role
      if (window.confirm(`Do you really want to give [${route.params.email}] the role ${role}?`)) {
        const result = BackRpc.call("userChangeRole", {
          userId: route.params.profileId,
          role: role,
          ...(role === "partner" && { partnerId: locationState.value?.partnerId }),
        });
        if ((await result).success) {
          closeScreen();
        } else {
          setMsg("Error changing role.");
        }
      }
    },
    [route.params.email, locationState.value]
  );

  const handleRevoke = useCallback(async () => {
    if (window.confirm(`Do you really want to revoke all roles for [${route.params.email}]?`)) {
      const result = BackRpc.call("userChangeRole", {
        userId: route.params.profileId,
        role: null,
      });
      if ((await result).success) {
        closeScreen();
      } else {
        setMsg("Error revoking roles.");
      }
    }
  }, [route.params.email]);

  return (
    <Screen showTitle={true} name="Change Role">
      <SectionBox>
        <PrimaryButton
          label="Make Partner User"
          isDisabled={!locationState.value}
          onClick={() => handleChangeRole("partner")}
        />
        <ForwardButtonItem
          label="Location"
          value={locationState.value?.name ?? "Select partner location"}
          onPress={() => navigation.navigate("SelectLocation")}
        />
        <SecondaryButton mt={2} label="Make Beta User" onClick={() => handleChangeRole("beta")} />
        <SecondaryButton mt={2} label="Make Admin User" onClick={() => handleChangeRole("admin")} />
        <VSpace h={6} />
        <SecondaryButton mt={2} label="Revoke all Role(s)" onClick={handleRevoke} />
      </SectionBox>
      <SectionMsg text={msg} type="error" />
    </Screen>
  );
};
