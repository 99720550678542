// icon:exit-outline | Ionicons https://ionicons.com/ | Ionic Framework
import * as React from "react";
import { createIcon } from "@chakra-ui/react";

export const InventoryFromOtherIcon = createIcon({
  viewBox: "0 0 512 512",
  path: [
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M176 176v-40a40 40 0 0140-40h208a40 40 0 0140 40v240a40 40 0 01-40 40H216a40 40 0 01-40-40v-40"
    />,
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M272 336l80-80-80-80M48 256h288"
    />,
  ],
});
