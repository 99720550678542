import React, { useCallback } from "react";
import { formatDate, formatDateTime, formatTime } from "../../util/format";
import { PropertiesCard } from "../../components/properties/PropertiesCard";
import { CTransactionItemDoc } from "../../model/CTransactionItemDoc";
import { VTransactions } from "../../model/VTransactionDoc";
import { CTransactionNotifieRepo } from "../../model/CTransactionNotifyDoc";
import { useAsync } from "../../hooks/reactUse";
import { CInvoiceRepo } from "../../model/CInvoiceDoc";
import { CRefundRepo } from "../../model/CRefundDoc";
import { SqTxnDataRepo } from "../../model/SqTxnDataDoc";
import { ToastTxnDataRepo } from "../../model/ToastTxnDataDoc";
import { docGet } from "../../lib/firestore/fstore";

export const CTransactionDetailBlock: React.FC<{
  transaction: CTransactionItemDoc;
}> = (props) => {
  const { error, value: cinvoice } = useAsync(async () => {
    if (props.transaction.type === "expire") {
      return CInvoiceRepo.findInvoiceForExpireId(props.transaction.id);
    }
    return null;
  });
  const { value: crefund } = useAsync(async () => {
    if (props.transaction.type === "void") {
      return CRefundRepo.findRefundForVoidId(props.transaction.id);
    }
    if (props.transaction.type === "expire") {
      return CRefundRepo.findRefundForExpireId(props.transaction.id);
    }
    return null;
  });

  const fetchJsonData = useCallback(async () => {
    const [vtransaction, notify, square, toast] = await Promise.all([
      docGet(VTransactions.doc(props.transaction.id)),
      docGet(CTransactionNotifieRepo.doc(props.transaction.id)),
      docGet(SqTxnDataRepo.doc(props.transaction.id)),
      docGet(ToastTxnDataRepo.doc(props.transaction.id)),
    ]);
    return {
      c_transaction_items: props.transaction,
      v_transactions: vtransaction,
      "c_transaction_items/notify": notify,
      "c_transaction_items/square": square,
      "c_transaction_items/toast": toast,
      c_invoice: cinvoice,
      c_refund: crefund,
    };
  }, [props.transaction.id, cinvoice, crefund]);

  // standard fields
  const kv: Record<string, string | number | boolean> = {};
  kv["Id"] = props.transaction.id;
  kv["Type"] = props.transaction.type;
  kv["Transaction At"] = formatDateTime(props.transaction.transactionAt);
  kv["Mode"] = `${props.transaction.source} / ${props.transaction.mode}`;
  kv["Location"] = props.transaction.locationName ?? "-";
  kv["Container count"] = Math.abs(props.transaction.qty);

  // transaction specific fields
  switch (props.transaction.type) {
    case "borrow":
      if (props.transaction.matchable === true) {
        kv["Matchable"] = "waiting for registration";
      }
      if (props.transaction.matchable === false) {
        kv["Matchable"] = "incomplete";
      }
      kv["Outstanding"] = props.transaction.outstandingQty ?? "-";
      kv["Expiration Date"] = props.transaction.expiresAt
        ? formatDate(props.transaction.expiresAt) + " " + formatTime(props.transaction.expiresAt)
        : "-";
      kv["Order Nr"] = props.transaction.external?.orderNr ?? "-";
      kv["Receipt Nr"] = props.transaction.external?.receiptNr ?? "-";
      break;

    case "return":
      kv["Extra returns"] = props.transaction.creditQty ?? 0;
      kv["Scanned"] = props.transaction.scanQty ?? 0;
      if (props.transaction.details?.reasonCode) {
        kv["Reason Code"] = props.transaction.details.reasonCode;
      }
      if (props.transaction.external?.receiptNr) {
        kv["Receipt Nr"] = props.transaction.external?.receiptNr ?? "-";
      }
      break;

    case "expire":
      kv["Invoice Status"] = cinvoice ? cinvoice.status : "-";
      kv["Refund Status"] = crefund ? crefund.status : "-";
      break;

    case "void":
      kv["Refund Status"] = crefund ? crefund.status : "-";
      break;
  }

  return (
    <PropertiesCard
      title="Details"
      json={{
        name: "Transaction",
        data: fetchJsonData,
      }}
      properties={kv}
    />
  );
};
