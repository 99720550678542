import { createIcon } from "@chakra-ui/react";

// Ionicons: ellipsis-horizontal-circle-outline

export const ActionMenuIcon = createIcon({
  viewBox: "0 0 512 512",
  path: (
    <g stroke="currentColor" fill="currentColor">
      <circle key="1" cx="256" cy="256" r="26" />
      <circle key="2" cx="346" cy="256" r="26" />
      <circle key="3" cx="166" cy="256" r="26" />
      <path
        d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="32"
      />
    </g>
  ),
});
