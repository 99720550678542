import firebase from "firebase/compat/app";
import { useEffect, useRef, useState } from "react";
import { useAsync } from "../../hooks/reactUse";
import { FUNC_NOOP } from "../../util/constants";
import { TypedDoc } from "./fstore";
import { docGet, docQuery, docWatch, docQueryWatch } from "./fstore";
import { docRefId } from "./fstore_docref";

/*
 * Hook to get a document.
 */
export function useDocGet<T extends TypedDoc>(
  docRef: firebase.firestore.DocumentReference<T> | null | undefined
) {
  return useAsync(async () => {
    if (docRef) {
      return docGet<T>(docRef);
    }
    return null;
  }, [docRef?.path]);
}

/*
 * Hook to execute a firestore query.
 */
export function useDocQuery<T extends TypedDoc>(
  query: firebase.firestore.Query<T> | null | undefined,
  dependencies: any[] = []
) {
  return useAsync(async () => {
    if (query) {
      try {
        return await docQuery(query);
      } catch (error) {
        console.error("useQueryGet():", error);
        throw error;
      }
    }
    return [];
  }, dependencies);
}

type WatchDocState<T extends TypedDoc> = {
  loading: boolean;
  doc: T | null;
};

/*
 * Hook to listen to changes to a document. The document reference itself can also change.
 */
export function useDocWatch<T extends TypedDoc>(
  docRef: firebase.firestore.DocumentReference<T> | null | undefined
): WatchDocState<T> {
  const [state, setState] = useState<WatchDocState<T>>({ loading: true, doc: null });
  const unsubscribeRef = useRef<() => void>(FUNC_NOOP);

  useEffect(() => {
    unsubscribeRef.current();

    if (docRef) {
      unsubscribeRef.current = docWatch(docRef, (doc: T | null) => {
        setState({ loading: false, doc });
      });
    } else {
      setState({ loading: false, doc: null });
    }

    return () => {
      unsubscribeRef.current();
      unsubscribeRef.current = FUNC_NOOP;
    };
  }, [docRefId(docRef)]);

  return state;
}

type WatchQueryState<D extends TypedDoc> = {
  loading: boolean;
  docs: D[];
};

/*
 * Hook to listen to query changes. The query itself can also change.
 */
export function useDocQueryWatch<T extends TypedDoc>(
  query: firebase.firestore.Query<T> | null | undefined,
  dependencies: any[] = []
) {
  const [state, setState] = useState<WatchQueryState<T>>({ loading: true, docs: [] });
  const unsubscribeRef = useRef<() => void>(FUNC_NOOP);

  useEffect(() => {
    unsubscribeRef.current();

    if (query) {
      unsubscribeRef.current = docQueryWatch(query, (docs: T[]) => {
        setState({ loading: false, docs });
      });
    } else {
      setState({ loading: false, docs: [] });
    }

    return () => {
      unsubscribeRef.current();
      unsubscribeRef.current = FUNC_NOOP;
    };
  }, dependencies);

  return state;
}
