import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { HFlex } from "../../components/layout/HFlex";
import { CardSection } from "../../components/layout/Sections";
import { useAsync } from "../../hooks/reactUse";
import { docGet } from "../../lib/firestore/fstore";
import { CAccountRepo } from "../../model/CAccountDoc";
import { VBalanceRepo } from "../../model/VBalanceDoc";
import { HSpace } from "../../components/layout/HSpace";
import { PhoneIcon } from "../../components/icons/PhoneIcon";
import { ChevronForwardIcon } from "../../components/icons/ChevronForwardIcon";
import { AccountIcon } from "../../components/icons/AccountIcon";

export const AccountInfoCard = (props: {
  accountId: string;
  title?: string;
  onPress?: (accountId: string) => void;
}) => {
  const typeIcon = {
    personal: <AccountIcon boxSize={5} color="brand.text" />,
    phone: <PhoneIcon boxSize={5} color="brand.text" />,
  };

  const accountState = useAsync(async () => {
    return docGet(CAccountRepo.doc(props.accountId));
  });

  return (
    <CardSection
      title={props.title ?? "Account"}
      json={{
        name: "Account",
        data: async () => {
          const account = accountState.value;
          const vbalance = await docGet(VBalanceRepo.doc(account?.id));
          return {
            account,
            v_balance: vbalance,
          };
        },
      }}
      px={3}
      py={2}
      onClick={props.onPress && (() => props.onPress?.(props.accountId))}
    >
      <HFlex justifyContent="space-between" alignItems="center" w="full">
        <HFlex alignItems="center">
          {accountState.value?.type && typeIcon[accountState.value?.type ?? "personal"]}
          {!accountState.value?.type && <Box w={8} />}
          {accountState.value?.type === "phone" && (
            <Text
              pl={3}
              mr={2}
              fontWeight="700"
              textDecoration={accountState.value?.status === "archived" ? "line-through" : "none"}
            >
              {accountState.value?.phone}
            </Text>
          )}
          {accountState.value?.type === "personal" && (
            <HFlex pl={3} mr={2}>
              <Text fontWeight="700">Personal</Text>
              <Text>
                &nbsp;
                {`${
                  accountState.value?.ownerId
                    ? `${accountState.value?.ownerId.slice(0, 10)}...`
                    : "[disconnected]"
                }`}
              </Text>
            </HFlex>
          )}
        </HFlex>
        <HFlex alignItems="center">
          <Text
            color="brand.secondaryText"
            textDecoration={accountState.value?.status === "archived" ? "line-through" : "none"}
          >{`${props.accountId.slice(0, 10)}...`}</Text>
          <HSpace w={1} />
          {props.onPress ? <ChevronForwardIcon boxSize={4} color="brand.text" /> : null}
        </HFlex>
      </HFlex>
    </CardSection>
  );
};
