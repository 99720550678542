import { MsgScreen } from "../../components/layout/MsgScreen";
import { WaitScreen } from "../../components/layout/WaitScreen";
import { useRoles } from "../../lib/auth/useRoles";
import { useTimeoutFn } from "../../hooks/reactUse";
import { authSignOut } from "../../lib/auth/useAuthentication";

export const GuardRole = (props: { children?: JSX.Element | JSX.Element[] | string | any }) => {
  const roles = useRoles();

  if (roles.isPending) return <WaitScreen name="Siging In ..." />;
  if (roles.isPrivileged) return props.children;
  return <NotAuthorized />;
};

const NotAuthorized = () => {
  useTimeoutFn(() => {
    authSignOut();
  }, 3000);
  return <MsgScreen msg="Not authorized." />;
};
