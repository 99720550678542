import React from "react";
import { PrimaryButton } from "./PrimaryButton";
import { SecondaryButton } from "./SecondaryButton";
import { Box, ButtonProps } from "@chakra-ui/react";
import { HFlex } from "../layout/HFlex";

export const TwoButtonGroup: React.FunctionComponent<
  {
    _left: { label: string } & ButtonProps;
    _right: { label: string } & ButtonProps;
  } & ButtonProps
> = (props) => {
  return (
    <HFlex w="full">
      <SecondaryButton flex={1} {...props._left} />
      <Box w="12px" />
      <PrimaryButton flex={1} {...props._right} />
    </HFlex>
  );
};
