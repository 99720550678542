import React, { useState } from "react";
import TabControl from "../primitives/TabControl";

type Props = {
  setParentIndex: (index: number) => void;
  tabLabels: string[];
};

const ReportRangeSelector: React.FC<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const handleIndexChange = (index: number) => {
    setSelectedIndex(index);
    props.setParentIndex(index);
  };

  return (
    <TabControl
      values={props.tabLabels}
      selectedIndex={selectedIndex}
      onTabPress={handleIndexChange}
    />
  );
};

export default ReportRangeSelector;
