import firebase from "firebase/compat/app";
import { LTransactionItemData } from "../lib/apiDefs";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";
export type { DeliverReasonCode, PartnerReasonCode } from "../lib/apiDefs";

export class LTransactionItemDoc extends createDocBaseClass<LTransactionItemData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const LTransactionItemRepo =
  new (class LTransactionItemRepo extends Repo<LTransactionItemDoc> {
    constructor() {
      super("l_transaction_items", LTransactionItemDoc);
    }
  })();
