import React from "react";
import { Text } from "@chakra-ui/react";
import { VFlex } from "../layout/VFlex";

export const Stat: React.FunctionComponent<{ count: string; title: string }> = (props) => {
  return (
    <VFlex alignItems="center">
      <Text color="brand.text" fontSize="2xl" fontWeight="700">
        {props.count}
      </Text>
      <Text mt={1} color="brand.text" fontSize="sm" fontWeight="700">
        {props.title}
      </Text>
    </VFlex>
  );
};
