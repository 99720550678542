import React from "react";
import { useAsync } from "../../hooks/reactUse";
import { Screen } from "../../components/layout/Screen";
import { LTransactionDetailBlock } from "./LTransactionDetailBlock";
import { LocationInfoCard } from "../../components/views/LocationInfoCard";
import { LTransactionItemRepo } from "../../model/LTransactionItemDoc";
import { BackAppScreenProps } from "../../backAppStack";
import { docGet } from "../../lib/firestore/fstore";

import { LTransactionItemTypes } from "../../lib/apiDefs";
import { ScreenTitle } from "../../components/layout/ScreenTitle";

const ltransactionNames: Record<LTransactionItemTypes, string> = {
  subscription: "Subscription Change",
  partner: "Deliver/Remove",
  inv_count: "Inventory Count",
  adjust_diff: "Adjust Difference",
};

export const LTransactionDetailScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLTransactionDetails">) => {
  const transactionState = useAsync(async () =>
    docGet(LTransactionItemRepo.doc(route.params.transactionId))
  );

  return (
    <Screen name="Transaction Detail">
      {transactionState.value ? (
        <>
          <ScreenTitle
            title={ltransactionNames[transactionState.value.type] ?? transactionState.value.type}
            secondaryTitle={transactionState.value.locationName}
          />
          {transactionState.value.partnerId ? (
            <LocationInfoCard
              partnerId={transactionState.value.partnerId}
              locationId={transactionState.value.locationId}
            />
          ) : null}
          <LTransactionDetailBlock transaction={transactionState.value} showInternalValues={true} />
        </>
      ) : null}
    </Screen>
  );
};
