import React, { useCallback, useState } from "react";
import { Text } from "@chakra-ui/react";
import { useBackRpc } from "../../lib/functions/rpc";
import { VFlex } from "../../components/layout/VFlex";
import { VSpace } from "../../components/layout/VSpace";
import { ContainerDoc } from "../../model/ContainerDoc";
import { FullScreen } from "../../components/layout/FullScreen";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { ContainerScanner } from "../../components/containers/ContainerScanner";
import { ContainersView } from "../../components/containers/ContainersView";
import { VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import { BackAppScreenProps } from "../../backAppStack";
import { useAlerts } from "../../components/modals/AlertProvider";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import { Scrollable } from "../../components/primitives/Scrollable";
import { QR_SCANNER_HEIGHT } from "../../components/camera/QRCodeScanner";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminLocationInventoryCountScan = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationInventoryCountScan">) => {
  const closeScreen = useCloseScreen();
  const alerts = useAlerts();
  const [containers, setContainers] = useState<ContainerDoc[]>([]);
  const [invCountState, submitInvCount] = useBackRpc("locationInventoryCount");

  const { loading: ilevelLoading, value: ilevel } = useDocGet(
    VInventoryLevelRepo.doc(route.params.locationId)
  );

  function handleAddContainer(container: ContainerDoc) {
    setContainers((state) => {
      // NOTE: double scans can easily happen, because the camera is constantly reading QR codes. we
      //       filter duplicates codes earlier, but due to async actions it can still happen that
      //       handleAddItem() is called twice with the same code. handle that situation here.
      const alreadyScanned = state.find((scanned) => scanned.id === container.id);
      if (alreadyScanned) return state;
      return state.concat([container]);
    });
  }

  const handleSubmit = useCallback(() => {
    alerts.openAlertYesNo(
      {
        title: "Inventory Count",
        text: `Did you scan all containers? You are about to submit  ${containers.length}  as count. Expected were  ${ilevel?.currentQuantity}.`,
      },
      async (yesno) => {
        if (yesno) {
          await submitInvCount({
            locationId: route.params.locationId,
            qty: containers.length,
            containerIds: containers.map((c) => c.id),
          });
          closeScreen();
        }
      }
    );
  }, [alerts, containers.length, ilevel]);

  const formatButtonLabel = (numOfBowls: number) => {
    let label = "";
    if (numOfBowls <= 1) {
      label = `Count ${numOfBowls} container`;
    } else {
      label = `Count ${numOfBowls} containers`;
    }
    if (ilevel) label += ` at ${ilevel.locationName}`;
    return label;
  };

  return (
    <FullScreen name="Return /w Scan">
      <ContainerScanner
        disabled={invCountState.loading}
        alreadyScanned={containers}
        onAddContainer={handleAddContainer}
        onNoCamera={closeScreen}
      />
      <VSpace h={3} />
      <VFlex>
        <Text textAlign="center" fontSize="sm">
          All containers at this location must be scanned in one batch. Expected count is{" "}
          {ilevelLoading ? "..." : ilevel?.currentQuantity ?? 0}.
        </Text>
      </VFlex>
      <Scrollable maxH={`calc(100vh - ${QR_SCANNER_HEIGHT}px)`}>
        <VSpace h={3} />
        <ContainersView
          containers={containers}
          emptyMsg="Please scan all containers."
          continueMsg="Please continue scanning containers."
          showProductInfo={true}
        />
        <SectionPrimaryButton
          mt={3}
          isLoading={invCountState.loading}
          onClick={handleSubmit}
          label={formatButtonLabel(containers.length)}
        />
        <VSpace minH="60px" />
      </Scrollable>
    </FullScreen>
  );
};
