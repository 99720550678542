import firebase from "firebase/compat/app";
import { CProfileData, PhoneNumber } from "../lib/apiDefs";
import { Repo, createDocBaseClass, docGet, docQuery, docQueryFirst } from "../lib/firestore/fstore";
import { CAccountRepo } from "./CAccountDoc";
import invariant from "invariant";
import { VBalanceRepo } from "./VBalanceDoc";

export class CProfileDoc extends createDocBaseClass<CProfileData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }

  canBorrow() {
    return this.status === "active" && this.paymentId !== null;
  }

  notificationMethods() {
    const methods = [];
    if (this.notification?.email) methods.push("email");
    if (this.notification?.phone) methods.push("phone");
    if (this.notification?.device) methods.push("device");
    return methods;
  }

  identityMethods() {
    const methods = [];
    if (this.email && this.emailVerified) methods.push("email");
    if (this.phone && this.phoneVerified) methods.push("phone");
    return methods;
  }
}

export const CProfileRepo = new (class CProfileRepo extends Repo<CProfileDoc> {
  constructor() {
    super("c_profiles", CProfileDoc);
  }

  async countProfiles(phone: string) {}

  async findAllProfiles(phone: string) {
    return docQuery(this.query().where("phone", "==", phone));
  }

  async findAppProfile(phone: string) {
    return docQueryFirst(
      this.query().where("phone", "==", phone).where("phoneVerified", "==", true)
    );
  }

  async findAccountId(profile: CProfileDoc) {
    if (profile.accountId) {
      return profile.accountId;
    } else {
      invariant(profile.phone, "profile must have a phone number");
      const account = await CAccountRepo.findByPhone(profile.phone);
      return account?.id ?? null;
    }
  }

  async findAccount(profile: CProfileDoc) {
    if (profile.accountId) {
      return docGet(CAccountRepo.doc(profile.accountId));
    } else {
      invariant(profile.phone, "profile must have a phone number");
      return CAccountRepo.findByPhone(profile.phone);
    }
  }

  async findAccountByPhone(phone: string) {
    return CAccountRepo.findByPhone(phone);
  }

  async findBalance(profileOrId: CProfileDoc | string) {
    const profile = await this.resolveDoc(profileOrId);
    if (profile) {
      const account = await this.findAccount(profile);
      if (account) {
        return docGet(VBalanceRepo.doc(account.id));
      }
    }
    return null;
  }
})();
