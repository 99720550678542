import firebase from "firebase/compat/app";
import { TerminalData } from "../lib/apiDefs";
import { createDocBaseClass } from "../lib/firestore/fstore";
import _toPairs from "lodash/toPairs";
import { Repo } from "../lib/firestore/fstore";

export class TerminalDoc extends createDocBaseClass<TerminalData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }

  /**
   * Returns a string describing all enabled features for the terminal.
   * @returns
   */
  getFeatureDescription() {
    const features = _toPairs(this.features)
      .filter((pair) => pair[1])
      .map((pair) => pair[0])
      .sort()
      .join(", ");
    return features;
  }
}

export const TerminalRepo = new (class TerminalRepo extends Repo<TerminalDoc> {
  constructor() {
    super("terminals", TerminalDoc);
  }
})();
