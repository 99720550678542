import React from "react";
import _entries from "lodash/entries";
import { Box, Text } from "@chakra-ui/react";
import { ContainerDoc, ContainerRepo } from "../../model/ContainerDoc";
import { HFlex } from "../../components/layout/HFlex";
import { useDocQuery } from "../../lib/firestore/fstore_hooks";
import { CardSection } from "../../components/layout/Sections";

const ContainerInventoryRow = (props: { count: number; label: string; emphasize?: boolean }) => {
  return (
    <HFlex>
      <Box minW={6} mr={2}>
        <Text
          fontSize={props.emphasize ? "lg" : "sm"}
          fontWeight={props.emphasize ? 700 : 400}
          textAlign="end"
        >
          {props.count}
        </Text>
      </Box>
      <Text fontSize={props.emphasize ? "lg" : "sm"} fontWeight={props.emphasize ? 700 : 400}>
        {props.label}
      </Text>
    </HFlex>
  );
};

export const ContainerInventoryView = (props: { locationId: string; expected?: number }) => {
  const { loading, value } = useDocQuery(
    ContainerRepo.query().where("locationId", "==", props.locationId)
  );

  function productCounts(containers: ContainerDoc[]) {
    const counts = new Map<string, number>();
    for (const container of containers) {
      const productId = container.productId ?? "<null>";
      let count = counts.get(productId) ?? 0;
      counts.set(productId, count + 1);
    }

    const entries = _entries(counts).sort((a, b) => {
      return a[0].localeCompare(b[0]);
    });

    return entries as [string, number][];
  }

  return (
    <CardSection px={3} py={1}>
      {loading ? (
        <Text>Loading...</Text>
      ) : value ? (
        <>
          {productCounts(value).map(([productId, count]) => {
            return <ContainerInventoryRow key={productId} label={productId} count={count} />;
          })}
          <ContainerInventoryRow
            key="total"
            emphasize={true}
            label={"Total containers" + (props.expected ? ` (${props.expected} expected)` : "")}
            count={value.length}
          />
        </>
      ) : (
        <Text>Error loading</Text>
      )}
    </CardSection>
  );
};

export default ContainerInventoryView;
