import React, { useCallback, useState } from "react";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { LocationInfoCard } from "../../components/views/LocationInfoCard";
import { CompactCounterInput } from "../../components/composite/CompactCounterInput";
import { VFlex } from "../../components/layout/VFlex";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import { DeliverReasonCodeSelector } from "./DeliverReasonCodeSelector";
import { useBackRpc } from "../../lib/functions/rpc";
import { DeliverReasonCode } from "../../model/LTransactionItemDoc";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import { CardSection } from "../../components/layout/Sections";
import { Divider, Text } from "@chakra-ui/react";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminLocationDeliverRemove = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationDeliverRemove">) => {
  const closeScreen = useCloseScreen();
  const [quantity, setQuantity] = useState<number>(0);
  const [reasonCode, setReasonCode] = useState<DeliverReasonCode | null>(null);
  const { loading, value: ilevel } = useDocGet(VInventoryLevelRepo.doc(route.params.locationId));
  const [deliverState, submitDeliver] = useBackRpc("locationDeliverRemove");

  const handleSetQuantity = useCallback((value: number) => {
    setQuantity(value);
    if (value === 0) setReasonCode(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (quantity !== 0 && reasonCode !== null) {
      await submitDeliver({
        locationId: route.params.locationId,
        qty: quantity,
        reasonCode,
      });
      closeScreen();
    }
  }, [quantity, reasonCode, submitDeliver]);

  const delta = ilevel?.currentDelta ?? 0;

  console.log(quantity, reasonCode);

  return (
    <Screen name="Deliver & Remove" showTitle={true}>
      <LocationInfoCard partnerId={route.params.partnerId} locationId={route.params.locationId} />
      <CardSection title="Deliver or Remove count" p={2}>
        <VFlex w="full">
          <Text textAlign="center" fontSize="sm">
            Current Inventory
          </Text>
          <Text textAlign="center" fontSize="xl" fontWeight={700}>
            {loading ? "..." : ilevel?.currentQuantity ?? 0}
          </Text>

          {delta < 0 ? (
            <Text textAlign="center" fontSize="sm">
              {-delta} less than subscription
            </Text>
          ) : null}
          {delta > 0 ? (
            <Text textAlign="center" fontSize="sm">
              {delta} over subscription
            </Text>
          ) : null}
          {delta === 0 ? (
            <Text textAlign="center" fontSize="sm">
              At subscription level.
            </Text>
          ) : null}

          <Divider my={2} orientation="horizontal" borderColor="brand.text" />

          <CompactCounterInput
            minValue={loading ? 0 : -(ilevel?.currentQuantity ?? 0)}
            value={quantity}
            maxValue={200}
            onNumChange={handleSetQuantity}
            preText="Remove"
            postText="Deliver"
          />

          <DeliverReasonCodeSelector
            quantity={quantity}
            value={reasonCode}
            onReasonCode={setReasonCode}
          />
        </VFlex>
      </CardSection>
      <SectionPrimaryButton
        label="Submit"
        isDisabled={quantity === 0 || !reasonCode}
        onClick={handleSubmit}
      />
    </Screen>
  );
};
