import React, { ChangeEvent, useCallback, useState } from "react";
import { Box, Input, Spinner, Text } from "@chakra-ui/react";
import invariant from "invariant";
import { Screen } from "../../components/layout/Screen";
import { useAsyncFn } from "../../hooks/reactUse";
import { fixPhone } from "../../util/phone";
import { searchableName } from "../../util/searchableName";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { BackAppScreenProps } from "../../backAppStack";
import { ActionMenu } from "../../components/composite/ActionMenu";
import _isEqual from "lodash/isEqual";
import { CProfileDoc, CProfileRepo } from "../../model/CProfileDoc";
import { docQuery } from "../../lib/firestore/fstore";
import { CardSection, SectionBox } from "../../components/layout/Sections";
import { VBalanceRepo } from "../../model/VBalanceDoc";
import _ from "lodash";
import { CAccountRepo } from "../../model/CAccountDoc";
import { SearchResultData, SearchResultRow } from "./SearchResultRow";
import { HFlex } from "../../components/layout/HFlex";
import { HSpace } from "../../components/layout/HSpace";

export const AdminCustomerSearchScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminCustomerSearch">) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const [profileSearchDescription, setSearchDescription] = useState<string>("");
  const [message, setMessage] = useState<string | null>(null);

  function mapAppProfiles(profiles: CProfileDoc[]): SearchResultData[] {
    return profiles.map((profile) => {
      invariant(profile.type === "app", "only app profiles are support in the search result");
      return {
        accountId: profile.accountId!, // <- app profiles always have an account
        profileId: profile.id,
        status: profile.status,
        name: profile.name,
        email: profile.email,
        phone: profile.phone,
      };
    });
  }

  const [searchState, doSearch] = useAsyncFn(
    async (
      termType: "phone" | "email" | "other" | "recentSignups" | "outstandingBalances",
      searchTerm: string = ""
    ): Promise<SearchResultData[]> => {
      setMessage(null);

      if (termType === "recentSignups") {
        setSearchDescription("Recent app sign-ups");
        const profiles = await docQuery(
          CProfileRepo.query().where("type", "==", "app").orderBy("createdAt", "desc").limit(40)
        );
        return mapAppProfiles(profiles);
      } else if (termType === "outstandingBalances") {
        setSearchDescription("Outstanding balances (sorted by expiration date)");

        // TODO: better adjust to the new profile/account/balance model
        // TODO: when we get bigger, this needs to be done diffently. With firestore we can't run a query

        // with two range/inequalities (> outstanding, sort by expiresAt).
        const balances = await docQuery(VBalanceRepo.query().where("outstandingSum", ">", 0));
        balances.sort(
          (a, b) => (a.getNextExpiresAt()?.getTime() ?? 0) - (b.getNextExpiresAt()?.getTime() ?? 0)
        );
        const result = balances.map(
          (balance) =>
            ({
              accountId: balance.id,
              status: "active",
              name: balance.info?.name,
              email: balance.info?.email,
              phone: balance.info?.phone,
              outstandingSum: balance.outstandingSum,
              expiresAt: balance.getNextExpiresAt(),
            } as SearchResultData)
        );
        return result;
      }

      if (searchTerm.length < 2) {
        setMessage("Your input must have at least two characters");
        return [];
      }

      if (termType === "phone") {
        const phone = fixPhone(searchTerm);
        setSearchDescription(`Customers with '${phone}'`);
        const appProfile = await CProfileRepo.findAppProfile(phone);
        if (appProfile) {
          return mapAppProfiles([appProfile]);
        } else {
          const account = await CAccountRepo.findByPhone(phone);
          if (account) {
            return [
              {
                accountId: account.id,
                status: account.status,
                phone: account.phone,
              },
            ];
          }
        }
        return [];
      } else if (termType === "email") {
        setSearchDescription(`Customer with '${searchTerm}'`);
        const profiles = await docQuery(
          CProfileRepo.query()
            .where("type", "==", "app")
            .where("email", "==", searchTerm.toLowerCase())
        );
        return mapAppProfiles(profiles);
      }

      setSearchDescription(`Name, email or id starting with '${searchTerm}'`);

      // (1) search for profile id
      const searchCustomerId = () => {
        return docQuery(CProfileRepo.queryPartialField("documentId", searchTerm));
      };

      // (2) search for name prefix
      const searchName = () => {
        const name = searchableName(searchTerm);
        return docQuery(CProfileRepo.queryPartialField("normalizedName", name));
      };

      // (3) search for email prefix
      const searchEmail = () => {
        return docQuery(CProfileRepo.queryPartialField("email", searchTerm.toLocaleLowerCase()));
      };

      // run 3 queries in parallel
      const [idResult, nameResult, emailResult] = await Promise.all([
        searchCustomerId(),
        searchName(),
        searchEmail(),
      ]);

      // merge the results
      const profileList = [...idResult, ...nameResult, ...emailResult];

      // remove duplicates
      const idSet = new Set();
      let resultList: CProfileDoc[] = [];
      profileList.forEach((element) => {
        if (!idSet.has(element.id)) {
          resultList.push(element);
          idSet.add(element.id);
        }
      });

      // remove all none app profiles
      resultList = resultList.filter((p) => p.type === "app");

      // sort by name, email, id
      const compareFn = (a: CProfileDoc, b: CProfileDoc) => {
        if (a.normalizedName && b.normalizedName && !_isEqual(a.normalizedName, b.normalizedName)) {
          return a.normalizedName.localeCompare(b.normalizedName);
        }
        if (a.normalizedName && !b.normalizedName) {
          return -1;
        }
        if (b.normalizedName && !a.normalizedName) {
          return 1;
        }

        if (a.email && b.email) {
          return a.email.localeCompare(b.email);
        }
        if (a.email) {
          return -1;
        }
        if (b.email) {
          return 1;
        }
        return a.id.localeCompare(b.id);
      };
      resultList.sort(compareFn);

      // sort by active -> passive -> archived
      resultList.sort((a, b) => {
        const aa = a.status === "active" ? 0 : a.status === "passive" ? 1 : 2;
        const bb = b.status === "active" ? 0 : b.status === "passive" ? 1 : 2;
        return aa - bb;
      });

      return mapAppProfiles(resultList);
    }
  );

  const handleSearchInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    event.preventDefault();
  }, []);

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        let searchTerm = searchInput;
        let type: "email" | "phone" | "other";
        if (searchTerm.includes("@")) {
          type = "email";
        } else {
          if (searchTerm.startsWith("+") || !isNaN(Number(searchTerm))) {
            type = "phone";
          } else {
            type = "other";
          }
        }
        doSearch(type, searchTerm);
      }
    },
    [searchInput]
  );

  const handleSearchRecentSignups = () => {
    setSearchInput("");
    doSearch("recentSignups");
  };

  const handleSearchOutstandingBalances = () => {
    setSearchInput("");
    doSearch("outstandingBalances");
  };

  function handleAccountDetails(accountId: string) {
    navigation.push("AdminAccountDetails", { accountId });
  }

  return (
    <Screen name="Customer Search" showTitle={true}>
      <SectionBox>
        <HFlex alignItems="center" mr={10} w="full">
          <Box flex={1} bg="white" borderRadius="md" borderWidth={0} borderColor="gray.100">
            <Input
              id="search"
              flex={1}
              fontSize="md"
              placeholder={searchFocus ? "" : "Name, Email, Phone or Customer Id"}
              enterKeyHint="search"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize={"none"}
              value={searchInput}
              onInput={handleSearchInput}
              onKeyDown={handleKeyDown}
              onFocus={() => setSearchFocus(true)}
              onBlur={() => setSearchFocus(false)}
            />
          </Box>
          <HSpace w={2} />
          <ActionMenu
            menu={{
              "Recent app sign-ups": handleSearchRecentSignups,
              "Outstanding balances": handleSearchOutstandingBalances,
            }}
          />
        </HFlex>
      </SectionBox>

      {searchState.loading ? (
        <HFlex justifyContent="center">
          <Spinner />
        </HFlex>
      ) : (
        <>
          {!!searchState.value?.length && (
            <CardSection title={profileSearchDescription} withDivider={true}>
              {searchState.value?.map((item) => {
                return (
                  <SearchResultRow
                    key={item.accountId ?? item.profileId}
                    resultData={item}
                    onPress={handleAccountDetails}
                  />
                );
              })}
            </CardSection>
          )}
          {/* {searchState.value?.length === 0 && (
            <CardSection title={profileSearchDescription}>
              <Text p={3}>Nothing found.</Text>
            </CardSection>
          )} */}
          {!!searchInput && searchState.value?.length === 0 && (
            <SectionMsg type="muted" text={message ?? "Nothing found."} textSize="sm" />
          )}
        </>
      )}
    </Screen>
  );
};
