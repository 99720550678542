import firebase from "firebase/compat/app";
import { CInvoiceData } from "../lib/apiDefs";
import { Repo, createDocBaseClass, docQueryFirst } from "../lib/firestore/fstore";

export class CInvoiceDoc extends createDocBaseClass<CInvoiceData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const CInvoiceRepo = new (class CInvoiceRepo extends Repo<CInvoiceDoc> {
  constructor() {
    super("c_invoices", CInvoiceDoc);
  }

  async findInvoiceForExpireId(expireId: string) {
    const cinvoice = await docQueryFirst(this.query().where("transactionId", "==", expireId));
    return cinvoice;
  }
})();
