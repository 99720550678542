import { Screen } from "../../components/layout/Screen";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { CompactCounterInput } from "../../components/composite/CompactCounterInput";
import { ForwardButtonItem } from "../../components/primitives/ForwardButtonItem";
import { useEffect, useState } from "react";
import { useAsync, useAsyncFn } from "../../hooks/reactUse";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { BackAppScreenProps } from "../../backAppStack";
import { useBackRpc } from "../../lib/functions/rpc";
import { CProfileRepo } from "../../model/CProfileDoc";
import { CTransactionItemRepo } from "../../model/CTransactionItemDoc";
import { docGet, docQuery } from "../../lib/firestore/fstore";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import { LocationRepo } from "../../model/LocationDoc";
import { CardSection } from "../../components/layout/Sections";
import { VBalanceRepo } from "../../model/VBalanceDoc";
import { useToast } from "@chakra-ui/react";
import { VFlex } from "../../components/layout/VFlex";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

function useLoadOutstandingTransactions(profileId?: string, accountId?: string | null) {
  return useAsync(async () => {
    // determine the account
    if (!accountId) {
      const profile = await docGet(CProfileRepo.doc(profileId));
      if (profile) {
        accountId = await CProfileRepo.findAccountId(profile);
      }
    }

    // get the open transactions for that account (sorted by expiration)
    if (accountId) {
      const ctxns = await docQuery(
        CTransactionItemRepo.query()
          .where("accountId", "==", accountId)
          .where("type", "==", "borrow")
          .where("outstandingQty", ">", 0)
      );
      ctxns.sort((a, b) => a.transactionAt.getTime() - b.transactionAt.getTime());
      return ctxns;
    }

    return [];
  }, [profileId]);
}

export const AdminReturnScreen = ({ navigation, route }: BackAppScreenProps<"AdminReturn">) => {
  const closeScreen = useCloseScreen();
  const toast = useToast();
  const ctxnsState = useLoadOutstandingTransactions(route.params.profileId, route.params.accountId);
  const [returnQty, setReturnQty] = useState<number>(0);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [adminReturnState, doAdminReturn] = useBackRpc("adminReturn");

  const balanceState = useAsync(() =>
    route.params.accountId
      ? VBalanceRepo.mustFindById(route.params.accountId)
      : CProfileRepo.findBalance(route.params.profileId!)
  );
  const locationState = useDocGet(LocationRepo.doc(route.params.locationId));

  useEffect(() => {
    if ((!route.params.partnerId || !route.params.locationId) && ctxnsState.value) {
      navigation.setParams({
        partnerId: ctxnsState.value[0].partnerId,
        locationId: ctxnsState.value[0].locationId,
      });
    }
  }, [ctxnsState.value]);

  const [returnState, doReturn] = useAsyncFn(
    async (returnQty: number, partnerId: string, locationId: string) => {
      setIsFailed(false);
      const result = await doAdminReturn({
        profileId: route.params.profileId,
        accountId: route.params.accountId,
        partnerId,
        locationId,
        returnQty,
      });
      if (!result.success || result.returnedQty == 0) {
        setIsFailed(true);
      }
      return result;
    }
  );

  useEffect(() => {
    if (!returnState.loading && !!returnState.value?.success && !!returnState.value?.returnedQty) {
      toast({
        position: "top",
        title: "Return Confirmed",
        description: `You returned ${returnState.value?.returnedQty} container(s) for the customer to ${locationState.value?.name}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      closeScreen();
    }
  }, [
    locationState.value?.name,
    returnState.loading,
    returnState.value?.success,
    returnState.value?.returnedQty,
  ]);

  return (
    <Screen name="Return for Customer" showTitle={true}>
      <CardSection withDivider={true}>
        <CompactCounterInput
          preText="Return"
          postText="container(s)"
          maxValue={balanceState.value?.outstandingSum ?? 0}
          minValue={0}
          onNumChange={setReturnQty}
        />
        <ForwardButtonItem
          label="Location"
          value={locationState.value?.name ?? "..."}
          onPress={() => navigation.navigate("SelectLocation")}
        />
      </CardSection>
      <SectionPrimaryButton
        label="Confirm"
        onClick={() => doReturn(returnQty, route.params.partnerId!, route.params.locationId!)}
        isDisabled={returnQty === 0 || !locationState.value}
        isLoading={returnState.loading}
      />
      <SectionMsg type="error" text={isFailed ? "There is nothing to return" : ""} />
    </Screen>
  );
};
