// icon:clipboard-outline | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";
import { createIcon } from "@chakra-ui/react";

export const ClipboardIcon = createIcon({
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2m-7 0a1 1 0 011 1 1 1 0 01-1 1 1 1 0 01-1-1 1 1 0 011-1M7 7h10V5h2v14H5V5h2v2z"
    />
  ),
});
