// icon:table-account | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";
import { createIcon } from "@chakra-ui/react";

export const RoleProgramIcon = createIcon({
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M4 3h14a2 2 0 012 2v7.08a6.01 6.01 0 00-4.32.92H12v4h1.08c-.11.68-.11 1.35 0 2H4a2 2 0 01-2-2V5c0-1.1.9-2 2-2m0 4v4h6V7H4m8 0v4h6V7h-6m-8 6v4h6v-4H4m19 9v-1c0-1.33-2.67-2-4-2s-4 .67-4 2v1h8m-4-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.89 2-2-.89-2-2-2z"
    />
  ),
});
