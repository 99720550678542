import firebase from "firebase/compat/app";
import { UPrivilegesData } from "../lib/apiDefs";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";

export class UPrivilegesDoc extends createDocBaseClass<UPrivilegesData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }

  isAdmin() {
    return this.role === "admin";
  }

  isPartner() {
    return this.role === "partner";
  }

  isTerritory() {
    return this.role === "territory";
  }

  isProgram() {
    return this.role === "program";
  }
}

export const UPrivilegesRepo = new (class UPrivilegesRepo extends Repo<UPrivilegesDoc> {
  constructor() {
    super("u_privileges", UPrivilegesDoc);
  }
})();
