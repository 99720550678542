import { Box, Center, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CodeResultSuccess, parseQRCode } from "./code";
import { HFlex } from "../layout/HFlex";
import { VFlex } from "../layout/VFlex";
import { useAutoClearState } from "./useAutoClearState";
import { useAutoClearRef } from "./useAutoClearRef";
import {
  BarcodeFormat,
  BrowserMultiFormatReader,
  DecodeHintType,
  NotFoundException,
} from "@zxing/library";
import { findVideoInputDevice, requestCameraPermission } from "./QRCameraHelper";
import { useAlerts } from "../modals/AlertProvider";

export const QR_SCANNER_HEIGHT = 380;

const OverlayLines = (props: { color: string; size: number; overlayCaption?: string | null }) => {
  const color = props.color;
  const size = `${props.size}px`;
  return (
    <VFlex w="min-content">
      <HFlex>
        <Box
          borderLeftColor={color}
          borderTopColor={color}
          borderBottomColor="transparent"
          borderRightColor="transparent"
          borderWidth={3}
          w={size}
          h={size}
          zIndex={1}
        />
        <Box w={props.size} h={props.size} />
        <Box
          borderRightColor={color}
          borderTopColor={color}
          borderBottomColor="transparent"
          borderLeftColor="transparent"
          borderWidth={3}
          w={size}
          h={size}
          zIndex={1}
        />
      </HFlex>
      <Box h={props.size} />
      <HFlex>
        <Box
          borderLeftColor={color}
          borderBottomColor={color}
          borderTopColor="transparent"
          borderRightColor="transparent"
          borderWidth={3}
          w={size}
          h={size}
          zIndex={1}
        />
        <Box w={size} h={size} />
        <Box
          borderRightColor={color}
          borderBottomColor={color}
          borderTopColor="transparent"
          borderLeftColor="transparent"
          borderWidth={3}
          w={size}
          h={size}
          zIndex={1}
        />
      </HFlex>
      <HFlex my={1} justifyContent="center">
        <Text color={color} textAlign="center" fontWeight="bold">
          {props.overlayCaption ?? ""}
        </Text>
      </HFlex>
    </VFlex>
  );
};

export interface ScanMsg {
  type: "wait" | "success" | "warn" | "error" | "alarm";
  text: string;
}
const msgBgColors = {
  wait: "white",
  success: "tertiary.700",
  warn: "amber.400",
  error: "red.700",
  alarm: "red.900",
};
const msgTextColors = {
  wait: "brand.text",
  success: "white",
  warn: "brand.text",
  error: "white",
  alarm: "white",
};

export const CameraOverlay = (props: { overlayCaption?: string | null; msg?: ScanMsg | null }) => {
  return (
    <>
      <OverlayLines color="green.500" overlayCaption={props.overlayCaption} size={70} />
      {!!props.msg?.text && props.msg.text.length > 0 ? (
        <Box position="absolute" bottom={0} left={0} w="100%" bgColor={msgBgColors[props.msg.type]}>
          <Text p={2} textAlign="center" color={msgTextColors[props.msg.type]} fontWeight="400">
            {props.msg.text}
          </Text>
        </Box>
      ) : null}
    </>
  );
};

const UndecoratedQRCodeScanner = (props: {
  onQRCode: (qrCode: string) => void;
  onNoCamera?: () => void;
}) => {
  const alerts = useAlerts();
  const [prevQRCodeRef, setPrevQRCode] = useAutoClearRef<string | null>(null);

  useEffect(() => {
    requestCameraPermission()
      .then(() => {
        findVideoInputDevice(["back", "rear"], "environment")
          .then((deviceId) => {
            const codeReader = new BrowserMultiFormatReader(
              new Map().set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.QR_CODE]),
              50 /* timeBetweenScansMillis */
            );
            if (deviceId) {
              codeReader.decodeFromVideoDevice(deviceId, "qr_video_id", (result, error) => {
                if (result) {
                  const qrCode = result.getText();

                  // the camera constantly produces a stream of QR codes, we only trigger a callback,
                  // when the code changes. otherwise we would flood the receiver with messages
                  if (qrCode === prevQRCodeRef.current) return;
                  setPrevQRCode(qrCode);

                  console.log("QR CODE", qrCode);
                  props.onQRCode(qrCode);
                }
                if (error && !(error instanceof NotFoundException)) {
                  console.error(error);
                }
              });
            }

            return () => {
              codeReader.reset();
            };
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((_error) => {
        alerts.openAlertOk(
          {
            title: "Camera Permission",
            text: "The app requires access to the camera to scan QR codes and to function. Please enable the camera for this web site.",
          },
          () => {
            props.onNoCamera?.();
          }
        );
      });
  }, []);

  return (
    <video
      id="qr_video_id"
      muted
      autoPlay
      playsInline
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
    />
  );
};

export const QRCodeScanner: React.FunctionComponent<{
  expectedTypes: ("item" | "point")[];
  onScan?: (result: CodeResultSuccess) => void;
  onNoCamera?: () => void;
  overlayCaption?: string;
  msg?: ScanMsg | null;
}> = (props) => {
  const [scanMsg, setScanMsg] = useAutoClearState<ScanMsg | null>(null);

  // apply a message passed in from the parent
  useEffect(() => {
    if (props.msg) setScanMsg(props.msg);
  }, [props.msg]);

  // callback when the camera detects a QR code
  //
  // NOTE: the expo-camera implementation holds on to initially passed callback. that causes
  // some issues throughout the app. that's the reason we use a ref instead of a state here,
  // because we can change the ref's value from outside.
  const onQRCode = useCallback(
    (qrCode: string) => {
      const result = parseQRCode(qrCode, props.expectedTypes);
      if (result.code) {
        props.onScan?.(result);
      } else {
        setScanMsg({ type: "error", text: result.errorMsg! });
      }
    },
    [props.onScan]
  );

  /* a surrounding box was the best way to control the camera size */
  return (
    <Center
      position="relative"
      w="full"
      h={`${QR_SCANNER_HEIGHT}px`}
      maxH={`${QR_SCANNER_HEIGHT}px`}
      bg="black"
    >
      <UndecoratedQRCodeScanner onQRCode={onQRCode} onNoCamera={props.onNoCamera} />
      <CameraOverlay msg={scanMsg} overlayCaption={props.overlayCaption} />
    </Center>
  );
};
