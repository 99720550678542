import React from "react";
import invariant from "invariant";
import { Box, Text } from "@chakra-ui/react";
import { formatDate } from "../../util/format";
import { ONE_HOUR } from "../../util/constants";
import { VFlex } from "../../components/layout/VFlex";
import { HFlex } from "../../components/layout/HFlex";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { integerToSignedString } from "../../util/format";
import { integerToString } from "../../util/format";
import { VInventoryLevelDoc, VInventoryLevelRepo } from "../../model/VInventoryLevelDoc";
import {
  LTransactionItemDoc,
  LTransactionItemRepo,
  PartnerReasonCode,
} from "../../model/LTransactionItemDoc";
import { useRoles } from "../../lib/auth/useRoles";
import { useDocWatch, useDocQueryWatch } from "../../lib/firestore/fstore_hooks";
import { CardSection } from "../../components/layout/Sections";
import { InventoryPlusIcon } from "../../components/icons/InventoryPlusIcon";
import { InventoryMinusIcon } from "../../components/icons/InventoryMinusIcon";
import { InventorySubscriptionIcon } from "../../components/icons/InventorySubscriptionIcon";
import { InventoryLossIcon } from "../../components/icons/InventoryLossIcon";
import { InventoryFullIcon } from "../../components/icons/InventoryFullIcon";
import { InventoryIcon } from "../../components/icons/InventoryIcon";
import { InventoryDiffClearIcon } from "../../components/icons/InventoryDiffClearIcon";
import { InventoryDiffPayIcon } from "../../components/icons/InventoryDiffPayIcon";

type InventoryRowProps = {
  transactionId: string;
  icon?: JSX.Element;
  muted?: boolean;
  title: string;
  qty?: number;
  delta?: number;
  dateFrom?: Date;
  dateTo?: Date;
  deltaColor?: string;
  onPress?: (transactionId: string) => void;
};

const InventoryRow = (props: InventoryRowProps) => {
  invariant(props.dateFrom || props.dateTo, "a date must be provided");

  let dateLine;
  if (props.dateFrom) {
    dateLine = formatDate(props.dateFrom);
    if (props.dateTo) dateLine += ` - ${formatDate(props.dateTo)}`;
  } else {
    dateLine = `until ${formatDate(props.dateTo!)}`;
  }

  const hasValues = props.qty !== undefined || props.delta !== undefined;

  const color = props.icon ? "brand.text" : "muted.400";

  return (
    <HFlex
      px={4}
      py={1}
      my={1}
      alignItems="center"
      w="full"
      onClick={props.onPress ? () => props.onPress?.(props.transactionId) : undefined}
    >
      {props.icon ?? <Box w="32px" />}
      <VFlex flex={1} pl={3}>
        <HFlex justifyContent="space-between" alignContent="center" w="100%">
          <Text color={color} fontSize="md" fontWeight="700">
            {props.title}
          </Text>
          {hasValues ? (
            props.qty !== undefined ? (
              <Text color="brand.text" fontSize="md" fontWeight="700">
                {integerToString(props.qty)}
              </Text>
            ) : (
              <Text color="brand.secondaryText" fontSize="md" fontWeight="700">
                N/A
              </Text>
            )
          ) : null}
        </HFlex>
        <HFlex flex={1} justifyContent="space-between" alignItems="center">
          <Text color={color} fontSize="sm" fontWeight="400">
            {dateLine}
          </Text>
          {hasValues ? (
            props.delta !== undefined ? (
              <Text
                color={props.deltaColor ? props.deltaColor : "gray.500"}
                fontSize="sm"
                fontWeight="400"
              >
                {integerToSignedString(props.delta)}
              </Text>
            ) : (
              <Text color="brand.secondaryText" fontSize="sm" fontWeight="500">
                N/A
              </Text>
            )
          ) : null}
        </HFlex>
      </VFlex>
    </HFlex>
  );
};

const ReasonText: Record<PartnerReasonCode, string> = {
  subscription: "Subscription",
  lost_replacement: "Lost Bowl Replacment",
  replacement: "Replacement",
  mixed: "Mixed",
  rebalance: "Rebalance",
  end_subscription: "End Subscription",
  adjust_delivered: "Other",
  adjust_quantity: "Other",
  deliver: "Subscription",
};

const DeliverRow = (props: {
  transaction: LTransactionItemDoc;
  onPress?: (transactionId: string) => void;
}) => {
  const isDelivery = props.transaction.qty >= 0;
  return (
    <InventoryRow
      transactionId={props.transaction.id}
      dateFrom={props.transaction.transactionAt}
      title={`${isDelivery ? "Deliver" : "Remove"}${
        props.transaction.reasonCode ? ": " + ReasonText[props.transaction.reasonCode] : ""
      }`}
      qty={
        "expectedQty" in props.transaction
          ? props.transaction.expectedQty! + props.transaction.qty
          : undefined
      }
      delta={props.transaction.qty}
      icon={
        isDelivery ? (
          <InventoryPlusIcon boxSize={8} color="brand,text" />
        ) : (
          <InventoryMinusIcon boxSize={8} color="brand,text" />
        )
      }
      onPress={props.onPress}
    />
  );
};

const CountRow = (props: {
  transaction: LTransactionItemDoc;
  onPress?: (transactionId: string) => void;
}) => {
  const delta = props.transaction.qty;
  let title = "Inventory Count: ";
  let icon: any;
  if (delta < 0) {
    title += "Loss";
    icon = <InventoryLossIcon boxSize={8} color="brand.text" />;
  } else if (delta > 0) {
    title += "Excess";
    icon = <InventoryFullIcon boxSize={8} color="brand.text" />;
  } else {
    title += "Balanced";
    icon = <InventoryIcon boxSize={8} color="brand.text" />;
  }

  return (
    <InventoryRow
      transactionId={props.transaction.id}
      dateFrom={props.transaction.transactionAt}
      title={title}
      qty={(props.transaction.expectedQty ?? 0) + props.transaction.qty}
      delta={props.transaction.qty}
      icon={icon}
      deltaColor={delta < 0 ? "error.400" : undefined}
      onPress={props.onPress}
    />
  );
};

const AdjustRow = (props: {
  transaction: LTransactionItemDoc;
  onPress?: (transactionId: string) => void;
}) => {
  let icon;
  let title;
  if (props.transaction.qty > 0 && props.transaction.adjustPayment) {
    icon = <InventoryDiffPayIcon boxSize={8} color="brand.text" />;
    title = `Payment for Lost Container(s): ${props.transaction.qty}`;
  } else {
    icon = <InventoryDiffClearIcon boxSize={8} color="brand.text" />;
    title = `Difference Cleared: ${props.transaction.qty}`;
  }

  return (
    <InventoryRow
      transactionId={props.transaction.id}
      dateFrom={props.transaction.transactionAt}
      title={title}
      icon={icon}
      onPress={props.onPress}
    />
  );
};

const SubscriptionRow = (props: {
  transaction: LTransactionItemDoc;
  onPress?: (transactionId: string) => void;
}) => {
  return (
    <InventoryRow
      transactionId={props.transaction.id}
      dateFrom={props.transaction.transactionAt}
      title={`Subscription: ${props.transaction.qty.toLocaleString("en-US", {
        signDisplay: "always",
      })}`}
      icon={<InventorySubscriptionIcon boxSize={8} color="brand.text" />}
      onPress={props.onPress}
    />
  );
};

const PseudoBorrowReturnRow = (props: {
  dateFrom?: Date;
  dateTo: Date;
  expectedQty: number;
  delta?: number;
  onPress?: (transactionId: string) => void;
}) => {
  return (
    <InventoryRow
      transactionId={"id"}
      dateFrom={props.dateFrom}
      dateTo={props.dateTo}
      title="Borrow & Return"
      qty={props.expectedQty}
      delta={props.delta !== undefined ? props.delta : undefined}
      // icon={
      //   <Icon as={MaterialCommunityIcons} name="arrow-left-right" color="brand.text" size="2xl" />
      // }
    />
  );
};

export const LTransactionList: React.FunctionComponent<{
  partnerId: string;
  locationId: string;
  onPress?: (itemId: string) => void;
}> = (props) => {
  const { eroot } = useRoles();

  // retrieve all transactions sorted old -> new
  const result = useDocQueryWatch(
    LTransactionItemRepo.queryEntity(eroot)
      .where("locationId", "==", props.locationId)
      .orderBy("transactionAt", "asc")
  );

  // retrieve current level
  const resultLevel = useDocWatch(VInventoryLevelRepo.doc(props.locationId));

  function renderRow(transaction: LTransactionItemDoc) {
    switch (transaction.type) {
      case "partner":
        return (
          <DeliverRow key={transaction.id} transaction={transaction} onPress={props.onPress} />
        );

      case "subscription":
        return (
          <SubscriptionRow key={transaction.id} transaction={transaction} onPress={props.onPress} />
        );

      case "inv_count":
        return <CountRow key={transaction.id} transaction={transaction} onPress={props.onPress} />;

      case "adjust_diff":
        return <AdjustRow key={transaction.id} transaction={transaction} onPress={props.onPress} />;

      default:
        return null;
    }
  }

  const DELTA_TIME_PSEUDO_ROW = 12 * ONE_HOUR;
  function mapRows(docs: LTransactionItemDoc[], ilevel: VInventoryLevelDoc) {
    let lastQty: number | null = null; // <-- running qty from delta actions
    let lastDate: Date = docs[0].transactionAt;

    // transactions are sorted oldest to newest
    const rows: (JSX.Element | null)[] = [];
    for (const doc of docs) {
      // for a transaction with an explicity expectedQty insert a pseudeo borrow/return
      // row to cover for the difference
      if (["inv_count", "partner"].includes(doc.type)) {
        // ..., if the row has the inventory qty recorded
        if (doc.expectedQty !== undefined) {
          // ..., and there was borrow/return activity = the quantity has changed or certain time has passed
          if (
            lastQty !== null &&
            (doc.expectedQty !== lastQty ||
              doc.transactionAt.getTime() - lastDate.getTime() > DELTA_TIME_PSEUDO_ROW)
          ) {
            rows.unshift(
              <PseudoBorrowReturnRow
                key={doc.id + "_br"} // use location as unique key
                dateFrom={lastDate}
                dateTo={doc.transactionAt}
                expectedQty={doc.expectedQty ?? 0}
                delta={(doc.expectedQty ?? 0) - lastQty}
              />
            );
          }

          // record the last qty
          lastQty = doc.expectedQty + doc.qty;
        } else {
          // no inventory record, so next time skip the pseudo borrow/return
          lastQty = null;
        }
        lastDate = doc.transactionAt;
      }

      // add the actual row
      rows.unshift(renderRow(doc));
    }

    // as first (= newest) element add a pseudo borrow/return
    if (ilevel.subscriptionSum > 0) {
      const currentQty = ilevel.currentQuantity;
      if (lastQty !== null) {
        // insert peseudo row, when there was borrow/return activity or when time has passed
        if (lastQty !== currentQty || Date.now() - lastDate.getTime() > DELTA_TIME_PSEUDO_ROW) {
          rows.unshift(
            <PseudoBorrowReturnRow
              key="top_br"
              dateFrom={lastDate}
              dateTo={new Date()}
              expectedQty={currentQty}
              delta={currentQty - lastQty}
            />
          );
        }
      } else {
        rows.unshift(
          <PseudoBorrowReturnRow
            key="top_br"
            dateFrom={lastDate}
            dateTo={new Date()}
            expectedQty={currentQty}
          />
        );
      }
    }

    return rows;
  }

  if (result.loading || resultLevel.loading) {
    return null;
  } else if (!result.docs || !result.docs.length || !resultLevel.doc) {
    return <SectionMsg text="No inventory transactions." textSize="md" />;
  } else {
    return (
      <CardSection title="Inventory Transactions" withDivider={true}>
        {mapRows(result.docs, resultLevel.doc)}
      </CardSection>
    );
  }
};
