import firebase from "firebase/compat/app";
import { CTransactionNotifyData } from "../lib/apiDefs";
import { SubRepo, createDocBaseClass } from "../lib/firestore/fstore";

export class CTransactionNotifyDoc extends createDocBaseClass<CTransactionNotifyData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const CTransactionNotifieRepo =
  new (class CTransactionNotifieRepo extends SubRepo<CTransactionNotifyDoc> {
    constructor() {
      super("c_transaction_items", "notify", CTransactionNotifyDoc);
    }
  })();
