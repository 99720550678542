import React, { useCallback, useState } from "react";
import { ContainerScanner } from "../../components/containers/ContainerScanner";
import { ContainersView } from "../../components/containers/ContainersView";
import { VSpace } from "../../components/layout/VSpace";
import { FullScreen } from "../../components/layout/FullScreen";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { DeliverReasonCodeSelector } from "./DeliverReasonCodeSelector";
import { ContainerDoc } from "../../model/ContainerDoc";
import { BackAppScreenProps } from "../../backAppStack";
import { useBackRpc } from "../../lib/functions/rpc";
import { DeliverReasonCode } from "../../model/LTransactionItemDoc";
import { QR_SCANNER_HEIGHT } from "../../components/camera/QRCodeScanner";
import { Scrollable } from "../../components/primitives/Scrollable";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";
import { CardSection, CardSectionContent } from "../../components/layout/Sections";

export const AdminLocationRemoveScanScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminLocationRemoveScan">) => {
  const closeScreen = useCloseScreen();
  const [reasonCode, setReasonCode] = useState<DeliverReasonCode | null>(null);
  const [containers, setContainers] = useState<ContainerDoc[]>([]);
  const [removeState, submitRemove] = useBackRpc("locationDeliverRemove");

  function handleAddContainer(container: ContainerDoc) {
    setContainers((state) => {
      // NOTE: double scans can easily happen, because the camera is constantly reading QR codes. we
      //       filter duplicates codes earlier, but due to async actions it can still happen that
      //       handleAddItem() is called twice with the same code. handle that situation here.
      const alreadyScanned = state.find((scanned) => scanned.id === container.id);
      if (alreadyScanned) return state;
      return state.concat([container]);
    });
  }

  const handleSubmit = useCallback(async () => {
    if (containers.length && reasonCode) {
      await submitRemove({
        locationId: route.params.locationId,
        qty: -containers.length,
        reasonCode: reasonCode!,
        containerIds: containers.map((c) => c.id),
      });
      closeScreen();
    }
  }, [containers, reasonCode]);

  const formatButtonLabel = (numOfBowls: number) => {
    if (numOfBowls === 0) {
      return "No containers scanned yet";
    } else {
      let label = "";
      if (numOfBowls === 1) {
        label = "Remove 1 container";
      } else {
        label = `Remove ${numOfBowls} containers`;
      }
      return label;
    }
  };

  const submitDisabled = removeState.loading || containers.length < 1 || !reasonCode;
  const submitLoading = removeState.loading;
  return (
    <FullScreen name="Remove /w Scan">
      <ContainerScanner
        disabled={removeState.loading}
        alreadyScanned={containers}
        onAddContainer={handleAddContainer}
        onNoCamera={closeScreen}
      />
      <Scrollable maxH={`calc(100vh - ${QR_SCANNER_HEIGHT}px)`}>
        <VSpace h={3} />

        <ContainersView
          containers={containers}
          showProductInfo={true}
          emptyMsg="Please scan the containers."
          continueMsg="Please continue scanning containers."
        />

        <CardSection>
          <CardSectionContent>
            <DeliverReasonCodeSelector
              mode={"remove"}
              quantity={-containers.length}
              value={reasonCode}
              onReasonCode={setReasonCode}
            />
          </CardSectionContent>
        </CardSection>

        <SectionPrimaryButton
          mt={3}
          isDisabled={submitDisabled}
          isLoading={submitLoading}
          onClick={handleSubmit}
          label={formatButtonLabel(containers.length)}
        />
        <VSpace minH="60px" />
      </Scrollable>
    </FullScreen>
  );
};
