import React from "react";
import { useFonts } from "expo-font";
import { useAuthentication } from "./lib/auth/useAuthentication";
import { handleEmbeddedAuth } from "./lib/auth/embeddedApp";
import { useAsync, useTimeout } from "./hooks/reactUse";
import { usePendingWatchDog } from "./hooks/usePendingWatchDog";
import { UndecoratedScreen } from "./components/layout/UndecoratedScreen";
import { Box, Center, Spinner } from "@chakra-ui/react";

export const AppWaitScreen = ({}) => {
  const [showSpinner] = useTimeout(2000); // Spinner after 2sec wait

  return (
    <UndecoratedScreen name="Waiting ...">
      <Center w="100%" h="100%">
        {showSpinner() ? <Spinner color="brand.text" size="lg" /> : <Box>{/* TODO: Logo */}</Box>}
      </Center>
    </UndecoratedScreen>
  );
};

export const AppLoading: React.FunctionComponent<{ children: JSX.Element }> = (props) => {
  const { loading: startAuthPending } = useAsync(async () => {
    // first check for an auth of the embedded app
    // --> returns immediately, if not on web or no token
    await handleEmbeddedAuth();
    // ... then start auth listner
    useAuthentication.getState().start();
  }, []);

  // wait until auth state and role are determined
  const authPending = useAuthentication((s) => s.isPending);

  // wait until fonts are loaded
  const [fontsLoaded] = useFonts({
    "NotoSans-Regular": require("assets/fonts/NotoSans-Regular.ttf"),
    "NotoSans-Medium": require("assets/fonts/NotoSans-Medium.ttf"),
    "NotoSans-Bold": require("assets/fonts/NotoSans-Bold.ttf"),
  });

  // pending -> we are still waiting for something
  const pending = startAuthPending || authPending || !fontsLoaded;

  // timeout in case the app isn't initializing within an expected time
  usePendingWatchDog(pending, `state was: ${startAuthPending} ${authPending} ${!fontsLoaded}`);

  // just waiting until we have all initial data to start the app
  if (pending) return <AppWaitScreen />;
  return <>{props.children}</>;
};
