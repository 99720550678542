import { ButtonProps } from "@chakra-ui/react";
import { SectionBox } from "./Sections";
import { SecondaryButton } from "../primitives/SecondaryButton";

export const SectionSecondaryButton = (props: { label: string } & ButtonProps) => {
  return (
    <SectionBox>
      <SecondaryButton {...props} />
    </SectionBox>
  );
};
