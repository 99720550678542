import React from "react";
import { CardSection } from "../layout/Sections";
import { Stat } from "./Stats";
import { HStack, StackDivider } from "@chakra-ui/react";

export const StatsRow: React.FunctionComponent<{
  title?: string;
  borrowed: string;
  returned: string;
  purchased: string;
}> = (props) => {
  return (
    <CardSection title={props.title}>
      <HStack
        py={2}
        justifyContent="space-evenly"
        w="100%"
        divider={<StackDivider borderColor="brand.text" />}
      >
        <Stat count={props.borrowed} title="Borrowed" />
        <Stat count={props.returned} title="Returned" />
        <Stat count={props.purchased} title="Purchased" />
      </HStack>
    </CardSection>
  );
};
