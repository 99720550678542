import React from "react";
import { Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { HFlex } from "../layout/HFlex";
import { TextWithLoading } from "../primitives/TextWithLoading";

export interface Metric {
  value: number;
  label: string;
  icon: any;
  iconTopMargin?: number;
  iconSize?: number;
}

export const ReportTile = (props: {
  value: number;
  isLoading?: boolean;
  type?: "warning" | "error";
  icon?: any;
  iconMarginTop?: number;
  iconSize?: number;
}) => {
  const textColor = (tileType: string | undefined, value: number) => {
    if (value !== 0 && tileType) {
      return tileType === "warning" ? "yellow.600" : "errorMessage";
    } else {
      return "brand.text";
    }
  };

  return (
    <HFlex flex={1} alignItems="center">
      <TextWithLoading
        flex="2"
        marginRight="2"
        color={textColor(props.type, props.value)}
        textAlign="right"
        fontSize="2xl"
        fontWeight="700"
        isLoading={props.isLoading}
      >
        {props.value}
      </TextWithLoading>
      {props.icon}
    </HFlex>
  );
};
