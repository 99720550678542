import firebase from "firebase/compat/app";
import { SqTxnData } from "../lib/apiDefs";
import { SubRepo, createDocBaseClass } from "../lib/firestore/fstore";

export class SqTxnDataDoc extends createDocBaseClass<SqTxnData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const SqTxnDataRepo = new (class SqTxnDataRepo extends SubRepo<SqTxnDataDoc> {
  constructor() {
    super("c_transaction_items", "square_data", SqTxnDataDoc);
  }
})();
