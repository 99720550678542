import React from "react";
import { SectionMsg } from "../../components/layout/SectionMsg";
import { CTransactionItemDoc } from "../../model/CTransactionItemDoc";
import { CTransactionRow } from "./CTransactionRow";
import { CardSection } from "../../components/layout/Sections";
import { ifAdmin } from "../../components/conditional/IfAccess";

export const CTransactionListCard = (props: {
  isLoading?: boolean;
  limit?: number;
  transactions?: CTransactionItemDoc[] | null;
  showLocation?: boolean;
  emptyMsg?: string;
  onPress?: (itemId: string) => void;
}) => {
  const msg = props.isLoading
    ? "Loading ..."
    : !props.transactions?.length
    ? props.emptyMsg ?? `No transactions.`
    : props.limit && props.transactions.length >= props.limit
    ? `Only the most recent ${props.limit} transactions are shown.`
    : "";

  return (
    <>
      <CardSection withDivider={true}>
        {props.transactions?.map((transaction) => {
          return (
            <CTransactionRow
              key={transaction.id}
              transaction={transaction}
              showLocation={props.showLocation}
              onPress={ifAdmin(props.onPress)}
            />
          );
        })}
      </CardSection>
      <SectionMsg text={msg} type="muted" />
    </>
  );
};
