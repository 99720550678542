import firebase from "firebase/compat/app";
import { ContainerMovementData } from "../lib/apiDefs";
import { Repo, createDocBaseClass } from "../lib/firestore/fstore";

export class ContainerMovementDoc extends createDocBaseClass<ContainerMovementData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const ContainerMovementRepo =
  new (class ContainerMovementRepo extends Repo<ContainerMovementDoc> {
    constructor() {
      super("container_movements", ContainerMovementDoc);
    }
  })();
