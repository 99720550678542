import { createIcon } from "@chakra-ui/react";

// Ionicons: chevron-forward

export const ChevronForwardIcon = createIcon({
  viewBox: "0 0 512 512",
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="48"
      d="M184 112l144 144-144 144"
    />
  ),
});
