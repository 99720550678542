import { useCallback, useState } from "react";
import { useAsyncFn } from "../../hooks/reactUse";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { useBackRpc } from "../../lib/functions/rpc";
import { CardSection, SectionBox } from "../../components/layout/Sections";
import { PrimaryButton } from "../../components/primitives/PrimaryButton";
import { Input, Text } from "@chakra-ui/react";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminCustomerCreateScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminCustomerCreate">) => {
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [msg, setMsg] = useState(" ");
  const [createCustomerState, submitCreateCustomer] = useBackRpc("adminCreateCustomer");

  const [createState, doCreate] = useAsyncFn(async (name: string, email: string) => {
    setMsg("");
    try {
      const result = await submitCreateCustomer({ name, email });
      if (result.success && result.profileId) {
        navigation.replace("AdminCustomerDetails", { profileId: result.profileId });
      }
      setMsg(result.msg ?? "Failure to create customer.");
    } catch (error) {
      setMsg(String(error));
    }
  });

  const handleSubmit = useCallback(() => {
    if (name && email) doCreate(name, email);
  }, [name, email]);

  const isValidInput =
    name?.length && !!email && email.length > 5 && email.includes("@") && email.includes(".");
  return (
    <Screen showTitle={true} name="Create Customer">
      <CardSection withDivider={true}>
        <Input
          id="name"
          isDisabled={createState.loading}
          flex={1}
          fontSize="md"
          placeholder={"Enter name"}
          autoComplete="off"
          autoCorrect="off"
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          id="email"
          isDisabled={createState.loading}
          flex={1}
          fontSize="md"
          placeholder={"Enter email address"}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize={"none"}
          onChange={(e) => setEmail(e.target.value)}
        />
      </CardSection>
      <SectionPrimaryButton
        label="Create Customer"
        isDisabled={createState.loading || !isValidInput}
        isLoading={createState.loading}
        onClick={handleSubmit}
      />
      <SectionBox>
        <Text textAlign="center" fontSize="sm" color="error.900">
          {msg}
        </Text>
      </SectionBox>
    </Screen>
  );
};
