import React from "react";
import { Flex, IconButton, Input, InputProps } from "@chakra-ui/react";
import { EyeOffIcon } from "../icons/EyeOffIcon";
import { EyeIcon } from "../icons/EyeIcon";

export function PasswordInput(props: InputProps) {
  const [showPwd, setShowPwd] = React.useState(false);

  const handleToggleShowPwd = () => setShowPwd(!showPwd);

  const eyeIcon = <EyeIcon color="brand.text" boxSize={6} />;
  const eyeOffIcon = <EyeOffIcon color="brand.text" boxSize={6} />;

  return (
    <Flex w="full" flexDirection="row" alignContent="center" alignItems="center">
      <Input
        type={showPwd ? "text" : "password"}
        placeholder="Enter password"
        flexGrow={1}
        {...props}
      />
      <IconButton
        aria-label="Show password"
        icon={showPwd ? eyeOffIcon : eyeIcon}
        size="lg"
        variant="unstyled"
        width="min"
        height="min"
        onClick={handleToggleShowPwd}
      />
    </Flex>
  );
}
