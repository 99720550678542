import firebase from "firebase/compat/app";
import { LocationData } from "../lib/apiDefs";
import { createDocBaseClass } from "../lib/firestore/fstore";
import { Repo } from "../lib/firestore/fstore";

export class LocationDoc extends createDocBaseClass<LocationData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const LocationRepo = new (class LocationRepo extends Repo<LocationDoc> {
  constructor() {
    super("locations", LocationDoc);
  }
})();
