import { Text, Textarea } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useCloseScreen } from "../../components/navigation/useCloseScreen";
import { Screen } from "../../components/layout/Screen";
import { BackAppScreenProps } from "../../backAppStack";
import { useBackRpc } from "../../lib/functions/rpc";
import { CProfileRepo } from "../../model/CProfileDoc";
import { useDocGet } from "../../lib/firestore/fstore_hooks";
import { CardSection, SectionBox } from "../../components/layout/Sections";
import { PrimaryButton } from "../../components/primitives/PrimaryButton";
import { SectionPrimaryButton } from "../../components/layout/SectionPrimaryButton";

export const AdminCustomerSendTextScreen = ({
  navigation,
  route,
}: BackAppScreenProps<"AdminCustomerSendText">) => {
  const closeScreen = useCloseScreen();
  const [text, setText] = useState<string>();
  const [msg, setMsg] = useState<string>("");
  const [sendState, doSend] = useBackRpc("customerSendText");

  const profileState = useDocGet(CProfileRepo.doc(route.params.profileId));

  const handleSend = useCallback(() => {
    if (text) {
      doSend({ profileId: route.params.profileId, accountId: route.params.accountId, text })
        .then((result) => {
          if (result.success) {
            closeScreen();
          } else {
            setMsg("An error occured.");
          }
        })
        .catch(() => {
          setMsg("An error occured.");
        });
    }
  }, [text]);

  let buttonLabel = "Send";
  if (profileState.value?.phone) {
    buttonLabel += ` to ${profileState.value.phone}`;
    if (profileState.value?.name) {
      buttonLabel += ` (${profileState.value.name})`;
    }
  }

  return (
    <Screen showTitle={true} name="Send Text Message">
      <CardSection>
        <Textarea
          id="SMSText"
          minH={32}
          h={32}
          isDisabled={sendState.loading}
          flex={1}
          fontSize="xl"
          placeholder={"Enter message text"}
          autoComplete="off"
          onChange={(e) => setText(e.target.value)}
        />
      </CardSection>
      <SectionPrimaryButton
        label={buttonLabel}
        isDisabled={sendState.loading || !text?.length}
        isLoading={sendState.loading}
        onClick={handleSend}
      />
      <SectionBox>
        <Text textAlign="center" fontSize="sm" color="error.900">
          {msg}
        </Text>
      </SectionBox>
    </Screen>
  );
};
