import firebase from "firebase/compat/app";
import { Repo, createDocBaseClass, docQueryFirst } from "../lib/firestore/fstore";
import { CRefundData } from "../lib/apiDefs";

export class CRefundDoc extends createDocBaseClass<CRefundData>() {
  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const CRefundRepo = new (class CRefundReport extends Repo<CRefundDoc> {
  constructor() {
    super("c_refunds", CRefundDoc);
  }

  async findRefundForVoidId(voidId: string) {
    const crefund = await docQueryFirst(this.query().where("voidTransactionId", "==", voidId));
    return crefund;
  }

  async findRefundForExpireId(voidId: string) {
    const crefund = await docQueryFirst(this.query().where("expireTransactionId", "==", voidId));
    return crefund;
  }
})();
